/** @format */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

export enum Style {
  light = 'vex-style-light',
  default = 'vex-style-default',
  dark = 'vex-style-dark',
  contrast = 'vex-style-contrast',
}

const storage = new StorageService('global');
let _styleCurrent: Style = storage.getLocalData('theme') || Style.default;
const _styleSubject = new BehaviorSubject<Style>(_styleCurrent);
const dark = matchMedia('(prefers-color-scheme: dark)');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StyleService implements OnDestroy {
  static get styleCurrent() {
    return _styleCurrent;
  }
  static readonly style$ = _styleSubject.asObservable();

  private autoStyle() {
    return _styleCurrent === Style.contrast ? Style.contrast : Style.default;
    // TODO: improve dark theme support then enable this
    // return _styleCurrent === Style.contrast ? Style.contrast : dark.matches ? Style.dark : Style.default;
  }

  private check = () => {
    this.setStyle(this.autoStyle());
  };

  private _updateStyle(style: Style) {
    _styleCurrent = style;

    if (style === Style.contrast) {
      storage.setLocalData('theme', style);
    } else {
      storage.removeLocalData('theme');
    }
    style = this.autoStyle();
    const body = this.document.body;
    Object.values(Style)
      .filter((s) => s !== style)
      .forEach((value) => {
        if (body.classList.contains(value)) {
          body.classList.remove(value);
        }
      });
    body.classList.add(style);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    StyleService.style$.pipe(untilDestroyed(this)).subscribe((style) => this._updateStyle(style));
    // listen for any changes performed by people tinkering with their theme
    dark.addEventListener('change', this.check);
    this.check();
  }

  ngOnDestroy(): void {
    dark.removeAllListeners('change');
  }

  setStyle(style: Style) {
    _styleSubject.next(style);
  }
}
