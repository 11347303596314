/** @format */

import { differenceInMilliseconds } from 'date-fns';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { BehaviorSubject } from 'rxjs';
import { DateUtil } from '../../shared/date-util';
import { Rest } from '../../shared/rest';
import { StateService } from '../state.service';

const urlCollection = {
  url: () => `Organizations/${StateService.user?.OrganizationId}/EmbedInfo`,
};

// #region TYPE DEFINITIONS

export interface OrgReportPBI {
  EmbedUrl: string; // "https://app.powerbi.com/..."
  ReportId: string; // 3a2650ba-f46a-4287-8e99-d8bb05363683",
  ReportName: string; // "Clients",
}

export interface OrgReportPBIToken {
  Token: string; // "H4sIAAAAAAAE...",
  TokenId: string; // "9b94df89-d50a-433d-8c66-8e98ca81453e";
  Expiration: string; // "2024-03-13T18:37:00Z";
  // mapped
  expirationDate: Date;
}

export interface OrgReportSummary {
  EmbedReport: OrgReportPBI[];
  EmbedToken: OrgReportPBIToken;
  Type: string; // "Report",
}

// #endregion

// #region PRIVATE

// locations
const normalizeIn = (item: string): OrgReportSummary => {
  const summary = JSON.parse(item) as OrgReportSummary;
  Object.assign(summary.EmbedToken, {
    expirationDate: DateUtil.parseDateTimeISO(summary.EmbedToken.Expiration),
  });
  return summary;
};

// #endregion

export class OrgReportsService {
  private static reportSummary: OrgReportSummary;
  // access token updates
  private static accessToken = new BehaviorSubject<string>(null);
  static readonly accessToken$ = this.accessToken.asObservable();
  // reports
  private static reports = new BehaviorSubject<OrgReportPBI[]>(null);
  static readonly reports$ = this.reports.asObservable();

  static readonly getConfig = (id: string): IReportEmbedConfiguration => {
    const { EmbedToken, EmbedReport } = this.reportSummary || {};
    const { Token: accessToken } = EmbedToken || {};
    const { EmbedUrl: embedUrl } = EmbedReport?.find((report) => report.ReportId === id) || {};
    return (
      embedUrl && {
        accessToken,
        embedUrl,
        permissions: models.Permissions.Read,
        tokenType: models.TokenType.Embed,
        type: 'report',
        viewMode: models.ViewMode.View,
        settings:{
          hyperlinkClickBehavior: models.HyperlinkClickBehavior.RaiseEvent
        }
      }
    );
  };

  static readonly getTitle = (id: string) => {
    const { EmbedReport } = this.reportSummary || {};
    const { ReportName } = EmbedReport?.find((report) => report.ReportId === id) || {};
    return ReportName ? `menu.report.${ReportName}` : 'form.loading';
  };

  // track reportList
  static {
    let lastProfileId;
    let timeout;

    const announce = (items: OrgReportPBI[] = null, accessToken: string = null, refreshMs = 5 * 60 * 1e3) => {
      clearTimeout(timeout);
      this.reports.next(items);
      this.accessToken.next(accessToken);
      timeout = items && setTimeout(getSummary, refreshMs);
    };

    const getSummary = async () => {
      if (!StateService.user?.OrganizationId) return;
      try {
        this.reportSummary = await Rest.get<string, OrgReportSummary>(urlCollection, [], normalizeIn);
        // refresh in 10 seconds or expiration date - 60s, whichever is larger
        const refreshMs = Math.max(
          30e3,
          differenceInMilliseconds(this.reportSummary.EmbedToken.expirationDate, Date.now()) - 60e3
        );
        announce(this.reportSummary.EmbedReport, this.reportSummary.EmbedToken.Token, refreshMs);
      } catch (ex) {
        this.reportSummary = null;
        announce();
      }
    };

    // update announcements on user change
    StateService.user$.subscribe(async (user) => {
      const profileId = user?.PersonId;
      if (profileId !== lastProfileId) {
        lastProfileId = profileId;
        // cleanup
        this.reportSummary = null;
        announce();
      }
      if (StateService.isFocusedMode || user?.isClient) return;
      getSummary();
    });
  }
}
