/** @format */

import { SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HBIAddress } from '../services/location.service';
import { SnackService } from '../services/snack.service';
import { ServiceLocator } from './service-locator';

const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';

const sanitizerPromise = ServiceLocator.get(DomSanitizer);
let sanitizer: DomSanitizer;
sanitizerPromise.then((instance) => (sanitizer = instance));

export class Link {
  static fromAddress(address: HBIAddress) {
    // https://developers.google.com/maps/documentation/urls/guide
    // https://www.google.com/maps/search/?api=1&query=1200%20Pennsylvania%20Ave%20SE%2C%20Washington%2C%20District%20of%20Columbia%2C%2020003
    const { AddressLine1, City, State, ZipCode } = address || {};
    const region =
      State && ZipCode
        ? `${encodeURIComponent(State)}+${encodeURIComponent(ZipCode)}`
        : encodeURIComponent(State || ZipCode || '');
    const parts = [];
    if (AddressLine1) parts.push(encodeURIComponent(AddressLine1));
    if (City) parts.push(encodeURIComponent(City));
    if (region) parts.push(region);
    return parts.length ? sanitizer.bypassSecurityTrustUrl(`${baseUrl}${parts.join(',+')}`) : null;
  }

  static fromPhone(phone: string, isd: string = '', linkType: 'tel' | 'sms' = 'tel') {
    return phone && sanitizer.bypassSecurityTrustUrl(`${linkType}:${(isd + phone).replace(/[^+\d]/g, '')}`);
  }

  static fromEmail(email: string) {
    return email && sanitizer.bypassSecurityTrustUrl(`mailto:${email}`);
  }

  static isSafeUrl(value: any): value is SafeUrl {
    // really simple type check
    return typeof value === 'object' && 'changingThisBreaksApplicationSecurity' in value;
  }

  static open(link?: string | SafeUrl, target: string = '_blank', features?: string) {
    const url = Link.isSafeUrl(link) ? sanitizer.sanitize(SecurityContext.URL, link) : link;
    const newWindow = window.open(url, target, features);
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      SnackService.warn('link.error.open');
      return null;
    }
    return newWindow;
  }
}
