/** @format */

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ArrayUtil } from 'src/app/shared/array-util';
import { DateUtil } from 'src/app/shared/date-util';
import { ObservableManager } from '../../shared/observable-utils';
import { Rest } from '../../shared/rest';
import { CommonBase, normalizeCommonBase, removeCommonBase } from '../common.service';
import { AuthExclude, AuthRequire, StateService, authAllow } from '../state.service';

const urlCollection = {
  url: () => `Organizations/${StateService.user?.OrganizationId}/SystemMessages`,
  urlDelete: (id: number) => `Organizations/${StateService.user?.OrganizationId}/SystemMessages/SystemMessage/${id}`,
  urlDisplay: () => `Organizations/${StateService.user?.OrganizationId}/SystemMessages/GetSystemMessagesForDisplay`,
};

// #region TYPE DEFINITIONS

export interface OrgAnnouncement extends CommonBase {
  Content: string; // "Please ignore"
  EndDate: string; // "03/01/2025"
  OrganizationId: string; // "f8472681-6908-4127-adf9-fb967b886ced"
  StartDate: string; // "03/17/2022"
  SystemMessageId: number; // 30
  Title: string; // "Test only please ignore"
  // map
  endDate: Date;
  isLive: boolean;
  startDate: Date;
}

// #endregion

// #region PRIVATE

// locations
const normalizeIn = (item: OrgAnnouncement): OrgAnnouncement => {
  const startDate = DateUtil.parseDate(item.StartDate);
  const endDate = DateUtil.parseDate(item.EndDate);
  const isLive = Date.now() >= startDate.valueOf() && Date.now() <= endDate.valueOf();
  return Object.assign(normalizeCommonBase(item), { endDate, startDate, isLive });
};

const normalizeList = (items: OrgAnnouncement[]): OrgAnnouncement[] =>
  items
    .map((item) => normalizeIn(item))
    .sort(
      (a, b) =>
        -ArrayUtil.compare(a.endDate, b.endDate) ||
        -ArrayUtil.compare(a.startDate, b.startDate) ||
        ArrayUtil.compare(a.Title, b.Title)
    );

const normalizeOut = (item: OrgAnnouncement): OrgAnnouncement =>
  Object.assign(removeCommonBase(item), {
    EndDate: DateUtil.formatDateEN(item.endDate),
    OrganizationId: StateService.user.OrganizationId,
    StartDate: DateUtil.formatDateEN(item.startDate),
    endDate: undefined,
    isLive: undefined,
    startDate: undefined,
  });

// #endregion

export class OrgAnnouncementService {
  private static getName = (all = false) => `announcement/${StateService.user?.OrganizationId}${all ? '/all' : ''}`;
  private static announcements = new BehaviorSubject<OrgAnnouncement[]>(null);

  @AuthExclude('ClientFacingApplication.Read') // TODO: this is not in sync with server
  @AuthRequire('Client.Read', 'OptionLine.All', 'Donor.All', 'APR.Binder') // TODO: this should probably be Application.Admin
  static readonly list = async (): Promise<OrgAnnouncement[]> =>
    Rest.get<OrgAnnouncement[]>({ url: urlCollection.urlDisplay }, [], {
      normalize: normalizeList,
      manager: this.getName(),
    });

  @AuthExclude('ClientFacingApplication.Read') // TODO: this is not in sync with server
  @AuthRequire('Client.Read', 'OptionLine.All', 'Donor.All', 'Medical.Read', 'APR.Binder')
  static readonly list$ = (): Observable<OrgAnnouncement[]> =>
    ObservableManager.init<OrgAnnouncement[]>(this.getName(), () => this.list());

  @AuthRequire('Application.Admin	') // TODO: this is not in sync with server
  static readonly listAll = async (): Promise<OrgAnnouncement[]> =>
    Rest.get<OrgAnnouncement[]>(urlCollection, [], {
      normalize: normalizeList,
      manager: this.getName(true),
    });

  @AuthRequire('Application.Admin') // TODO: this is not in sync with server
  static readonly listAll$ = (): Observable<OrgAnnouncement[]> =>
    ObservableManager.init<OrgAnnouncement[]>(this.getName(true), () => {
      this.listAll();
      this.list();
    });

  @AuthExclude('ClientFacingApplication.Read') // TODO: this is not in sync with server
  @AuthRequire('Client.Read', 'OptionLine.All', 'Donor.All', 'APR.Binder')
  static readonly get = async (id: number): Promise<OrgAnnouncement> =>
    Rest.get<OrgAnnouncement>(urlCollection, [], id, normalizeIn);

  @AuthRequire('Application.Admin')
  static readonly set = async (data: OrgAnnouncement): Promise<OrgAnnouncement> =>
    Rest.putPost<OrgAnnouncement>(urlCollection, [], normalizeOut(data), data.SystemMessageId, {
      manager: this.getName(true),
    });

  @AuthRequire('Application.Admin')
  static readonly delete = async (data: OrgAnnouncement): Promise<void> =>
    Rest.delete<void>(urlCollection, [], data.SystemMessageId, {
      manager: this.getName(true),
    });

  static readonly announcements$ = this.announcements.asObservable();

  // track announcements
  static {
    let subscription: Subscription;
    let lastOrgId;
    let timeout;

    // announce announcements and schedule 5 minute refresh
    const updateAnnouncements = (items: OrgAnnouncement[]) => {
      clearTimeout(timeout);
      this.announcements.next(items);
      timeout = setTimeout(() => {
        if (!authAllow(OrgAnnouncementService.list)) return;
        OrgAnnouncementService.list();
      }, 5 * 60 * 1e3);
    };

    // update announcements on user change
    StateService.user$.subscribe(async (auth) => {
      if (StateService.isFocusedMode) return;
      const orgId = authAllow(OrgAnnouncementService.list) && StateService.user?.OrganizationId;
      if (orgId !== lastOrgId) {
        lastOrgId = orgId;
        // cleanup
        clearTimeout(timeout);
        subscription?.unsubscribe();
        this.announcements.next(null);
      }
      if (!orgId) return;
      // listen for changes on the new org
      subscription = this.list$().subscribe(updateAnnouncements);
    });
  }
}
