/** @format */

import { Injectable } from '@angular/core';
import { Rest } from 'src/app/shared/rest';
import { RewardsService } from '../rewards.service';
import { AuthRequire, StateService } from '../state.service';
import { CalendarAttendee } from './calendar-appointment.service';

const urlCollection = {
  url: () => '',
  urlPut: (appointmentId: string) =>
    `Organizations/${StateService.user?.OrganizationId}/Calendar/${appointmentId}/ToggleAttendeeAttendance`,
};

// #region TYPE DEFINITIONS

export type CalendarAttendanceType =
  | 'attended'
  | 'cancelled'
  | 'confirmed'
  | 'noShow'
  | 'rescheduled'
  | 'scheduled'
  // ui only
  | 'mixed' // mixed client attendee states
  | 'requested' // requested event
  | 'temp'; // creating event

export const CalendarAttendanceMap: Record<number, CalendarAttendanceType> = {
  1: 'scheduled',
  2: 'attended',
  3: 'noShow',
  4: 'rescheduled',
  5: 'cancelled',
  6: 'confirmed', // used by server to delete ?
};

export const canNotifyChange = (oldId: number, newId: number) => {
  const availOptions = {
    1: [4, 5], // Rescheduled or Cancelled -> Scheduled
    3: true, // No Show
    5: true, // Cancelled
    // 6: [4, 5], // Rescheduled or Cancelled -> Confirmed
  };
  const fromIds: true | number[] = availOptions[newId] || [];
  return oldId !== newId && (fromIds === true || fromIds.includes(oldId));
};

// #endregion

// #region PRIVATE

// #endregion

@Injectable({
  providedIn: 'root',
})
export class CalendarAttendanceService {
  @AuthRequire('Client.Read') // TODO: SERVER DOES NOT CHECK AUTH
  static set = async (attendance: CalendarAttendee, isNotificationAllowed: boolean): Promise<any> => {
    const result = await Rest.putPost<any>(
      urlCollection,
      [attendance.AppointmentId],
      attendance,
      attendance.AppointmentId,
      {
        params: { isNotificationAllowed },
      }
    );
    // let app know that client rewards info may have changed
    if (attendance.ContextType === 'Client') {
      RewardsService.refreshInfo(attendance.PersonId);
    }
    return result;
  };
}
