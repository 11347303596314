/** @format */

import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

// max number of times to retry request
const defaultAttempts = 2;
// how long to delay requests [initial, retry1, retry2, ..., retryN]
const ms = [0, 250, 1e3];

const RETRY_DISABLED = new HttpContextToken<boolean>(() => false);
const RETRY_ATTEMPTS = new HttpContextToken<number>(() => defaultAttempts);

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  // allow context to be updated for a given request
  static update({ attempts = defaultAttempts, disabled = false }, context: HttpContext = new HttpContext()) {
    const newContext = new HttpContext();
    for (const token of context.keys()) {
      newContext.set(token, context.get(token));
    }
    newContext.set(RETRY_DISABLED, disabled);
    newContext.set(RETRY_ATTEMPTS, attempts);
    return newContext;
  }

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const result = next.handle(request);

    // short circuit if access not needed
    if (request.context.get(RETRY_DISABLED)) return result;

    return result.pipe(
      retry({
        count: request.context.get(RETRY_ATTEMPTS),
        delay: (error: HttpErrorResponse, retryCount) => {
          // retry if offline/unavailable
          return error.status === 0
            ? timer(ms[retryCount]) // retry
            : throwError(() => error); // unhandled error
        },
      })
    );
  }
}
