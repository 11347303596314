/** @format */

import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[vexPristine]',
})
export class PristineDirective {
  constructor(private ngControl: NgControl) {}

  private markPristine = () => this.ngControl.control.markAsPristine();

  // mat-checkbox, mat-radio-group, mat-slide-toggle
  @HostListener('change')
  onChange = this.markPristine;

  // mat-select
  @HostListener('selectionChange')
  onSelectionChange = this.markPristine;

  // input[matDatepicker]
  @HostListener('dateChange')
  onDateChange = this.markPristine;
}
