/** @format */

import { format, parse } from 'date-fns';
import { toDate } from 'date-fns-tz';

// date in ms
const dtmMax = 8640e12;

const isValidDate = (dtm: Date | number) =>
  dtm && dtm !== DateUtil.maxDate && dtm !== DateUtil.minDate && !isNaN(dtm.valueOf());

export class DateUtil {
  static maxDate = dtmMax;
  static minDate = -dtmMax;

  /**
   * Format date as EN string
   * Jan 22, 2022 @ 9:55 => "01/22/2022"
   */
  static formatDateEN(dtm: Date | number): string {
    return isValidDate(dtm) ? format(dtm, 'MM/dd/yyyy') : null;
  }

  /**
   * Format date as ISO string
   * Jan 22, 2022 @ 9:55 => "2022/01/22"
   */
  static formatDateISO(dtm: Date | number): string {
    return isValidDate(dtm) ? format(dtm, 'yyyy/MM/dd/') : null;
  }

  /**
   * Format date in 24 hour time format
   * Jan 22, 2022 @ 9:55 => "09:55:00"
   */
  static formatTime24(dtm: Date | number): string {
    return isValidDate(dtm) ? format(dtm, 'HH:mm:ss') : null;
  }

  /**
   * Format date and time as EN string
   * Jan 22, 2022 @ 9:55 => "09:55:00 AM"
   */
  static formatTimeEN(dtm: Date | number): string {
    return isValidDate(dtm) ? format(dtm, 'hh:mm:ss aa') : null;
  }

  /**
   * Format date and time as EN string
   * Jan 22, 2022 @ 9:55 => "01/22/2022 9:55:00 AM"
   */
  static formatDateTimeEN(dtm: Date | number): string {
    return isValidDate(dtm) ? format(dtm, 'MM/dd/yyyy h:mm:ss aa') : null;
  }

  /**
   * Convert EN date string to local date (assumes ISO timezone is local)
   * ISO - "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 9:55
   * EN - "01/22/2022 09:55:40 AM" => Jan 22, 2022 @ 9:55
   * EN - "01/22/2022" => Jan 22, 2022 @ 00:00
   */
  static parseDate(dtm: string): Date {
    if (!dtm || dtm === '01/01/0001' || dtm === '1/1/0001 12:00:00 AM') return null;
    if (dtm.includes('T')) return DateUtil.parseDateTimeISO(dtm);
    if (dtm.includes(' ')) return DateUtil.parseDateTimeEN(dtm);
    const d = parse(dtm, 'MM/dd/yyyyy', new Date());
    return isValidDate(d) ? d : null;
  }

  /**
   * Convert EN string to local date (assumes ISO timezone is local)
   * "01/22/2022 09:55:40 AM" => Jan 22, 2022 @ 9:55
   */
  static parseDateTimeEN(dtm: string): Date {
    const d = dtm ? parse(dtm, 'MM/dd/yyyyy hh:mm:ss aa', new Date()) : null;
    return isValidDate(d) ? d : null;
  }

  /**
   * Convert ISO string to local date (assumes ISO timezone is local)
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 9:55
   */
  static parseDateTimeISO(dtm: string): Date {
    return dtm ? new Date(dtm) : null;
  }

  /**
   * Convert ISO string to local date (assumes ISO timezone is EST)
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 9:55 EST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 8:55 CST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 7:55 MST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 6:55 PST
   * etc...
   */
  static parseDateTimeISOFromEST(dtm: string): Date {
    return dtm ? new Date(toDate(dtm, { timeZone: 'America/New_York' }).toISOString()) : null;
  }

  /**
   * Convert ISO string to local date (assumes ISO timezone is UTC)
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 4:55 EST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 5:55 CST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 6:55 MST
   * "2022-01-22T09:55:40.00" => Jan 22, 2022 @ 7:55 PST
   * etc...
   */
  static parseDateTimeISOFromUTC(dtm: string): Date {
    return dtm ? new Date(`${dtm}Z`) : null;
  }

  // this replaces broken date-fns method
  static roundToNearestMinutes(
    dirtyDate: Date,
    nearestTo: number = 1,
    roundingMethod: (x: number) => number = Math.round
  ): Date {
    if (nearestTo < 1 || nearestTo > 30) {
      throw new RangeError('`nearestTo` must be between 1 and 30');
    }
    const date = toDate(dirtyDate);
    const minutes = date.getMinutes() + date.getSeconds() / 60;
    const roundedMinutes = roundingMethod(minutes / nearestTo) * nearestTo;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), roundedMinutes);
  }

  static isDate(value: any): value is Date {
    return Object.prototype.toString.call(value) === '[object Date]';
  }
}
