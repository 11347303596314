<!-- @format -->

<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen" class="flex items-center rounded cursor-pointer relative trans-ease-out select-none hover:bg-hover" matRipple>
  <div class="body-1 font-medium leading-snug ml-3 hidden md:block">
    {{ user?.fullName }}
  </div>
  <div class="h-10 w-10 flex items-center justify-center">
    <div *ngIf="!photoURL" class="h-8 w-8 border border-primary-light rounded-full flex items-center justify-center text-primary">
      <mat-icon [icIcon]="user?.isClient ? icClient : icStaff"></mat-icon>
    </div>
    <div *ngIf="photoURL" class="profile-photo rounded-full">
      <img [src]="photoURL" alt="profile picture" class="h-8 w-8 rounded-full" />
    </div>
    <div class="h-2 w-2 rounded-full border border-white absolute bottom-1 right-1" [class.bg-warn]="!connected" [class.bg-green]="connected"></div>
  </div>
</div>
