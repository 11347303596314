/** @format */

import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { ServiceLocator } from '../shared/service-locator';
import { StateService } from './state.service';

let snackPromise = ServiceLocator.get(MatSnackBar);
let translocoPromise = ServiceLocator.get(TranslocoService);

export interface SnackMessageData {
  key: string;
  data?: HashMap<any>;
  icon?: string;
  fallbackKey?: string;
}

const isSnackMessageData = (item: string | SnackMessageData): item is SnackMessageData => {
  return typeof item === 'object' && 'key' in item;
};

@Injectable({
  providedIn: 'any',
})
export class SnackService {
  constructor() {}

  static info = (messageKey: string | SnackMessageData, actionKey: string = '', durationMs: number = 5e3) => {
    return SnackService.snackCustom(messageKey, actionKey, durationMs);
  };

  static warn = (messageKey: string | SnackMessageData, actionKey: string = '', durationMs: number = 5e3) => {
    return SnackService.snackCustom(messageKey, actionKey, durationMs, 'warn', 'assertive');
  };

  static snackCustom = (
    messageKey: string | SnackMessageData,
    actionKey: string = '',
    duration: number = 5e3,
    panelClass: string | string[] = [],
    politeness: AriaLivePoliteness = 'polite'
  ): Promise<MatSnackBarRef<SnackbarComponent>> => {
    const {
      key,
      data = {},
      icon = null,
      fallbackKey = null,
    } = isSnackMessageData(messageKey) ? messageKey : { key: messageKey };
    return Promise.all([snackPromise, translocoPromise, StateService.initialLoad$]).then(
      ([snackBar, translocoService]) => {
        return snackBar.openFromComponent(SnackbarComponent, {
          data: {
            action: actionKey ? translocoService.translate(actionKey) : '',
            text: !fallbackKey
              ? translocoService.translate(key, data)
              : translocoService.translate(key, Object.assign({ fallback: null }, data)) ||
                translocoService.translate(fallbackKey, data),
          },
          duration,
          panelClass: icon ? ['prepend', `${icon}-icon`].concat(panelClass).flat() : panelClass,
          politeness,
        });
      }
    );
  };
}
