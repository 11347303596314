/** @format */

import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { StateService } from './state.service';

let eventHub: signalR.HubConnection;

const connectionId = new BehaviorSubject<string>(null);

// manage event listeners (server -> app)
type EventHandler = (...args: any[]) => void;
const eventHandlerCache: Record<string, EventHandler[]> = {};
const getMethodHandlers = (methodName: string) => eventHandlerCache[methodName] || (eventHandlerCache[methodName] = []);
const addListeners = () => {
  Object.entries(eventHandlerCache).forEach(([methodName, handlers]) => {
    handlers.forEach((handler) => eventHub.on(methodName, handler));
  });
};
const removeListeners = () => {
  Object.keys(eventHandlerCache).forEach((methodName) => eventHub.off(methodName));
};

// manage messages (app -> server)
type EventMessage = {
  type: 'invoke' | 'send';
  methodName: string;
  args: any[];
  resolve: (value: any) => any;
  reject: (reason: any) => any;
};
let messageQueue: EventMessage[] = [];
const sendMessages = () => {
  if (EventService.state !== signalR.HubConnectionState.Connected) {
    // forward event to keepalive stream
    return StateService.keepalive$.next({ sendMessage: true });
  }
  messageQueue.forEach(({ type, methodName, args, resolve, reject }) => {
    if (type === 'invoke') {
      eventHub.invoke(methodName, ...args).then(resolve, reject);
    } else {
      eventHub.send(methodName, ...args).then(resolve, reject);
    }
  });
  messageQueue = [];
};
const rejectMessages = () => {
  messageQueue.forEach((message: EventMessage) => {
    message.reject(new Error(`Unable to ${message.type} ${message.methodName} due to profile change`));
  });
  messageQueue = [];
};

const startConnection = async () => {
  try {
    // start connection
    await eventHub.start();
  } catch (ex) {
    connectionId.next(null);
    return;
  }
  // ensure event hub still exists
  if (!eventHub) return;
  // send queue'd messages
  sendMessages();
  // announce new connection
  connectionId.next(eventHub.connectionId);
};

// manage connect/disconnect for user
const connect = async () => {
  const auth_token = AuthService.getToken() as string;
  const url = `${environment.host}/eventHub?x-device-id=${encodeURIComponent(StateService.deviceId)}`;

  eventHub = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => auth_token,
      headers: { 'x-device-id': StateService.deviceId },
      // logger: signalR.LogLevel.Trace,
      withCredentials: true,
    })
    .withAutomaticReconnect()
    .configureLogging(environment.production ? signalR.LogLevel.Error : signalR.LogLevel.Information)
    .build();

  eventHub.onreconnecting(() => connectionId.next(null));
  eventHub.onreconnected((id) => {
    sendMessages();
    connectionId.next(id);
  });
  eventHub.onclose(() => connectionId.next(null));

  // add event listeners
  addListeners();
  // start connection
  await startConnection();
};

const disconnect = async () => {
  if (!eventHub) return;
  const oldHub = eventHub;
  // remove event listeners
  removeListeners();
  // announce closed connection
  connectionId.next(null);
  // disallow new activity on connection
  eventHub = null;
  // stop connection
  await oldHub.stop();
};

// track authenticated user changes
let personId;
StateService.user$.subscribe((user) => {
  const newPersonId = user?.PersonId;
  // ignore if user unchanged (session extended)
  if (personId === newPersonId) return;
  personId = newPersonId;

  // enable event hub if client or not focused mode
  if (user?.isClient || !StateService.isFocusedMode) {
    StateService.disableEventHub = false;
  }

  // reject queue'd messages
  rejectMessages();

  // manage connection state
  if (!user) return disconnect();
  if (StateService.disableEventHub) return;
  connect();
});

// try to kickstart connection if disconnected
StateService.keepalive$.subscribe((event) => {
  // check hub state
  if (!eventHub || EventService.state !== signalR.HubConnectionState.Disconnected) return;
  // check event status
  if (!event || ('isVisible' in event && !event.isVisible) || ('isOnline' in event && !event.isOnline)) return;
  startConnection();
});

StateService.closing$.subscribe(() => {
  if (EventService.state !== signalR.HubConnectionState.Connected) return;
  disconnect();
});

export class EventService {
  static readonly connected$ = connectionId.asObservable().pipe(map((id) => !!id));
  static readonly connectionId$ = connectionId.asObservable();

  static get state() {
    return eventHub ? eventHub.state : signalR.HubConnectionState.Disconnected;
  }

  /**
   * invokes method event hub
   */
  static invoke<T>(methodName: string, ...args: any[]): Promise<T> {
    let message: EventMessage;
    const result = new Promise<T>((resolve, reject) => {
      message = {
        type: 'invoke',
        methodName,
        args,
        resolve,
        reject,
      };
    });
    messageQueue.push(message);
    sendMessages();
    return result;
  }

  /**
   * adds specified event listener to the event hub
   */
  static on(methodName: string, methodHandler: EventHandler) {
    // add to cache
    const handlers = getMethodHandlers(methodName);
    handlers.push(methodHandler);
    // add to event hub
    if (!eventHub) return;
    eventHub.on(methodName, methodHandler);
  }

  /**
   * removes all event listeners from the event hub
   */
  static off(methodName: string): void;
  /**
   * removes specified event listener from the event hub
   */
  static off(methodName: string, methodHandler: EventHandler): void;
  static off(methodName: string, methodHandler?: EventHandler): void {
    // remove from cache
    if (methodHandler) {
      const handlers = getMethodHandlers(methodName);
      const index = handlers.findIndex((handler) => handler === methodHandler);
      if (index === -1) return;
      handlers.splice(index, 1);
    } else {
      delete eventHandlerCache[methodName];
    }
    // remove from event hub
    if (!eventHub) return;
    if (methodHandler) {
      eventHub.off(methodName, methodHandler);
    } else {
      eventHub.off(methodName);
    }
  }

  /**
   * sends message to event hub
   */
  static send(methodName: string, ...args: any[]): Promise<void> {
    let message: EventMessage;
    const result = new Promise<void>((resolve, reject) => {
      message = {
        type: 'send',
        methodName,
        args,
        resolve,
        reject,
      };
    });
    messageQueue.push(message);
    sendMessages();
    return result;
  }
}
