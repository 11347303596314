/**
 * The primary purpose of this module is to provide a way for static code to have
 * an immediate reference to something that would return a hydrated instance.
 *
 * setInjector can be called in the bootstrap resolution of main.ts
 *
 *   platformBrowserDynamic().bootstrapModule(AppModule)
 *     .then(app => ServiceLocator.setInjector(app.injector));
 *
 * Any static assets that want to access the injector, can simply do something like the following
 *
 *   ServiceLocator.get(Router).then(router => {
 *      router.navigateByUrl('/auth/login');
 *   });
 *
 * The injector itself can be requested too
 *
 *   ServiceLocator.get(Injector).then(injector => {
 *      const router = injector.get(Router);
 *      router.navigateByUrl('/auth/login');
 *   });
 *
 */

import { Injector, ProviderToken } from "@angular/core"

let resolver;
const injector: Promise<Injector> = new Promise((resolve) => {
    resolver = resolve;
});

export class ServiceLocator {

    static get<T>(token: ProviderToken<T>) {
        return injector.then(injector => injector.get(token));
    }

    static setInjector(value: Injector) {
        resolver(value);
    }

}
