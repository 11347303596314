/** @format */

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { MockInterceptor } from 'src/app/interceptors/mock.interceptor';
import { ObservableProvider, ObservableUtils } from 'src/app/shared/observable-utils';
import { ServiceLocator } from 'src/app/shared/service-locator';
import { Link } from '../shared/link';
import { PhoneUtil } from '../shared/phone-util';
import { AuthProfile, AuthService, AuthUser } from './auth.service';
import { SnackService } from './snack.service';
import { StateService } from './state.service';

let authUser: AuthUser;

const urlCollection = {
  /**
   * POST: retrieves the notification options for the provided profileIds
   */
  getNotifyOptions: () => `Organizations/${authUser.OrganizationId}/Clients/GetAllowedCommunicationMode`,
  /**
   * PUT: sets the notification options for the provided clientId
   */
  setNotifyOptions: () =>
    `Organizations/${authUser.OrganizationId}/Clients/${authUser.ClientId}/AllowedCommunicationMode`,
  /**
   * GET:
   * PUT:
   */
  emailUpdate: () => 'ClientAccount/EmailUpdate',
  /**
   * POST:
   */
  emailVerify: () => 'ClientAccount/EmailVerify',
  /**
   * GET:
   */
  photo: () => `Organizations/${authUser.OrganizationId}/Files/ClientPhoto/${authUser.ClientId}`,
  /**
   * GET:
   * PUT:
   */
  profiles: () => 'ClientAccount/Profiles',
};

export interface NotificationOptionsUpdate {
  CallAllowed: boolean; // cms: call allowed, aprn: voicemail allowed
  EmailAllowed: boolean;
  PreferredModeId: number;
  SMSAllowed: boolean;
}

export interface NotificationOptions extends NotificationOptionsUpdate {
  CFAAllowed: boolean;
  ChatMode: string;
  ChatModeId: number;
  DefaultMode: string;
  DefaultModeId: number;
  Email: string;
  IsSmsUnsubscribed: boolean;
  LanguageId: number;
  PersonId: string;
  Phone: string;
  PreferredMode: string;
  PrefLanguageId: number;
  ResubscribeToNumber: string;
  // mapped
  phoneLink?: SafeUrl;
  emailLink?: SafeUrl;
}

export interface EmailRequest {
  status?: string;
  active: boolean;
  email: string;
  expires: number;
  limit?: boolean;
}

export interface ProfileInfo {
  Id: string; // id of profile
  Location: string; // org location associated with profile
  Organization: string; // name of org
  OrganizationId: string; // id of org
  Role: string; // role of profile
}

export interface ProfileOrganizations {
  [id: string]: string;
}

export interface ProfileRole {
  Id: string;
  Name: string;
  DescText: string;
}

interface EmailVerify {
  status: string;
  persons: AuthProfile[];
  limit?: boolean;
}

// get required instances
const httpPromise = ServiceLocator.get(HttpClient);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);
const contextMock = InterceptorContext.get([{ interceptor: MockInterceptor, config: { enable: true } }]);

type Providers = Record<string, ObservableProvider>;

const normalizeOptions = (options: NotificationOptions[]) => {
  return options.map((option) =>
    Object.assign({}, option, {
      PreferredMode: option.PreferredMode || null, // remove bad 0 data
      Email: option.Email?.toLowerCase(),
      Phone: PhoneUtil.format(option.Phone),
      emailLink: Link.fromEmail(option.Email),
      phoneLink: Link.fromPhone(option.Phone),
    })
  );
};

let emailTimeout: number;
const normalizeEmailRequest = (data: EmailRequest): EmailRequest => {
  clearTimeout(emailTimeout);
  if (!data.active) {
    return { active: false, email: null, expires: 0, limit: data.limit };
  }
  const ms = data.expires * 1e3;
  // schedule request reset
  emailTimeout = window.setTimeout(() => {
    ObservableUtils.refreshObservable(providers.emailRequest, true);
  }, ms);
  // set expire to epoc time of expiration
  const result = Object.assign({}, data, { expires: Date.now() + ms });
  return result;
};

const reqNotificationOptions = (http: HttpClient, personIds: string[] = [authUser.PersonId]) =>
  http.post<NotificationOptions[]>(urlCollection.getNotifyOptions(), { personIds }, { context });

const getNotificationOptions = async (profileIds: string[]): Promise<NotificationOptions[]> => {
  if (!authUser) return;
  const http = await httpPromise;
  const response = reqNotificationOptions(http, profileIds);
  const data = await firstValueFrom(response);
  return normalizeOptions(data);
};

const setNotificationOptions = async (options: NotificationOptionsUpdate): Promise<any> => {
  if (!authUser) return;
  const http = await httpPromise;
  const response = http.put<unknown>(urlCollection.setNotifyOptions(), options, { context });
  return await firstValueFrom(response);
};

const updateEmail = async (email: string, password: string) => {
  const http = await httpPromise;
  const response = http.post<EmailRequest>(urlCollection.emailUpdate(), { email, password }, { context });
  try {
    const data = await firstValueFrom(response);
    providers.emailRequest.bs.next(normalizeEmailRequest(data));
  } catch (ex) {
    if (ex instanceof HttpErrorResponse && ex.error.limit) {
      SnackService.warn('auth.failedAttempts');
      AuthService.resetPassword();
      throw new Error('too many');
    } else {
      SnackService.warn('profile.email.passwordError');
    }
  }
};

const verifyEmailAndGetProfiles = async (verifyCode: string) => {
  const http = await httpPromise;
  const response = http.post<EmailVerify>(urlCollection.emailVerify(), { verifyCode }, { context });
  try {
    const data = await firstValueFrom(response);
    if (data.status !== 'SUCCESS') {
      throw data.status;
    }
    AuthService.selectProfileAsync(authUser.PersonId);
    AuthService.updateProfiles(data.persons);
  } catch (ex) {
    if (ex instanceof HttpErrorResponse && ex.error.limit) {
      SnackService.warn('auth.failedAttempts');
    } else {
      throw new Error('not verified');
    }
  }
  clearTimeout(emailTimeout);
  ObservableUtils.resetObservable(providers.emailRequest);
};

const providers: Providers = {
  photo: {
    bs: new BehaviorSubject<string>(null),
    match: () => authUser.ClientId,
    url: urlCollection.photo,
    normalize: (data) => data.Base64Photo && `data:image/png;base64,${data.Base64Photo}`,
  },
  profiles: {
    bs: new BehaviorSubject<ProfileInfo[]>(null),
    match: () => authUser.PersonId,
    // context: contextMock,
    url: urlCollection.profiles,
  },
  emailRequest: {
    bs: new BehaviorSubject<EmailRequest>(null),
    match: () => authUser.exp,
    url: urlCollection.emailUpdate,
    // context: contextMock,
    normalize: normalizeEmailRequest,
  },
  notificationOptions: {
    bs: new BehaviorSubject<NotificationOptions>(null),
    match: () => authUser.PersonId,
    url: () => reqNotificationOptions,
    // context: contextMock,
    normalize: (data) => normalizeOptions(data)[0],
  },
};

// track changes in current user profile
StateService.user$.subscribe((user) => {
  authUser = user;
  if (!user) {
    Object.values(providers).forEach(ObservableUtils.resetObservable);
    return;
  }
  Object.values(providers).forEach(ObservableUtils.refreshObservable);
});

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor() {}

  static emailRequest$ = ObservableUtils.getObservable<EmailRequest>(providers.emailRequest);
  static notificationOptions$ = ObservableUtils.getObservable<NotificationOptions>(providers.notificationOptions);
  static organizations$ = ObservableUtils.getObservable<ProfileInfo[]>(providers.profiles).pipe(
    map(
      // return unique orgs from profile list
      (profiles): ProfileOrganizations =>
        profiles?.reduce((result, profile) => {
          result[profile.OrganizationId] = profile.Organization;
          return result;
        }, {})
    )
  );
  static profiles$ = ObservableUtils.getObservable<ProfileInfo[]>(providers.profiles);

  // disabled client photo for now
  // static photo$ = ObservableUtils.getObservable<string>(providers.photo);
  static photo$: Observable<string> = providers.photo.bs.asObservable();

  static getNotificationOptions = getNotificationOptions;
  static refreshUserNotificationOptions = () => ObservableUtils.refreshObservable(providers.notificationOptions, true);
  static setNotificationOptions = setNotificationOptions;
  static updateEmail = updateEmail;
  static verifyEmail = verifyEmailAndGetProfiles;
}
