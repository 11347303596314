/** @format */
/**
 * This resolver just insures that all the defined content is preloaded before resolving
 * It's not responsible for keeping track of the active content bundles
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { StateService } from 'src/app/services/state.service';

export type LangProviderScope = {
  scope: string;
  alias?: string;
};

@Injectable({
  providedIn: 'root',
})
export class LangResolver implements Resolve<Promise<void>> {
  private lastLPS: LangProviderScope | LangProviderScope[];

  // create fake content listener to trigger content bundle download
  private loadContentPath = async (path: string) => {
    await firstValueFrom(this.translocoService.selectTranslation(path));
  };

  // process provider scope for active lang
  private loadScopedContent = (activeLang, providerScope: LangProviderScope) => {
    const { scope, alias } = providerScope;
    if (!scope) return;
    this.loadContentPath(`${scope}/${activeLang}`);
    // apply content alias if needed
    if (!alias) return;
    this.translocoService._setScopeAlias(scope, alias);
  };
  // process LangProviderScope
  private processLPS() {
    const activeLang = this.translocoService.getActiveLang();
    // preload scoped content
    if ('length' in this.lastLPS) {
      this.lastLPS.forEach((lps) => {
        this.loadScopedContent(activeLang, lps);
      });
    } else {
      this.loadScopedContent(activeLang, this.lastLPS);
    }
  }

  constructor(private translocoService: TranslocoService) {
    // preload base content
    const activeLang = this.translocoService.getActiveLang();
    this.loadContentPath(activeLang);

    // be sure we have ALL content bundles for current route if lang changes
    this.translocoService.langChanges$.subscribe(() => {
      if (!this.lastLPS) return;
      this.processLPS();
    });
  }

  resolve(route: ActivatedRouteSnapshot): Promise<void> {
    this.lastLPS = route.data.lang || {};
    // preload content
    this.processLPS();
    // monitor content load before resolving
    return new Promise((resolve) => {
      StateService.loadingCount$.pipe(takeWhile((cnt) => cnt !== 0)).subscribe({ complete: resolve });
    });
  }

  async contentAvailable(scope: string) {
    const activeLang = this.translocoService.getActiveLang();
    await this.loadContentPath(`${scope}/${activeLang}`);
  }
}
