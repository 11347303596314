/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@visurel/iconify-angular';
import { SidenavItemComponent } from './sidenav-item.component';

@NgModule({
  declarations: [SidenavItemComponent],
  imports: [CommonModule, IconModule, MatIconModule, MatRippleModule, RouterModule, TranslocoModule],
  exports: [SidenavItemComponent],
})
export class SidenavItemModule {}
