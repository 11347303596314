/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AgreementsGuardService } from './guards/agreements-guard.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { AuthResetService } from './guards/auth-reset.service';
import { AccessResolver } from './resolvers/access.resolver';
import { updateResolver } from './resolvers/update.resolver';

const routes: Routes = [
  // authentication
  {
    path: '',
    // ensure all assets are loaded before starting up
    // resolve: { assets: AssetResolver },
    children: [
      // authentication
      {
        path: 'auth',
        canActivate: [AuthResetService],
        runGuardsAndResolvers: 'always',
        resolve: { update: updateResolver },
        children: [
          {
            path: 'login',
            loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule),
          },
          {
            path: 'forgot-password',
            loadChildren: () =>
              import('./pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
          },
          {
            path: 'reset-password/:resetId',
            loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then((m) => m.ResetModule),
          },
          {
            path: 'transfer',
            loadChildren: () => import('./pages/auth/transfer/transfer.module').then((m) => m.TransferModule),
          },
          {
            path: 'lock',
            loadChildren: () => import('./pages/auth/pin-lock/pin-lock.module').then((m) => m.PinLockModule),
          },
        ],
      },
      // close
      {
        path: 'close',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/close/close.module').then((m) => m.CloseModule),
          },
        ],
      },
      // agreements
      {
        path: 'agreements',
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: 'tac',
            loadChildren: () =>
              import('./pages/agreements/termsandconditions/termsandconditions.module').then(
                (m) => m.TermsAndConditionsModule
              ),
          },
        ],
      },
      // scheduler
      {
        path: 'scheduler',
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/scheduler/scheduler.module').then((m) => m.SchedulerModule),
          },
        ],
      },
      // video (tac agreement not required)
      {
        path: 'video/room/:roomId',
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./pages/video/room/video.module').then((m) => m.VideoRoomModule),
      },
      // main app (protected)
      {
        path: '',
        component: CustomLayoutComponent,
        canActivate: [AuthGuardService, AgreementsGuardService],
        runGuardsAndResolvers: 'always',
        resolve: { allowed: AccessResolver, update: updateResolver },
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingModule),
          },
          {
            path: 'about',
            loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
          },
          {
            path: 'access',
            loadChildren: () =>
              import('./pages/access/access-index/access-index.module').then((m) => m.AccessIndexModule),
          },
          {
            path: 'action',
            loadChildren: () => import('./pages/action/action.module').then((m) => m.ActionModule),
          },
          {
            path: 'admin',
            children: [
              {
                path: 'announcements',
                loadChildren: () =>
                  import('./pages/admin/announcements/announcements.module').then((m) => m.AnnouncementsModule),
              },
              {
                path: 'authorizations',
                loadChildren: () =>
                  import('./pages/admin/authorizations/authorizations.module').then((m) => m.AuthorizationsModule),
              },
              // {
              //   path: 'letters',
              //   loadChildren: () => import('./pages/admin/letters/letters.module').then((m) => m.LettersModule),
              // },
              {
                path: 'locations',
                loadChildren: () => import('./pages/admin/locations/locations.module').then((m) => m.LocationsModule),
              },
              {
                path: 'org',
                loadChildren: () => import('./pages/admin/org/org.module').then((m) => m.AdminOrgModule),
              },
              {
                path: 'values',
                loadChildren: () => import('./pages/admin/values/values.module').then((m) => m.AdminValuesModule),
              },
              {
                path: 'poolPhones',
                loadChildren: () =>
                  import('./pages/admin/pool-phones/pool-phones.module').then((m) => m.PoolPhonesModule),
              },
            ],
          },
          {
            path: 'aprn',
            children: [
              {
                path: 'client',
                children: [
                  {
                    path: 'intake',
                    loadChildren: () =>
                      import('./pages/aprn/client-intake/aprn-client-intake.module').then(
                        (m) => m.AprnClientIntakeModule
                      ),
                  },
                  {
                    path: ':profileId',
                    loadChildren: () =>
                      import('./pages/aprn/client/aprn-client.module').then((m) => m.APRNClientModule),
                  },
                ],
              },
              {
                path: 'reports',
                loadChildren: () => import('./pages/aprn/reports/aprn-reports.module').then((m) => m.AprnReportsModule),
              },
            ],
          },
          {
            path: 'calendar',
            children: [
              {
                path: 'edit',
                loadChildren: () =>
                  import('./pages/calendar/edit/calendar-edit.module').then((m) => m.CalendarEditModule),
              },
              {
                path: 'requests',
                loadChildren: () =>
                  import('./pages/calendar/requests/calendar-requests.module').then((m) => m.CalendarRequestsModule),
              },
              {
                path: '',
                loadChildren: () => import('./pages/calendar/calendar.module').then((m) => m.CalendarModule),
              },
            ],
          },
          {
            path: 'chat',
            loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatModule),
          },
          {
            path: 'client',
            children: [
              {
                path: 'create',
                data: { mode: 'CREATE' },
                loadChildren: () => import('./pages/client/view/client-view.module').then((m) => m.ClientViewModule),
              },
              {
                path: 'search',
                loadChildren: () =>
                  import('./pages/client/search/client-search.module').then((m) => m.ClientSearchModule),
              },
              {
                path: ':profileId',
                data: { mode: 'EDIT' },
                loadChildren: () => import('./pages/client/view/client-view.module').then((m) => m.ClientViewModule),
              },
            ],
          },
          {
            path: 'files',
            children: [
              {
                path: 'educational',
                loadChildren: () =>
                  import('./pages/files/educational/educational.module').then((m) => m.EducationalModule),
              },
              {
                path: 'media',
                loadChildren: () => import('./pages/files/media/media.module').then((m) => m.MediaModule),
              },
            ],
          },
          {
            path: 'intakeforms',
            loadChildren: () => import('./pages/intake-forms/intake-forms.module').then((m) => m.IntakeFormsModule),
          },
          {
            path: 'ol',
            children: [
              {
                path: 'scheduler',
                loadChildren: () => import('./pages/ol/ol-scheduler.module').then((m) => m.OLSchedulerModule),
              },
            ],
          },
          {
            path: 'profile',
            loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
          },
          {
            path: 'report',
            children: [
              {
                path: 'dashboard',
                loadChildren: () =>
                  import('./pages/report/dashboard/report-dashboard.module').then((m) => m.ReportDashboardModule),
              },
              {
                path: 'legacy',
                loadChildren: () =>
                  import('./pages/report/legacy/report-legacy.module').then((m) => m.ReportLegacyModule),
              },
              {
                path: 'test',
                loadChildren: () =>
                  import('./pages/report/report-test/report-test.module').then((m) => m.ReportTestModule),
              },
              {
                path: 'view',
                loadChildren: () =>
                  import('./pages/report/report-view/report-view.module').then((m) => m.ReportViewModule),
              },
            ],
          },
          {
            path: 'rewards',
            loadChildren: () => import('./pages/rewards/rewards.module').then((m) => m.RewardsModule),
          },
          {
            path: 'staff',
            children: [
              {
                path: 'create',
                data: { mode: 'CREATE' },
                loadChildren: () => import('./pages/staff/view/staff-view.module').then((m) => m.StaffViewModule),
              },
              {
                path: 'view/:profileId',
                data: { mode: 'READ' },
                loadChildren: () => import('./pages/staff/view/staff-view.module').then((m) => m.StaffViewModule),
              },
              {
                path: 'viewById/:userId',
                data: { mode: 'READ' },
                loadChildren: () => import('./pages/staff/view/staff-view.module').then((m) => m.StaffViewModule),
              },
              {
                path: 'edit/:profileId',
                data: { mode: 'UPDATE' },
                loadChildren: () => import('./pages/staff/view/staff-view.module').then((m) => m.StaffViewModule),
              },
              {
                path: 'directory',
                loadChildren: () =>
                  import('./pages/staff/directory/staff-directory.module').then((m) => m.StaffDirectoryModule),
              },
              {
                path: 'roles',
                loadChildren: () => import('./pages/staff/roles/staff-roles.module').then((m) => m.StaffRolesModule),
              },
              {
                path: 'search',
                loadChildren: () => import('./pages/staff/search/staff-search.module').then((m) => m.StaffSearchModule),
              },
            ],
          },
          {
            path: 'ultrasound',
            children: [
              {
                path: 'review',
                loadChildren: () =>
                  import('./pages/ultrasound/review/ultrasound-review.module').then((m) => m.UltrasoundReviewModule),
              },
            ],
          },
          // errors
          {
            path: 'errors/401',
            loadChildren: () => import('./pages/errors/error-401/error-401.module').then((m) => m.Error401Module),
          },
          {
            path: '**',
            pathMatch: 'full',
            loadChildren: () => import('./pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    // QuicklinkModule,
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
