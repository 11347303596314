/** @format */

type ComparableValue = number | boolean | string | Date;

export class ArrayUtil {
  // returns inverted copy of provided array
  static readonly reverse = (input: any[]) => input?.slice().reverse();

  // array sort helper
  // returns number +/- or 0 if equal
  // supported types (number, boolean, string, Date, null, undefined)
  //
  // ascending:
  // [6,1,3,5,4,2].sort(compare) => [1,2,3,4,5,6]
  //
  // descending:
  // [6,1,3,5,4,2].sort((a,b) => -compare(a, b)) [6,5,4,3,2,1]
  //
  // chaining: (prop1 asc, prop2 desc)
  // arrayOfObjects.sort((a,b) => compare(a.prop1, b.prop1) || -compare(a.prop2, b.prop2))
  //
  static readonly compare = (a: ComparableValue, b: ComparableValue): number => {
    const result = typeof a === 'string' ? a.localeCompare(b as string) : (a as any) - (b as any);
    return isNaN(result) ? 0 : result;
  };
}
