/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@visurel/iconify-angular';
import { ClearButtonComponent } from './clear-button.component';

@NgModule({
  declarations: [ClearButtonComponent],
  imports: [CommonModule, MatButtonModule, IconModule],
  exports: [ClearButtonComponent],
})
export class ClearButtonModule {}
