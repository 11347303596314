/**
 * Static service for managing authentication
 *
 * @format
 */

// libraries
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

// local code
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { ServiceLocator } from 'src/app/shared/service-locator';
import { environment } from 'src/environments/environment';
import { DialogData } from '../dialogs/generic-dialog/generic-dialog.component';
import { RetryInterceptor } from '../interceptors/retry.interceptor';
import { Link } from '../shared/link';
import { LazyDialogService } from './lazy-dialog.service';
import { PersonBase, PersonService, normalizePersonBase } from './person.service';
import { SnackService } from './snack.service';
import { StateService } from './state.service';
import { StorageService } from './storage.service';

const urlCollection = {
  /**
   * POST: Initiate the reset password process and sends a link to the provided email address
   */
  resetStart: () => `ClientAccount/Reset`,
  /**
   * GET: Verifies if the reset id is active
   * PUT: Validates email/reset id and sets the corresponding account password
   */
  resetValidate: (resetId: string) => `ClientAccount/Reset/${encodeURIComponent(resetId)}`,
  postValidatePin: (orgId: string) => `Organizations/${orgId}/LoginPin`,
  /**
   * POST: Validates email/password and returns a list of corresponding profiles
   */
  getProfiles: () => `ClientAccount/ClientAuthLookup`,
  /**
   * GET: gets link that can be used to launch new cfa window for client
   */
  getTransferClient: (orgId: string, clientId: string) => `Organizations/${orgId}/ClientTransfer/${clientId}`,
  /**
   * POST: Gets a JWT for the requested profile
   */
  setProfiles: () => `ClientAccount/ClientAuthLogin`,
  /**
   * PUT: Accepts the client terms and conditions for the given profile
   */
  acceptClientTac: (orgId: string, personId: string) =>
    `Organizations/${orgId}/Person/${personId}/CFA/AcceptTermsAndConditions?value=1`,
  /**
   * PUT: Accepts the staff terms and conditions for the given profile
   */
  acceptStaffTac: (orgId: string, personId: string) =>
    `Organizations/${orgId}/Person/${personId}/NL/AcceptTermsAndConditions?value=1`,
};

// definitions
export interface AuthUser extends PersonBase {
  sub: string; // username
  iat: number; // issued at in epoc seconds
  nbf: number; // not before in epoc seconds
  exp: number; // expiration in epoc seconds
  CanEPrescribe: boolean;
  ClientId: string;
  FullName: string;
  Id: string; // userId
  isAPRN: boolean;
  isClient: boolean;
  isCMS: boolean;
  isHBIAdmin: boolean;
  isOL: boolean;
  isPhysician: boolean;
  Lang: string;
  Location: string;
  Organization: string;
  OrganizationId: string;
  Permission: string[];
  Role: string;
  TermsAndConditionsAccepted: boolean;
}
type JWTUser = Omit<AuthUser, 'TermsAndConditionsAccepted'> & {
  TermsAndConditionsAccepted: string; // "true" or "false" bleh fix your service!
};
export type AuthProfile = {
  Id: string;
  Name: string;
  Org: string;
  Role: string;
  UserName: string;
  Location: string;
  isClient?: boolean;
  roleType?: string;
};
export type AuthAuthorizations = Record<string, boolean>;
export type AuthServiceResult = {
  status: AuthStatus;
};
type AuthStatus = 'SUCCESS' | 'VALID' | 'EXPIRED' | 'ERROR';
type ServiceResponse = {
  status: AuthStatus;
  access_token?: string; // jwt bearer token
  login_token?: string;
  persons: AuthProfile[];
};
interface AuthClientTransfer {
  url: string;
}

// storage
const storage = new StorageService('auth');
const userProp = 'user';
const profilesProp = 'profiles';
const returnUrlProp = 'returnUrl';
const authTokenProp = 'aToken';
const expiresProp = 'expires';
const loginTokenProp = 'lToken';
const targetProp = 'target';

const invalidTargets = {
  '/agreements/tac': 1,
  '/errors/401': 1,
  '/errors/404': 1,
};

// service
const errorStatus = 'ERROR';

// get required instances
const httpPromise = ServiceLocator.get(HttpClient);
const routerPromise = ServiceLocator.get(Router);
const zonePromise = ServiceLocator.get(NgZone);
const dialogPromise = ServiceLocator.get(MatDialog);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { access: false } }]);

// get http instance synchronously
// const injector = Injector.create({
//   providers: [
//     { provide: Router, deps: [null, UrlSerializer, ChildrenOutletContexts] },
//     { provide: UrlSerializer, useValue: new DefaultUrlSerializer() },
//   ]
// });
// const httpHandler = new HttpXhrBackend({ build: () => new XMLHttpRequest });
// const http = new HttpClient(httpHandler);

let pinCount = 0;
let authenticated = false;
let activeUser: AuthUser;
let authorizations: AuthAuthorizations = {};
let bcProcessing: boolean;
let warnSnack: MatSnackBarRef<any> = null;
const logoutTimer = { timer: 0, warn: 0, logout: 0 };

const decodeJwt = (token: string) => {
  const [header, payload, signature] = token.split('.');
  return {
    token,
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload)) as JWTUser,
    signature,
  };
};

const normalizeProfiles = (profiles: AuthProfile[]) => {
  return profiles.map((profile) => {
    return Object.assign(profile, {
      isClient: profile.Role === 'Client',
      UserName: undefined,
      Name: profile.Name.replace(/^Org. /, ''),
      roleType: PersonService.getRoleTypeByName(profile.Role),
    });
  });
};

StateService.profiles$.subscribe((profiles) => {
  if (!profiles) return;
  storage.setSessionData(profilesProp, profiles);
});

const processAuth = (jwt) => {
  // get jwt details
  const { payload = null, token } = decodeJwt(jwt);
  authenticated = true;
  // reset pin count
  pinCount = 0;
  // fix payload typing issues
  activeUser = Object.assign({}, normalizePersonBase(payload), {
    TermsAndConditionsAccepted: 'true' === payload?.TermsAndConditionsAccepted.toLowerCase(),
  });

  // calculate client side expiration minus 10 seconds
  const now = Math.floor(Date.now() / 1e3);
  const issued = activeUser.iat;
  const expires = activeUser.exp - now;
  const sessionLength = AuthService.maxSessionLength ? Math.min(AuthService.maxSessionLength, expires) : expires;
  const expiresCalc = (issued + sessionLength - 10) * 1e3;

  // setup logout warning and logout action timers
  generateLogoutTimers(issued * 1e3, expiresCalc);

  // save auth info
  storage.setSessionData({
    [authTokenProp]: token,
    [expiresProp]: expiresCalc,
  });
  // normalize authorizations
  authorizations = activeUser.Permission?.reduce((result, authorization) => {
    result[authorization] = true;
    return result;
  }, {});
  // normalize activeUser
  delete activeUser.Permission;
  activeUser.isClient = activeUser.Role === 'Client';
  activeUser.isAPRN = activeUser.OrganizationId === environment.aprnOrgId;
  activeUser.isOL = 'OptionLine.All' in authorizations;
  activeUser.isPhysician = PersonService.getRoleTypeByName(activeUser.Role) === 'PHYSICIAN';
  activeUser.isCMS = !activeUser.isAPRN && !activeUser.isOL && !activeUser.isPhysician && !activeUser.isClient;
  activeUser.isHBIAdmin = 'Application.All' in authorizations;
  // append authorizations
  Object.assign(authorizations, {
    'User.isAPRN': activeUser.isAPRN,
    'User.isClient': activeUser.isClient,
    'User.isCMS': activeUser.isCMS,
    'User.isOL': activeUser.isOL,
    'User.isPhysician': activeUser.isPhysician,
  });
  // announce authorizations
  StateService.authorizations$.next(authorizations);
  // announce activeUser
  StateService.user$.next(payload && activeUser);
};

const generateLogoutTimers = (issued, expires) => {
  const durationMax = 30 * 60 * 1e3; // 30 minutes max
  const durationMin = 1 * 60 * 1e3; // 1 minutes max
  const durationCalc = (expires - issued) * 0.08; // 5min if 1hr, 30min if 6hrs
  const duration = durationCalc < durationMin ? durationMin : durationCalc > durationMax ? durationMax : durationCalc;
  logoutTimer.warn = expires - duration;
  logoutTimer.logout = expires;
  checkTimers();
};

// check the logout timers and perform actions if needed
const checkTimers = () => {
  // console.log('checkTimers:', JSON.stringify(logoutTimer), logoutTimer.warn - Date.now());
  const now = Date.now();
  clearTimeout(logoutTimer.timer);
  if (!logoutTimer.logout) return;

  if (!activeUser || logoutTimer.logout < now) {
    return AuthService.logout();
  }
  if (logoutTimer.warn && logoutTimer.warn < now) {
    timeoutWarn(logoutTimer.logout - now);
    logoutTimer.warn = 0;
    logoutTimer.timer = window.setTimeout(async () => (await zonePromise).run(checkTimers), logoutTimer.logout - now);
  } else {
    logoutTimer.timer = window.setTimeout(async () => (await zonePromise).run(checkTimers), logoutTimer.warn - now);
  }
};

// display timeout warning
const timeoutWarn = (warnTime) => {
  SnackService.info('auth.expiring', 'auth.extend', warnTime).then((snack) => {
    warnSnack = snack;
    snack.onAction().subscribe(() => {
      warnSnack = null;
      AuthService.selectProfileAsync(activeUser?.PersonId).then((result) => {
        if (result.status === 'SUCCESS') return;
        AuthService.logout();
        SnackService.warn('auth.status.failure.extend');
      });
    });
  });
};

let timeoutInactive;
let isVisible = true;
let isLocked = false;

const updateInactiveTimeout = () => {
  if (!isVisible) return;
  clearTimeout(timeoutInactive);
  if (isLocked || !activeUser || !activeUser.isCMS) return;
  timeoutInactive = window.setTimeout(async () => (await zonePromise).run(AuthService.lock), 30 * 60 * 1e3);
};

StateService.keepalive$.subscribe(async (event) => {
  // update inactivity timer
  isVisible = 'isVisible' in event ? event.isVisible : isVisible;
  isLocked = 'isLocked' in event ? event.isLocked : isLocked;
  updateInactiveTimeout();
  // check for logout if browser tab has just been focused again
  if (!event.isVisible) return;
  const zone = await zonePromise;
  zone.run(checkTimers);
});

const announce = (message) => {
  // don't allow messages to trigger new messages
  if (bcProcessing) return;
  StateService.postBroadcastMessage(message);
};

StateService.broadcastMessages$.subscribe(async (event: MessageEvent) => {
  bcProcessing = true;
  // switch (event.data) {
  //   case 'logout': await AuthService.logout(); break;
  //   default: console.log(event.data);
  // }
  bcProcessing = false;
});

export class AuthService {
  static get returnPath(): string {
    const value = storage.getSessionData<string>(targetProp) || '/';
    return value;
  }

  static set returnPath(value: string) {
    if (invalidTargets[value]) return;
    storage.setSessionData(targetProp, value);
  }

  static maxSessionLength = 0;

  // get the last login username used
  static getUsername(): string {
    return storage.getLocalData(userProp) as string;
  }

  // do we have a valid token?
  static isAuthenticated(path) {
    AuthService.getToken();
    if (!authenticated || !activeUser) {
      AuthService.returnPath = path;
    }
    return authenticated;
  }

  // get saved token, if still valid
  static getToken() {
    const expires = storage.getSessionData<number>(expiresProp) || 0;
    if (expires > Date.now()) {
      const token = storage.getSessionData<string>(authTokenProp);
      if (token && !authenticated) {
        processAuth(token);
        const profiles = storage.getSessionData<AuthProfile[]>(profilesProp);
        const norm = normalizeProfiles(profiles || []);
        StateService.profiles$.next(norm);
      }
      return token || null;
    } else if (expires) {
      AuthService.resetAuth();
    }
    return null;
  }

  // request reset link be sent to email
  static async passwordResetSendLink(email: string): Promise<AuthServiceResult> {
    // get http client from service locator
    return httpPromise.then((http) => {
      // Request reset link
      return http
        .post(
          urlCollection.resetStart(),
          // `ClientAccount/Reset`,
          {
            email,
          },
          { context }
        )
        .toPromise()
        .then(
          (data: ServiceResponse) => {
            return { status: data.status || errorStatus };
          },
          (data: HttpErrorResponse) => {
            // console.warn('failure', data);
            const status = data.error.status || errorStatus;
            return { status };
          }
        );
    });
  }

  static async resetValidateIdAsync(resetId: string): Promise<AuthServiceResult> {
    // get http client from service locator
    return httpPromise.then((http) => {
      return http
        .get(
          urlCollection.resetValidate(resetId),
          // `ClientAccount/Reset/${encodeURIComponent(resetId)}`,
          { context }
        )
        .toPromise()
        .then(
          (data: ServiceResponse) => {
            return { status: data.status || errorStatus };
          },
          (data: HttpErrorResponse) => {
            // console.warn('failure', data);
            const status = data.error.status || errorStatus;
            return { status };
          }
        );
    });
  }

  static async resetPasswordAsync(resetId: string, email: string, password: string): Promise<AuthServiceResult> {
    // get http client from service locator
    return httpPromise.then((http) => {
      return http
        .put(
          urlCollection.resetValidate(resetId),
          // `ClientAccount/Reset/${encodeURIComponent(resetId)}`,
          {
            email,
            password,
          },
          { context }
        )
        .toPromise()
        .then(
          (data: ServiceResponse) => {
            storage.setSessionData(loginTokenProp, data.login_token);
            StateService.isAuthTransfer = false;
            const norm = normalizeProfiles(data.persons || []);
            StateService.profiles$.next(norm);
            return { status: data.status || errorStatus };
          },
          (data: HttpErrorResponse) => {
            // console.warn('failure', data);
            const status = data.error.status || errorStatus;
            return { status };
          }
        );
    });
  }

  static async lookupAsync(user: string, pass: string, remember?: boolean): Promise<AuthServiceResult> {
    let result: AuthServiceResult = { status: 'SUCCESS' };
    // get http client from service locator
    const http = await httpPromise;
    // delete username
    if (!remember) {
      storage.removeLocalData(userProp);
    }
    const requestData = {
      email: user,
      password: pass,
    };
    // request list of user profiles
    const response = http.post<ServiceResponse>(urlCollection.getProfiles(), requestData, { context });
    try {
      const data = await firstValueFrom(response);
      // save username
      if (remember) {
        storage.setLocalData(userProp, user);
      }
      storage.setSessionData(loginTokenProp, data.login_token);
      StateService.isAuthTransfer = false;
      const norm = normalizeProfiles(data.persons || []);
      StateService.profiles$.next(norm);
    } catch (ex) {
      AuthService.resetAuth();
      result = { status: 'ERROR' };
    } finally {
      return result;
    }
  }

  static updateProfiles(profiles: AuthProfile[]) {
    const norm = normalizeProfiles(profiles || []);
    StateService.profiles$.next(norm);
  }

  static async selectProfileAsync(personId: string, loginToken?: string): Promise<AuthServiceResult> {
    let result: AuthServiceResult = { status: 'SUCCESS' };
    // get http client from service locator
    const http = await httpPromise;
    const isLogin = !activeUser;
    const isExtension = !isLogin && personId === activeUser.PersonId;
    // reset auth user details
    if (!isExtension) {
      StateService.authorizations$.next({});
      StateService.user$.next(null);
    }
    const requestData = {
      login_token: loginToken || storage.getSessionData<string>(loginTokenProp),
      id: personId,
    };
    // change/extend user profile
    const response = http.post<ServiceResponse>(
      urlCollection.setProfiles(),
      // `ClientAccount/ClientAuthLogin`,
      requestData,
      { context }
    );
    processResponse: try {
      const data = await firstValueFrom(response);
      storage.setSessionData(loginTokenProp, data.login_token);
      processAuth(data.access_token);
      if (isExtension) {
        break processResponse;
      }
      const router = await routerPromise;
      if (isLogin) {
        router.navigateByUrl(AuthService.returnPath, { replaceUrl: isLogin });
        break processResponse;
      }
      let child = router.routerState.root;
      while (child.firstChild) {
        child = child.firstChild;
      }
      // check authorizations
      const reqAuth = child.snapshot.data['authorizations'];
      if (
        reqAuth &&
        !reqAuth.some((name) => {
          return authorizations[name];
        })
      ) {
        // navigate home if not authorized
        router.navigateByUrl('/', { replaceUrl: isLogin });
      }
    } catch (ex) {
      AuthService.resetAuth();
      result = { status: 'ERROR' };
    } finally {
      return result;
    }
  }

  static acceptTermsAndConditions() {
    const url =
      activeUser.Role === 'Client'
        ? urlCollection.acceptClientTac(activeUser.OrganizationId, activeUser?.PersonId) // `Organizations/${activeUser.OrganizationId}/Person/${activeUser?.PersonId}/CFA/AcceptTermsAndConditions?value=1`
        : urlCollection.acceptStaffTac(activeUser.OrganizationId, activeUser?.PersonId); //  `Organizations/${activeUser.OrganizationId}/Person/${activeUser?.PersonId}/NL/AcceptTermsAndConditions?value=1`;
    return Promise.all([httpPromise, routerPromise]).then(([http, router]) => {
      // return Promise.resolve()
      return http
        .put(url, null, { context })
        .toPromise()
        .then(
          (data) => {
            return this.selectProfileAsync(activeUser?.PersonId).then(() => {
              // did it work?
              if (!activeUser.TermsAndConditionsAccepted) {
                throw new Error('error');
              }
              router.navigateByUrl(AuthService.returnPath, {
                replaceUrl: true,
              });
            });
          },
          (data: HttpErrorResponse) => {
            throw data;
          }
        )
        .then(
          () => {
            return { status: 'SUCCESS' };
          },
          () => {
            SnackService.warn('auth.status.failure.tac');
            return { status: 'ERROR' };
          }
        );
    });
  }

  static async logout(status: string = 'logout', returnPath: string = '/') {
    const dialog = await dialogPromise;
    dialog.closeAll();
    AuthService.resetAuth();
    announce('logout');
    AuthService.returnPath = returnPath;
    const router = await routerPromise;
    router.navigate(['/auth/login'], { state: { status }, onSameUrlNavigation: 'reload' });
  }

  static async resetPassword() {
    AuthService.resetAuth();
    announce('logout');
    const router = await routerPromise;
    router.navigate(['/auth/forgot-password']);
  }

  static async passwordResetRequest(email: string) {
    const result = await AuthService.passwordResetSendLink(email);
    switch (result.status) {
      case 'SUCCESS':
        // show instructions
        const dialogConfig = new MatDialogConfig<DialogData>();
        const data: DialogData = {
          title: 'auth.passReset.dialogTitle',
          content: 'auth.passReset.dialogContent',
          contentData: { email },
          maxHeight: 'sm',
          defaultValue: false,
          enableMarkdown: true,
          actions: [
            {
              label: 'form.button.ok',
              value: true,
              color: 'primary',
            },
          ],
        };
        Object.assign(dialogConfig, {
          autoFocus: true,
          closeOnNavigation: true,
          disableClose: true,
          data,
        });
        LazyDialogService.open('generic-dialog', dialogConfig);
        break;
      default:
        SnackService.warn('auth.passReset.failure');
    }
  }

  static async lock(returnUrl?: string) {
    const router = await routerPromise;
    const dialog = await dialogPromise;
    dialog.closeAll();
    storage.setSessionData(returnUrlProp, returnUrl || router.routerState.snapshot.url);
    router.navigate(['/auth/lock'], { replaceUrl: true });
    StateService.keepalive$.next({ isLocked: true });
  }

  static async unlock(pin: string) {
    const returnUrl = storage.getSessionData<string>(returnUrlProp) || '/';
    if (!AuthService.isAuthenticated(returnUrl)) {
      storage.removeSessionData(returnUrlProp);
      AuthService.logout();
      return false;
    }
    const http = await httpPromise;
    const router = await routerPromise;
    const formData = new FormData();
    formData.append('LoginPin', pin);
    formData.append('LoginAttempts', `${pinCount}`);
    const response = http.post<any>(urlCollection.postValidatePin(activeUser.OrganizationId), formData, {
      context: RetryInterceptor.update({ disabled: true }, context),
      params: { format: 'json' },
    });
    try {
      const data = await firstValueFrom(response);
      if (data?.status === 'SUCCESS') {
        pinCount = 0;
        storage.removeSessionData(returnUrlProp);
        StateService.keepalive$.next({ isLocked: false });
        window.setTimeout(
          async () => (await zonePromise).run(() => router.navigate([returnUrl], { replaceUrl: true })),
          0
        );
        return true;
      }
      if (data?.status === 'LOGOFF') {
        AuthService.logout();
      }
      pinCount++;
    } catch (ex) {}
    return false;
  }

  static async transferClient(clientId: string, target: string, returnUrl?: string) {
    const http = await httpPromise;
    try {
      const response = http.get<AuthClientTransfer>(
        urlCollection.getTransferClient(activeUser.OrganizationId, clientId),
        {
          context,
          params: { target },
        }
      );
      const { url } = await firstValueFrom(response);
      const success = Link.open(url);
      if (success) {
        AuthService.lock(returnUrl);
      } else {
        SnackService.warn('auth.status.failure.window');
      }
    } catch (ex) {
      SnackService.warn('auth.status.failure.transfer');
    }
  }

  static resetAuth = () => {
    authenticated = false;
    activeUser = null;
    // save return path before clearing data
    const rp = AuthService.returnPath;
    storage.removeSessionData();
    // restore return path
    AuthService.returnPath = rp;
    StateService.user$.next(null);
    StateService.authorizations$.next({});
    StateService.profiles$.next([]);
    clearTimeout(logoutTimer.timer);
    logoutTimer.warn = 0;
    logoutTimer.logout = 0;
    if (warnSnack) {
      warnSnack.dismiss();
      warnSnack = null;
    }
  };
}
