/**
 * This module is used to validate if a user is authenticated before allowing the route to continue.
 * If they are not authenticated, by default they will be routed to /auth/login,
 * or a custom route of the dev's choice using the authGuardRedirect data property
 *
 * protected-routing.module.ts
 *   {
 *     path: 'protected',
 *     data: { authGuardRedirect: '/disallowed' },
 *     canActivate: [AuthGuardService],
 *     component: ProtectedComponent
 *   }
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthAuthorizations, AuthService, AuthUser } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { ServiceLocator } from '../shared/service-locator';

let user: AuthUser;
let auth: AuthAuthorizations = {};
let disableProfileSwitch = false;

const routerPromise = ServiceLocator.get(Router);

const checkAuth = (name) => {
  return auth[name];
};

StateService.user$.subscribe(async (authUser) => {
  user = authUser;
  // navigate to homepage if changing profiles and page disallows
  if (!user && disableProfileSwitch) {
    const router = await routerPromise;
    router.navigateByUrl('/');
  }
});

StateService.authorizations$.subscribe((authorizations) => {
  auth = authorizations;
});

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    // disallow focused mode on homepage
    if (StateService.isFocusedMode && url === '/') {
      // don't close for clients
      if (user?.isClient) return false;
      try {
        // only works if opened using window.open()
        window.close();
      } catch (ex) {}
      return false;
    }

    // switch app into focused mode
    // e.g. https://server.com/?focusedMode=1
    const focusedMode = state.root.queryParamMap.get('focusedMode');
    if (focusedMode) {
      StateService.isFocusedMode = true;
    }

    // allow session length to be reduced via querystring
    // e.g. https://server.com/?maxSessionTime=120 // 2 minute session length
    const maxSessionLength = state.root.queryParamMap.get('maxSessionLength');
    if (maxSessionLength && !isNaN(Number(maxSessionLength))) {
      AuthService.maxSessionLength = parseInt(maxSessionLength, 10);
    }
    // get deepest child
    let child = route;
    while (child.firstChild) {
      child = child.firstChild;
    }
    disableProfileSwitch = child.data.disableProfileSwitch || false;
    // check if authenticated and save requested route
    if (AuthService.isAuthenticated(url)) {
      // check authorizations
      const reqAuth = child.data.authorizations;
      return (
        !reqAuth || // no authorizations required
        reqAuth.some(checkAuth) || // authorization found
        this.router.parseUrl('errors/401')
      ); // unauthorized
    }
    // not authenticated
    const redirectPath = child.data['authGuardRedirect'] || '/auth/login';
    return this.router.parseUrl(redirectPath);
  }
}
