/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { AuthUser } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../../services/config.service';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { trackByRoute } from '../../utils/track-by';
// icons
import icRadioButtonChecked from '@iconify/icons-ic/baseline-push-pin';
import icRadioButtonUnchecked from '@iconify/icons-ic/outline-push-pin';

@UntilDestroy()
@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map((config) => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map((config) => config.sidenav.showCollapsePin)); // Promise.resolve(true);
  gtMd$ = this.layoutService.gtMd$;
  brand = StateService.brand;
  user: AuthUser;

  version = environment.version;
  items = this.navigationService.items;

  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef
  ) {
    this.navigationService.items$.pipe(untilDestroyed(this)).subscribe((items) => {
      this.items = items;
      this.cd.markForCheck();
    });
  }

  ngOnInit() {
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.brand = StateService.brand;
      this.cd.markForCheck();
    });
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    if (this.collapsed) {
      this.layoutService.expandSidenav(true);
    } else {
      this.layoutService.collapseSidenav(true);
    }
  }
}
