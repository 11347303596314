<!-- @format -->

<div class="rounded overflow-hidden bg-white drop-shadow-md max-w-sm w-screen" *transloco="let t">
  <div class="flex justify-between items-center bg-primary shadow text-primary-contrast py-4 px-6">
    <div>
      <div class="title">{{ t('announcements.title') }}</div>
    </div>
  </div>

  <div class="dropdown-content">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let announcement of announcements; trackBy: trackById" class="flex-auto shadow-none" (afterExpand)="markRead(announcement.SystemMessageId)">
        <mat-expansion-panel-header>
          <div class="flex-auto flex items-center gap-3 mr-3 truncate">
            <div class="flex-auto flex flex-col truncate">
              <div class="body-1 leading-snug truncate">{{ announcement.Title }}</div>
              <div class="caption text-secondary">{{ t('date.shortDate', { date: announcement.startDate }) }}</div>
            </div>
            <ic-icon *ngIf="!isRead(announcement)" [icon]="icNew" size="16" class="text-orange"></ic-icon>
          </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="whitespace-pre-wrap">{{ announcement.Content }}</div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
