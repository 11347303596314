/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, first, firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';
import { AuthAuthorizations, AuthProfile, AuthUser } from './auth.service';
import { OrgAnnouncement } from './org/org-announcements.service';
import { StorageService } from './storage.service';

/**
 * Authentication Decorators and Validation
 */
// store ref to auth lookup
const authExcludeMap = new WeakMap<object, string[]>();
const authRequireMap = new WeakMap<object, string[]>();
// property decorators
export function AuthExclude(...authorizations: string[]): PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    authExcludeMap.set(target[propertyKey], authorizations);
  };
}
export function AuthRequire(...authorizations: string[]): PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    authRequireMap.set(target[propertyKey], authorizations);
  };
}
// validate authorization against registered refs
export function authAllow(ref: any): boolean {
  const claimsExclude = authExcludeMap.get(ref);
  const claimsRequire = authRequireMap.get(ref);
  const includes = (item) => (item.startsWith('!') ? !StateService.auth[item.substring(1)] : StateService.auth[item]);
  return !(claimsExclude && claimsExclude.some(includes)) && (!claimsRequire || claimsRequire.some(includes));
}

const focusedClass = 'is-focused-mode';
const focusedProp = 'focused';
const sharedProp = 'shared';
const transferProp = 'transfer';
const eventHubProp = 'eventHub';

// return true when loading count drop to 0
const isLoaded = (loadingCount, index) => {
  if (index && !loadingCount) {
    return true;
  }
};
// return true when window is visible
const getVisibility = () => {
  return document.visibilityState === 'visible';
};

export type AppBrand = 'CMS' | 'CFA' | 'OL' | 'APRN';

const getBrand = (user: AuthUser): AppBrand => {
  const hostBrand = location.hostname === 'cms.myhelplink.org' ? 'CMS' : 'CFA';
  // init brand
  if (!user) return StateService.brand || hostBrand;
  if (user.isClient) return 'CFA';
  if (user.isAPRN) return 'APRN';
  if (user.isOL) return 'OL';
  return 'CMS';
};

const storage = new StorageService('global');

let broadcastChannel: BroadcastChannel;

// load last state of disableEventHub
let _disableEventHub = storage.getSessionData<boolean>(eventHubProp) === false;

let parentDeviceId;
try {
  parentDeviceId = JSON.parse(window.name).deviceId;
} catch (ignore) {}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  // ensure we have a unique deviceId
  // are we working for parent, else saved, else new
  static readonly deviceId = parentDeviceId || storage.getLocalData('deviceId') || v4();

  /**
   * Authentication Transferred from CMS?
   */
  static get isAuthTransfer() {
    return storage.getSessionData<boolean>(transferProp) === true;
  }
  static set isAuthTransfer(value) {
    // save mode for session
    storage.setSessionData(transferProp, value);
    // disable local storage use if auth transfer
    StorageService.disableLocal = value;
  }
  /**
   * device shared with others
   */
  static get isSharedDevice() {
    return storage.getSessionData<boolean>(sharedProp) === true;
  }
  static set isSharedDevice(value) {
    // save mode for session
    storage.setSessionData(sharedProp, value);
  }
  /**
   * use focused mode?
   */
  static get isFocusedMode() {
    return storage.getSessionData<boolean>(focusedProp) === true;
  }
  static set isFocusedMode(value) {
    if (value) {
      document.body.classList.add(focusedClass);
    } else {
      document.body.classList.remove(focusedClass);
    }
    // save mode for session
    storage.setSessionData(focusedProp, value);
  }
  /**
   * app installed
   */
  static get isInstalled() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
  /**
   * send messages to other browser tabs
   */
  static readonly postBroadcastMessage = (message: any) => {
    if (!broadcastChannel) return;
    broadcastChannel.postMessage(message);
  };
  /**
   * flag indicating event hub should connect
   * remember if page refresh
   */
  static get disableEventHub() {
    return _disableEventHub;
  }
  static set disableEventHub(value) {
    _disableEventHub = value;
    // remember if page refresh
    storage.setSessionData(eventHubProp, !value);
  }
  /**
   * current org announcements
   */
  static readonly announcements$ = new BehaviorSubject<OrgAnnouncement[]>(null);
  /**
   * flag indicating this device and location has authorized access
   */
  static readonly authorized$ = new BehaviorSubject<boolean>(false);
  /**
   * flag indicating access control has been granted
   */
  static readonly accessGranted$ = new BehaviorSubject<boolean>(false);
  /**
   * the current authenticated user
   */
  static readonly user$ = new BehaviorSubject<AuthUser>(null);
  static user: AuthUser;
  /**
   * the authorizations for the current user
   */
  static readonly authorizations$ = new BehaviorSubject<AuthAuthorizations>({});
  static auth: AuthAuthorizations;
  static brand: AppBrand;
  /**
   * an array of available profiles for the current user
   */
  static readonly profiles$ = new BehaviorSubject<AuthProfile[]>(null);
  /**
   * the number of dependencies currently being loaded
   */
  static readonly loadingCount$ = new BehaviorSubject<number>(0);
  /**
   * resolves when the initial asset loading completes (language files)
   */
  static readonly initialLoad$ = firstValueFrom(StateService.loadingCount$.pipe(first(isLoaded)));
  /**
   * the online state of the device
   */
  static readonly online$ = new BehaviorSubject<boolean>(window.navigator.onLine);
  /**
   * new app version is ready to be applied
   */
  static readonly updateReady$ = new BehaviorSubject<boolean>(false);
  static updateReady: boolean;
  /**
   * triggered when app is closing
   */
  static readonly closing$ = new Subject<boolean>();
  /**
   * adds string to the end of the current page title
   */
  static readonly titleAppend$ = new BehaviorSubject<string>(null);
  /**
   * the visibility state of the window
   */
  static readonly visible$ = new BehaviorSubject<boolean>(getVisibility());
  /**
   * combination of events that would indicate user activity in the application
   */
  static readonly keepalive$ = new Subject<{
    httpRequest?: string;
    isLocked?: boolean;
    isOnline?: boolean;
    isVisible?: boolean;
    navUrl?: string;
    sendMessage?: boolean;
  }>();
  /**
   * messages that could be sent by any cfa browser tab
   */
  static readonly broadcastMessages$ = new Subject<MessageEvent>();
}

try {
  broadcastChannel = new BroadcastChannel('cfa');
  broadcastChannel.onmessage = (msg) => {
    StateService.broadcastMessages$.next(msg);
  };
  StateService.postBroadcastMessage('connected');
} catch (ex) {
  // multi-tab communication not supported
}

// track online state
window.addEventListener('online', () => StateService.online$.next(true));
window.addEventListener('offline', () => StateService.online$.next(false));
// track closing
window.addEventListener('beforeunload', () => StateService.closing$.next(true));
// track window visibility
document.addEventListener('visibilitychange', () => {
  StateService.visible$.next(getVisibility());
});
// forward events to keepalive stream
StateService.visible$.subscribe((isVisible) => {
  StateService.keepalive$.next({ isVisible });
});
StateService.online$.subscribe((isOnline) => {
  StateService.keepalive$.next({ isOnline });
});
// track user/auth
StateService.user$.subscribe((user) => {
  StateService.user = user;
  StateService.brand = getBrand(user);
});
StateService.authorizations$.subscribe((auth) => {
  StateService.auth = auth;
});
// track app update status
StateService.updateReady$.subscribe((updateReady) => {
  StateService.updateReady = updateReady;
});
// maintain focused mode for life of session
StateService.isFocusedMode = storage.getSessionData<boolean>(focusedProp) || false;

// in case of page refresh
// disable local storage use if auth transfer
StorageService.disableLocal = StateService.isAuthTransfer;

// save device id for next time
if (!parentDeviceId) storage.setLocalData('deviceId', StateService.deviceId);

// debug logs
// StateService.user$.subscribe(console.info.bind(console, 'StateService.user$:'));
// StateService.authorizations$.subscribe(console.info.bind(console, 'StateService.authorizations$:'));
// StateService.profiles$.subscribe(console.info.bind(console, 'StateService.profiles$:'));
// StateService.loadingCount$.subscribe(console.log.bind(console, 'StateService.loadingCount$:'));
// StateService.initialLoad$.then(console.log.bind(console, 'StateService.initialLoad$'))
