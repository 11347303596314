/** @format */

import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { StateService } from '../services/state.service';
import { StorageService } from '../services/storage.service';

// paths where page refresh is allowed for app update
const allowedPaths = {
  '/': true, // home
  '/auth/login': true, // login/logout
};

export const updateResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  // check for manual update trigger
  // session storage / global: {"update":true}
  const storage = new StorageService('global');
  const avail: boolean = storage.getSessionData('update') || false;
  if (avail) {
    // announce update
    StateService.updateReady$.next(avail);
    // clear update flag
    storage.removeSessionData('update');
  }

  // get update status and target url
  const updateReady = StateService.updateReady;
  const targetUrl = state.url.toString();
  if (updateReady && allowedPaths[targetUrl]) {
    // update ready and allowed path, reload the app
    window.location.assign(targetUrl);
  }
  return updateReady;
};
