/** @format */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceLocator } from 'src/app/shared/service-locator';

// get required instance
let snackPromise = ServiceLocator.get(MatSnackBar);
let translocoPromise = ServiceLocator.get(TranslocoService);

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // test http response for offline/unavailable state
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          Promise.all([snackPromise, translocoPromise]).then(([snackBar, translocoService]) => {
            snackBar.open(
              navigator.onLine
                ? translocoService.translate('connection.unavailable')
                : translocoService.translate('connection.offline'),
              '',
              {
                duration: 3000,
                panelClass: 'warn',
                politeness: 'assertive',
              }
            );
          });
        }
        return throwError(() => error);
      })
    );
  }
}
