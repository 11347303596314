/** @format */

import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { AuthAuthorizations, AuthProfile, AuthUser } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { ProfileService } from 'src/app/services/profile.service';
import { StateService } from 'src/app/services/state.service';
// icons
import icStaff from '@iconify/icons-mdi/badge-account-horizontal';
import icClient from '@iconify/icons-mdi/person';

@UntilDestroy()
@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserComponent implements OnInit {
  icClient = icClient;
  icStaff = icStaff;

  dropdownOpen: boolean;
  user: AuthUser;
  auth: AuthAuthorizations;
  profiles: AuthProfile[];
  photoURL: string;
  connected: boolean;

  private dropdownRef: PopoverRef;

  constructor(private popover: PopoverService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.dropdownRef?.close();
      if (!user) {
        return;
      }
      this.user = user;
      this.cd.markForCheck();
    });
    StateService.profiles$.pipe(untilDestroyed(this)).subscribe((profiles) => {
      this.profiles = profiles;
    });
    StateService.authorizations$.pipe(untilDestroyed(this)).subscribe((auth) => {
      this.auth = auth;
    });
    ProfileService.photo$.pipe(untilDestroyed(this)).subscribe((url) => {
      this.photoURL = url;
      this.cd.markForCheck();
    });
    EventService.connected$.pipe(untilDestroyed(this)).subscribe((connected) => {
      this.connected = connected;
      this.cd.markForCheck();
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    this.dropdownRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
      data: {
        user: this.user,
        profiles: this.profiles,
        auth: this.auth,
      },
    });

    this.dropdownRef.afterClosed$.subscribe(() => {
      this.dropdownRef = null;
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
