/** @format */

import { StorageService } from '../services/storage.service';
import { QueryString } from './query-string';

let filter: string;
let position = 0;
let positionClass = ['tl', 'tr', 'br', 'bl'];
const data = {};

const getPositionClass = () => {
  return `debug-${positionClass[position % 4]}`;
};
const move = (event) => {
  if (event.target !== document.body) {
    return;
  }
  document.body.classList.remove(getPositionClass());
  position++;
  document.body.classList.add(getPositionClass());
};

export class DebugState {
  static set filter(value: string) {
    filter = value;
    const body = document.body;
    const storage = new StorageService('debug');
    if (value) {
      body.classList.add('debug');
      body.classList.add(getPositionClass());
      body.addEventListener('click', move);
      // add weinre via ngrok.io
      const domain = atob('bmdyb2suaW8=');
      const subdomain = prompt(`Forwarding Subdomain for ${domain}`, storage.getLocalData('weinre') as string);
      if (!subdomain) return;
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', `https://${subdomain}.${domain}/target/target-script-min.js#anonymous`);
      body.appendChild(scriptTag);
      storage.setLocalData('weinre', subdomain);
    } else {
      body.classList.remove('debug');
      body.classList.remove(getPositionClass());
      body.removeEventListener('click', move);
    }
  }

  static set(path: string, value: unknown) {
    if (filter && (filter === '*' || path.startsWith(filter))) {
      data['update'] = { path, date: new Date().toLocaleString() };
      path.split('.').reduce((parent, prop, index, array) => {
        parent[prop] = index + 1 === array.length ? value : parent[prop] || {};
        return parent[prop];
      }, data);
      document.body.setAttribute('data-debug', JSON.stringify(data, null, 2));
    }
  }
}

DebugState.filter = QueryString.get('debug-state');
