/** @format */

import { HttpClient, HttpContext } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, defer, firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { ServiceLocator } from './service-locator';

export type ObservableProvider = {
  _active?: number; // internally tracks if the observable has a listener
  _match?: string | number | boolean; // internal flag used to determine if new data should be requested
  bs: BehaviorSubject<any>; // the source BehaviorSubject
  match: () => string | number | boolean; // if true, then data will only be requested once, and never deleted/changed
  url: () => string | ((http: HttpClient) => Observable<any>); // the api url to request the data from or a function that will place the request
  urlPost?: () => string; // the api url to post data to
  urlPut?: (id: unknown) => string; // the api url to put data to
  normalize?: (data: any) => any; // optional function for manipulating response data before emitted
  context?: HttpContext;
};
export type ObservableManagerAPI<T> = {
  dec: () => void;
  inc: () => void;
  observable?: Observable<T>;
  refresh: () => void;
  subject?: BehaviorSubject<T>;
};

const noop = () => {};
const noopNum = (n: number) => {};
const defaultNormalize = (data) => data;

// get required instances
let httpPromise = ServiceLocator.get(HttpClient);
// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);

// common data requester method
const requestData = async (provider: ObservableProvider): Promise<void> => {
  const match = provider.match();
  if (!match || provider._match === match || !provider._active) return;
  provider._match = match;
  const http = await httpPromise;
  const url = provider.url();
  const result$ = typeof url === 'string' ? http.get(url, { context: provider.context || context }) : url(http);
  const normalize = provider.normalize || defaultNormalize;
  try {
    const data = await firstValueFrom(result$);
    // check if match changed while we were waiting
    if (provider._match !== match) return;
    provider.bs.next(normalize(data));
  } catch (ex) {
    console.warn(ex);
    delete provider._match;
    // todo: find better way to deal with errors
    provider.bs.next(null);
    // using stream.error requires all observables to handle errors
    // if not, stream is unable to recover
    // provider.bs.error(ex);
  }
};

// manager allows rest api to auto update observable data as necessary
export class ObservableManager {
  private static readonly managers: Record<string, ObservableManagerAPI<any>> = {};
  private static readonly defaultManager: ObservableManagerAPI<any> = {
    dec: () => null,
    inc: () => null,
    refresh: () => null,
  };

  // refresh data when possible
  // delay refresh momentarily to ensure another update is not requested
  private static requestRefresh(active: { cnt: number; id: any }, refresh: () => any) {
    clearTimeout(active.id);
    active.id = setTimeout(() => {
      if (!active.cnt) refresh();
    }, 0);
  }

  // find all impacted managers
  // e.g. updates to profiles/1234/cases/5678
  // impact both profiles/1234 and profiles/1234/cases/5678
  private static getManagers(namespace: string) {
    // find all active managers in hierarchy
    const parts = namespace.split('/');
    // check profiles, profiles/1234, profiles/1234/cases, profiles/1234/cases/5678
    return parts.map((part, index) => this.managers[parts.slice(0, index + 1).join('/')]).filter((a) => a);
  }

  // get api for a given namespace
  // support hierarchical structures
  static get<T>(namespace: string): ObservableManagerAPI<T> {
    if (!namespace) return this.defaultManager;
    const exactManager = this.managers[namespace] || this.defaultManager;
    // wrap all managers in api
    return {
      dec: () => this.getManagers(namespace).forEach((manager) => manager.dec()),
      inc: () => this.getManagers(namespace).forEach((manager) => manager.inc()),
      refresh: () => this.getManagers(namespace).forEach((manager) => manager.refresh()),
      // observable of requested manager
      observable: exactManager.observable,
      subject: exactManager.subject,
    };
  }

  // get observable for a given namespace
  // create if not already existing
  static init<T>(namespace: string, refresh: () => any, cleanup: () => any = () => {}): Observable<T> {
    if (!namespace || !refresh) return null;
    // check if we already have observable cache for client
    if (namespace in this.managers) return this.managers[namespace].observable;
    // initialize internals
    const active = { cnt: 0, id: null };
    const subject = new BehaviorSubject<T>(null);
    const observable = subject.pipe(
      ObservableUtils.detectActivity(
        // request initial data when subscribers
        refresh,
        () => {
          // cleanup when no subscribers
          cleanup();
          delete this.managers[namespace];
          // console.log('ObservableManagers', Object.keys(this.managers));
        }
      )
    );
    this.managers[namespace] = {
      dec: () => active.cnt--,
      inc: () => active.cnt++,
      observable,
      refresh: () => this.requestRefresh(active, refresh),
      subject,
    };
    // console.log('ObservableManagers', Object.keys(this.managers));
    return observable;
  }

  // tracks state to control value tracking
  // e.g.
  //   track org locations for the current user
  //   if the user changes, then track the org locations for the new user
  // only caches if there is an active subscriber
  static initTrack<T, B>(
    state: BehaviorSubject<B>,
    namespace: (value: B) => string,
    refresh: (value: B) => any,
    cleanup: (value: B) => any = () => {}
  ): Observable<T> {
    const subject = new BehaviorSubject<T>(null);
    let subState: Subscription;
    let subValues: Subscription;
    const observable = subject.pipe(
      ObservableUtils.detectActivity(
        () => {
          // track state changes when subscribers
          subState = state.subscribe((value: B) => {
            subValues?.unsubscribe();
            if (!value) return subject.next(null);
            // map value changes to output
            subValues = this.init<T>(
              namespace(value),
              () => refresh(value),
              () => cleanup(value)
            ).subscribe((value) => {
              subject.next(value);
            });
          });
        },
        () => {
          // cleanup when no subscribers
          subValues.unsubscribe();
          subState.unsubscribe();
        }
      )
    );
    return observable;
  }
}

export class ObservableUtils {
  // detect when subscribers added/removed
  static detectActivity(onActive = noop, onInactive = noop, onChange = noopNum) {
    return <T>(source$: Observable<T>) => {
      let counter = 0;
      return defer(() => {
        // 0 --> 1
        if (!counter++) onActive();
        onChange(counter);
        return source$;
      }).pipe(
        finalize(() => {
          // 1 --> 0
          onChange(counter);
          if (!--counter) onInactive();
        })
      );
    };
  }

  // accepts an ObservableProvider
  // returns an observable that is monitored for listeners
  // it will request data from the provided url
  // it will cache results as long as the match function returns the same value
  static getObservable<T>(provider: ObservableProvider) {
    return provider.bs.pipe<T>(
      ObservableUtils.detectActivity(
        () => {
          provider._active = (provider._active || 0) + 1;
          requestData(provider);
        },
        () => {
          provider._active--;
        }
      )
    );
  }

  // nulls the data for the provided ObservableProvider if not already null
  // retains data that is unchanging (match === true)
  static resetObservable(provider: ObservableProvider): void {
    if (!provider._match || provider._match === true) return;
    delete provider._match;
    provider.bs.next(null);
  }

  // requests data for the provided ObservableProvider
  // if something is listening for the data
  static refreshObservable(provider: ObservableProvider, force?: boolean | unknown): void {
    if (force === true && provider._match && provider._match !== true) {
      // forget last match value to ensure data requested
      delete provider._match;
      // if no one listening, just set to null and be done
      if (!provider._active) return provider.bs.next(null);
    }
    requestData(provider);
  }
}
