/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { ClearButtonModule } from '../components/clear-button/clear-button.module';
import { AutocompleteDirective } from './autocomplete.directive';
import { BaseDirective } from './base.directive';
import { ClearButtonDirective } from './clear-button.directive';
import { DatePickerDirective } from './date-picker.directive';
import { DndDirective } from './drag-n-drop.directive';
import { DraggableDirective } from './draggable.directive';
import { ISDMaskDirective } from './isd-mask.directive';
import { LazyCompDirective } from './lazy-comp.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { PhoneUniqueDirective } from './phone-unique.directive';
import { PinDirective } from './pin.directive';
import { PristineDirective } from './pristine.directive';
import { ScrollerDirective } from './scroller.directive';
import { TrimDirective } from './trim.directive';
import { ZipCodeMaskDirective } from './zip-code-mask.directive';

@NgModule({
  declarations: [
    AutocompleteDirective,
    BaseDirective,
    ClearButtonDirective,
    DatePickerDirective,
    DraggableDirective,
    DndDirective,
    ISDMaskDirective,
    LazyCompDirective,
    PhoneMaskDirective,
    PhoneUniqueDirective,
    PinDirective,
    PristineDirective,
    ScrollerDirective,
    TrimDirective,
    ZipCodeMaskDirective,
  ],
  imports: [CommonModule, MatNativeDateModule, ClearButtonModule],
  exports: [
    AutocompleteDirective,
    ClearButtonDirective,
    DatePickerDirective,
    DraggableDirective,
    DndDirective,
    ISDMaskDirective,
    LazyCompDirective,
    PhoneMaskDirective,
    PhoneUniqueDirective,
    PinDirective,
    PristineDirective,
    ScrollerDirective,
    TrimDirective,
    ZipCodeMaskDirective,
  ],
})
export class CommonDirectivesModule {}
