/** @format */

import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  HashMap,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  Translation,
  TranslocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoModule,
  TranslocoService,
  getBrowserLang,
  translocoConfig,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import {
  Duration,
  addDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format,
  formatDistanceToNow,
  formatDuration,
  formatRelative,
  intervalToDuration,
  isValid,
  parse,
  startOfISOWeek,
} from 'date-fns';
import { tap } from 'rxjs/operators';
import { AuthUser } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { StateService } from 'src/app/services/state.service';
import { StorageService } from 'src/app/services/storage.service';
import { ServiceLocator } from 'src/app/shared/service-locator';
import { environment } from 'src/environments/environment';
import { PhoneUtil } from './phone-util';

const failedRetries = 2;

// languages for content files
const availableLangs = [
  { id: 'en', label: 'English' },
  // { id: 'de', label: 'Deutsch' },
  { id: 'es', label: 'Español' },
  // { id: 'fr', label: 'Français' },
  // { id: 'it', label: 'Italiano' },
];

// angular system locales
import localeEs from '@angular/common/locales/es';
// import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeEs);
// registerLocaleData(localeFr);

// date-fns locales
import { enUS as en, es /*, de, fr, it*/ } from 'date-fns/locale';
const dateFnsLocales = { en, es /*, de, fr, it*/ };

const translocoPromise = ServiceLocator.get(TranslocoService);

const iatProp = 'iat';
const langProp = 'lang';

// -------------------
// LANG TRACKING
// -------------------
let currentLang = 'en';
let currentUser: AuthUser;
let isQuietUpdate = false;
const storage = new StorageService();
const updatedLang = storage.getSessionData<string>(langProp);
const browserLang = getBrowserLang();
translocoPromise.then((translocoService) => {
  if (translocoService.isLang(updatedLang)) {
    // if page refresh
    translocoService.setActiveLang(updatedLang);
  } else if (translocoService.isLang(browserLang)) {
    // if new window
    translocoService.setActiveLang(browserLang);
  }
  translocoService.langChanges$.subscribe((lang) => {
    if (currentLang === lang) return;
    currentLang = lang;
    // track lang pref for this session
    storage.setSessionData(langProp, currentLang);
    if (isQuietUpdate || !currentUser) return;
    // send lang pref change to server
    EventService.send('LanguageUpdate', currentLang);
  });
});

// -------------------
// USER TRACKING
// -------------------
StateService.user$.subscribe(async (user) => {
  currentUser = user;
  const iat = storage.getSessionData<number>(iatProp);
  if (!user || user.iat === iat) return;
  // save the jwt 'issued at time'
  // only process jwt lang once
  storage.setSessionData(iatProp, user.iat);
  if (user.Lang) {
    // update ui to match saved lang pref
    const translocoService = await translocoPromise;
    isQuietUpdate = true;
    translocoService.setActiveLang(user.Lang);
    isQuietUpdate = false;
  } else {
    // send current lang pref to server
    EventService.send('LanguageUpdate', currentLang);
  }
});

// -------------------
// EVENT HUB INTEGRATION
// -------------------

EventService.on('languageUpdate', async (lang: string) => {
  translocoPromise.then((translocoService) => {
    // if server telling us new lang, then update quietly
    // if server asking us, then set to currentLang
    isQuietUpdate = !!lang;
    translocoService.setActiveLang(lang || currentLang);
    isQuietUpdate = false;
  });
});

// -------------------
// DATE FORMATTERS
// -------------------
export class DateFNSUtils {
  static format(date: number | Date, formatString: string): string {
    if (!isValid(date)) return;
    return format(date, formatString, { locale: dateFnsLocales[currentLang] });
  }
  // static formatDistanceToNow(date: number | Date): string {
  //   return formatDistanceToNow(date, { includeSeconds: true, locale: dateFnsLocales[currentLang] });
  // }
  static formatRelative(date: number | Date, baseDate: number | Date): string {
    if (!isValid(date) || !isValid(baseDate)) return;
    return formatRelative(date, baseDate, { locale: dateFnsLocales[currentLang] });
  }
  static formatDuration(duration: number | Duration): string {
    if (typeof duration === 'number') {
      duration = {
        days: Math.floor(duration / 86400),
        hours: Math.floor(duration / 3600) % 24,
        minutes: Math.floor(duration / 60) % 60,
        seconds: duration % 60,
      };
    }
    return formatDuration(duration, { locale: dateFnsLocales[currentLang] });
  }
}

// -------------------
// LOADING TRACKER
// -------------------
let cnt = 0;
const handlerError = (store) => {
  return () => {
    if (failedRetries === store.cnt++) {
      handleComplete();
    }
  };
};
const handleComplete = () => {
  if (cnt === 0) return;

  // setTimeout lets the fallback requests kick in before marking this complete
  setTimeout(() => {
    StateService.loadingCount$.next(--cnt);
  }, 0);
};

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  // support outstanding download tracking via StateService.loadingCount
  getTranslation(lang: string) {
    // console.log('getTranslation:', lang);
    const store = { cnt: 0 };
    StateService.loadingCount$.next(++cnt);
    return this.http
      .get<Translation>(`/assets/i18n/${lang}.json`)
      .pipe(tap({ error: handlerError(store), complete: handleComplete }));
  }
}

@Injectable({ providedIn: 'root' })
export class TranslocoNullMissingHandler implements TranslocoMissingHandler {
  // allow request to provide a fallback result if the key is missing
  handle(key: string, config: TranslocoConfig, params: HashMap = {}) {
    const hasFallback = 'fallback' in params;
    if (!hasFallback && config.missingHandler.logMissingKey && !config.prodMode) {
      const msg = `Missing translation for '${key}'`;
      console.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    }
    return hasFallback ? params.fallback : key;
  }
}

// -------------------
// TRANSLOCO MODULE
// -------------------
@NgModule({
  imports: [
    TranslocoMessageFormatModule.forRoot({
      customFormatters: {
        // converts a date string from one format to another (pipe delimited in|out format strings)
        // e.g. { "title": "{exampleDate, formatDateString, MM/dd/yyyy hh:mm:ss aa|PPPP}" }
        formatDateString: (dtm: string, lang: string, formats: string) => {
          const [inFormat, outFormat] = formats.split('|');
          return DateFNSUtils.format(parse(dtm, inFormat, new Date()), outFormat);
        },
        // formats a date
        // e.g. { "title": "{exampleDate, formatDate, PPPP}" }
        formatDate: (dtm: Date, lang: string, outFormat: string) => {
          return DateFNSUtils.format(dtm, outFormat);
        },
        // formats a date
        // e.g. { "date.weekday": "{day, formatWeekday}" }
        formatWeekday: (day: number, lang: string) => {
          return DateFNSUtils.format(addDays(startOfISOWeek(new Date()), day), 'EEEE');
        },
        // formats a phone
        // e.g. { "phone": "{phone, formatPhone, 1}" }
        formatPhone: (phone: string, lang: string, isd: string) => PhoneUtil.format(phone, isd),
        // returns singular or plural version based on count
        // e.g. { "title": "{{count}} {count, formatPlural, item|items}" }
        formatPlural: (count: number = 1, lang: string, variations: string) => {
          const [singular, plural] = variations.split('|');
          return count === 1 ? singular : plural;
        },
        // returns name in possessive format
        // e.g. { "title": "{name, formatPossessive} rules" }
        formatPossessive: (name: string, lang: string) => {
          return name.toLocaleLowerCase().endsWith('s') ? `${name}'` : `${name}'s`;
        },
        // formats a duration
        // e.g. { "title": "{exampleDate, formatDistanceToNow}" }
        formatDistanceToNow: (dtm: number | Date, lang: string) => {
          // return DateFNSUtils.formatDistanceToNow(date);
          return formatDistanceToNow(dtm, { includeSeconds: true, locale: dateFnsLocales[currentLang] });
        },
        // formats a number
        // e.g. { "number": "{number, formatNumber}" }
        formatNumber: (number: number, lang: string) => {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
          return new Intl.NumberFormat(dateFnsLocales[currentLang]).format(number);
        },
        // returns a relative date
        formatRelative: (dtm: number | Date, lang: string) => {
          const days = differenceInCalendarDays(Date.now(), dtm);
          if (!days) {
            return formatDistanceToNow(dtm, { locale: dateFnsLocales[currentLang] });
          }
          if (days < 7) {
            return DateFNSUtils.format(dtm, 'E');
          }
          if (differenceInCalendarMonths(Date.now(), dtm) < 12) {
            return DateFNSUtils.format(dtm, 'MMM d');
          }
          return DateFNSUtils.format(dtm, 'P').replace(/\d{4}/, (a) => a.slice(2));
        },
        // returns a relative date
        formatRelativeWeek: (dtm: number | Date, lang: string) => {
          if (differenceInCalendarDays(Date.now(), dtm) < 7) {
            return formatRelative(dtm, Date.now(), { locale: dateFnsLocales[currentLang] });
          }
          return DateFNSUtils.format(dtm, 'PP');
        },
        // formats a duration
        // e.g. { "title": "{seconds, formatDuration}" }
        formatDuration: (duration: number | Duration, lang: string) => {
          return DateFNSUtils.formatDuration(duration);
        },
        // formats a birthDate for age
        // e.g. { "title": "{birthDate, formatAge}" }
        formatAge: (birthDate: Date, lang: string) => {
          const { years, months, days } = intervalToDuration({ start: birthDate, end: new Date() });
          return formatDuration(
            years >= 10 ? { years } : years >= 1 ? { years, months } : months >= 1 ? { months, days } : { days },
            {
              zero: false,
              locale: dateFnsLocales[currentLang],
            }
          );
        },
        // formats a duration in ms as age
        // e.g. { "title": "{ms, formatAgeDuration}" }
        formatAgeDuration: (duration: { start: Date; end: Date }, lang: string) => {
          const { years, months, days } = intervalToDuration(duration);
          return formatDuration(
            years >= 10 ? { years } : years >= 1 ? { years, months } : months >= 1 ? { months, days } : { days },
            {
              zero: false,
              locale: dateFnsLocales[currentLang],
            }
          );
        },
        // formats a number
        // e.g. { "title": "Card total: {total, formatInt}" }
        formatInt: (num: number | string, lang: string) => {
          const rxClean = /\D/g;
          switch (typeof num) {
            case 'number':
              return num.toLocaleString(lang);
            case 'string':
              return parseInt(num.replace(rxClean, '')).toLocaleString(lang);
            default:
              return '--';
          }
        },
        // array at
        // e.g. { "title": "{arr, arrayAt, 0}" }
        arrayAt: (array: any[], lang: string, index: string) => {
          const i = parseInt(index);
          return array[i];
        },
      },
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs,
        failedRetries,
        defaultLang: 'en',
        // if a content file is missing, then the language will switch to this
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true,
          // It will use the first language set in the `fallbackLang` property
          // useFallbackTranslation: true
        },
        // flatten: {
        //   aot: environment.production
        // }
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: TranslocoNullMissingHandler },
  ],
})
export class TranslocoRootModule {}
