/** @format */

import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { VexModule } from 'src/@vex/vex.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { ApiInterceptor } from './interceptors/api-interceptor';
import { MockInterceptor } from './interceptors/mock.interceptor';
import { OfflineInterceptor } from './interceptors/offline.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { LangResolver } from './resolvers/lang-resolver';
import { TranslocoRootModule } from './shared/transloco-root.module';
// import * as Emoji from 'quill-emoji';

// const e = Emoji;
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@NgModule({
  declarations: [AppComponent, SnackbarComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      // serverLogLevel: NgxLoggerLevel.ERROR
    }),
    MatSnackBarModule,
    NgHcaptchaModule.forRoot({
      siteKey: environment.apiKeys.hCaptcha,
    }),
    // QuillConfigModule.forRoot({
    //   theme: 'snow',
    //   formats: ['emoji'],
    //   modules: {
    //     toolbar: false,
    //     'emoji-textarea': true,
    //     'emoji-shortname': true,
    //   },
    // }),

    // Vex
    VexModule,
    CustomLayoutModule,
    TranslocoRootModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // error in dialog if not imported here :/
    MatChipsModule,
  ],
  providers: [
    Title,
    LangResolver,
    // global storage namespace
    { provide: 'STORAGE_NAMESPACE', useValue: 'global' },
    // http interceptors
    { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    { provide: MAT_DATEPICKER_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
