/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AccessService, AccessState } from '../services/access.service';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root',
})
export class AccessResolver implements Resolve<AccessState> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<AccessState> {
    // send keepalive event
    StateService.keepalive$.next({ navUrl: state.url });
    // ensure access allowed
    return AccessService.resolve(state.url);
  }
}
