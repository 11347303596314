/** @format */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, UrlSerializer } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApiInterceptor } from '../interceptors/api-interceptor';
import { InterceptorContext } from '../interceptors/interceptor-context';
import { MockInterceptor } from '../interceptors/mock.interceptor';
import { ObservableManager } from './observable-utils';
import { ServiceLocator } from './service-locator';

// get required instances
const httpPromise = ServiceLocator.get(HttpClient);
const routerPromise = ServiceLocator.get(Router);
const urlSerializerPromise = ServiceLocator.get(UrlSerializer);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);
const contextMock = InterceptorContext.get([{ interceptor: MockInterceptor, config: { enable: true } }]);

export interface RestUrlMap {
  url: any;
  urlDelete?: any;
  urlGet?: any;
  urlList?: any;
  urlPost?: any;
  urlPut?: any;
}

export interface RestOptions<T, B = T> {
  normalize?: (data: T) => B;
  normalizePost?: (data: T) => B;
  normalizePut?: (data: T) => B;
  params?: HttpParams | Record<string, any>;
  manager?: string;
}

interface RestGetOverload {
  <T, B = T>(urlMap: RestUrlMap, urlArgs: any[], options: RestOptions<T, B>): Promise<B>;
  <T, B = T>(urlMap: RestUrlMap, urlArgs: any[], normalize: (data: T) => B): Promise<B>;
  <T, B = T>(urlMap: RestUrlMap, urlArgs: any[], id: unknown, options: RestOptions<T, B>): Promise<B>;
  <T, B = T>(urlMap: RestUrlMap, urlArgs: any[], id: unknown, normalize: (data: T) => B): Promise<B>;
}

const get: RestGetOverload = async <T, B = T>(
  urlMap: RestUrlMap,
  urlArgs: any[],
  overload1: any = {},
  overload2: any = undefined
): Promise<B> => {
  // get options dynamically based on passed parameters
  const {
    id = null,
    normalize = (data) => data,
    params = {},
    manager = null,
  } = typeof overload2 === 'function'
    ? { id: overload1, normalize: overload2 } // id, normalize
    : overload2
    ? { id: overload1, ...overload2 } // id, options
    : typeof overload1 === 'function'
    ? { normalize: overload1 } // normalize
    : overload1; // options
  const mgr = ObservableManager.get<B>(manager);
  const http = await httpPromise;
  // LIST url method
  const urlList = urlMap.urlList || urlMap.url;
  // GET url may be same as base url + /itemId
  const urlGet = urlMap.urlGet || ((...args) => `${urlMap.url(...args)}/${args.pop()}`);
  // generate url
  const url = id ? urlGet(...urlArgs, id) : urlList(...urlArgs);
  // place request
  const response = http.get<T>(url, { context, params: { response_format: 'json', ...params } });
  try {
    // wait for result
    const json = await firstValueFrom<T>(response);
    // normalize result
    const normalized = normalize(json) as B;
    // if manager subject, then emit next
    mgr.subject?.next(normalized);
    // return normalized result
    return normalized;
  } catch (ex) {
    // if manager subject, then emit error
    mgr.subject?.error(ex);
    // throw error
    throw ex;
  }
};

const getUrl = async (
  urlMap: RestUrlMap,
  urlArgs: any[],
  overload1: any = {},
  overload2: any = undefined
): Promise<string> => {
  const router = await routerPromise;
  const urlSerializer = await urlSerializerPromise;
  // get options dynamically based on passed parameters
  const {
    id = null,
    normalize = (data) => data,
    params = {},
    manager = null,
  } = typeof overload2 === 'function'
    ? { id: overload1, normalize: overload2 } // id, normalize
    : overload2
    ? { id: overload1, ...overload2 } // id, options
    : typeof overload1 === 'function'
    ? { normalize: overload1 } // normalize
    : overload1; // options
  // LIST url method
  const urlList = urlMap.urlList || urlMap.url;
  // GET url may be same as base url + /itemId
  const urlGet = urlMap.urlGet || ((...args) => `${urlMap.url(...args)}/${args.pop()}`);
  // generate url
  const url = id ? urlGet(...urlArgs, id) : urlList(...urlArgs);
  const tree = router.createUrlTree([url], { queryParams: params });
  return urlSerializer.serialize(tree);
};

const putPost = async <T, B = T>(
  urlMap: RestUrlMap,
  urlArgs: any[],
  data: any,
  id: unknown = null,
  options: RestOptions<T, B> = {}
) => {
  // get options
  const { normalize = (data) => data, normalizePut, normalizePost, params, manager } = options;
  const mgr = ObservableManager.get<B>(manager);
  // mark update in progress
  mgr.inc();
  try {
    const http = await httpPromise;
    // POST url may be same as base url
    const urlPost = urlMap.urlPost || urlMap.url;
    // PUT url may be same as base url + /id
    const urlPut = urlMap.urlPut || ((...args) => `${urlMap.url(...args)}/${args.pop()}`);
    // generate url
    const url = id ? urlPut(...urlArgs, id) : urlPost(...urlArgs);
    // calc http method
    const httpMethod = id ? http.put : http.post;
    // place request
    const response = httpMethod.call(http, url, data, { context, responseType: 'json', params });
    // wait for result
    const json = await firstValueFrom<T>(response);
    // calc normalization method
    const normMethod = (id ? normalizePut : normalizePost) || normalize;
    // normalize result
    const normalized = normMethod(json) as B;
    // request manager data refresh
    mgr.refresh();
    // return normalized result
    return normalized;
  } catch (ex) {
    throw ex;
  } finally {
    // mark update complete
    mgr.dec();
  }
};

const del = async <T, B = T>(urlMap: RestUrlMap, urlArgs: any[], id: unknown, options: RestOptions<T, B> = {}) => {
  // get options
  const { normalize = (data) => data, params, manager } = options;
  const mgr = ObservableManager.get<B>(manager);
  // mark update in progress
  mgr.inc();
  try {
    const http = await httpPromise;
    // DELETE url may be same as base url + /id
    const urlDelete = urlMap.urlDelete || ((...args) => `${urlMap.url(...args)}/${args.pop()}`);
    // generate url
    const url = urlDelete(...urlArgs, id);
    // place request
    const response = http.delete<T>(url, { context, responseType: 'json', params });
    // wait for result
    const json = await firstValueFrom<T>(response);
    // normalize result
    const normalized = normalize(json) as B;
    // request manager data refresh
    mgr.refresh();
    // return normalized result
    return normalized;
  } catch (ex) {
    throw ex;
  } finally {
    // mark update complete
    mgr.dec();
  }
};

export class Rest {
  static readonly get = get;
  static readonly putPost = putPost;
  static readonly delete = del;
  // helpers
  static readonly getUrl = getUrl;
}
