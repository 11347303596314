/** @format */

import { ComponentType } from '@angular/cdk/overlay';
import {
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

export type LazyIOMap = Record<string, any>;
type SubscriptionMap = Record<string, Subscription>;

@Directive({
  selector: '[vexLazyComp]',
})
export class LazyCompDirective implements OnInit, OnDestroy {
  // the lazy component to load
  // example: vexLazyComp="client/client-account"
  // this will load the following component
  // src/app/components/lazy/client/client-account/client-account.component.ts
  @Input() set vexLazyComp(value: string) {
    this.componentPath = value;
    this.loadComponent();
  }
  // the inputs to pass to the requested lazy component
  // example: [inputs]="{inputName1: value, inputName2: value, ...}"
  @Input() set inputs(value: LazyIOMap) {
    const newValue = value || {};
    const oldValue = this.componentInputs || {};
    // identify new/changed inputs
    Object.keys(newValue)
      .filter((key) => oldValue[key] !== newValue[key])
      .forEach((key) => (this.componentInputChanges[key] = true));
    // identify removed inputs
    Object.keys(oldValue)
      .filter((key) => !(key in newValue))
      .forEach((key) => (this.componentInputChanges[key] = true));
    // save current input state
    this.componentInputs = value;
    // apply to component
    this.updateInputs();
  }
  // number of ms to delay loading the component
  // useful if animations are impacted by component rendering
  @Input() loadDelay = 0;

  // outputs events based on the requested lazy component's outputs
  // example: (outputs)="outputHandler($event)"
  // $event would be something like {outputName: outputValue}
  @Output() outputs = new EventEmitter<LazyIOMap>();

  private lazyModule: any;
  private componentInputs: LazyIOMap;
  private componentInputChanges: Record<string, boolean> = {};
  private componentPath: string;
  private componentName: string;
  private componentRef: ComponentRef<any>;
  private componentSubs: SubscriptionMap;

  private isDestroyed: boolean;
  private isReady: boolean;

  // load component code
  private async loadComponent() {
    const fileName = this.componentPath.split('/').pop();
    this.lazyModule = await import(`../components/lazy/${this.componentPath}/${fileName}.component`);
    this.createComponent();
  }

  // render component
  private async createComponent() {
    if (this.componentRef || !this.isReady || !this.lazyModule || this.isDestroyed) return;
    // grab first component export in module
    const [name, component] = Object.entries(this.lazyModule).find(([key, value]) => key.endsWith('Component'));
    // render component
    this.componentRef = this.viewContainerRef.createComponent<any>(component as ComponentType<any>, {});
    this.componentName = name;
    // wire inputs/outputs
    this.linkOutputs();
    this.updateInputs();
    // cleanup
    this.lazyModule = null;
    // announce ready
    this.outputs.emit({ $ready: name });
  }

  // Update component inputs
  // this does not remove/clear previously set inputs
  private async updateInputs() {
    if (!this.componentRef) return;
    Object.keys(this.componentInputChanges).forEach(
      (key) => (this.componentRef.instance[key] = this.componentInputs[key])
    );
    this.componentInputChanges = {};
    this.componentRef.changeDetectorRef.markForCheck();
  }

  // find and link component outputs (EventEmitters)
  private async linkOutputs() {
    if (!this.componentRef) return;
    this.componentSubs = {};
    Object.entries(this.componentRef.instance)
      .filter(([key, value]) => value instanceof EventEmitter)
      .forEach(([key, emitter]) => {
        this.componentSubs[key] = (emitter as EventEmitter<any>).subscribe((value) =>
          this.outputs.emit({ [key]: value })
        );
      });
  }

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    // mark ready for component create
    setTimeout(() => {
      this.isReady = true;
      this.loadComponent();
    }, this.loadDelay);
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
    Object.values(this.componentSubs || {}).forEach((value) => value.unsubscribe());
    this.componentSubs = this.componentInputs = this.componentRef = null;
    this.viewContainerRef.clear();
    // announce destroy
    this.outputs.emit({ $destroy: this.componentName });
  }
}
