/** @format */

import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _quickpanelOpenSubject = new BehaviorSubject<boolean>(false);
  quickpanelOpen$ = this._quickpanelOpenSubject.asObservable();

  private _sidenavOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavOpen$ = this._sidenavOpenSubject.asObservable();

  private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();

  private _sidenavCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsedOpen$ = this._sidenavCollapsedOpenSubject.asObservable();

  private _configpanelOpenSubject = new BehaviorSubject<boolean>(false);
  configpanelOpen$ = this._configpanelOpenSubject.asObservable();

  private _searchOpen = new BehaviorSubject<boolean>(false);
  searchOpen$ = this._searchOpen.asObservable();

  orientation$ = this.breakpointObserver
    .observe(['(orientation: portrait)', '(orientation: landscape)'])
    .pipe(map((state) => (state.breakpoints['(orientation: landscape)'] ? 'landscape' : 'portrait')));

  private _gtXl = this.breakpointObserver.observe(`(min-width: 1536px)`).pipe(map((state) => state.matches));
  private _gtLg = this.breakpointObserver.observe(`(min-width: 1280px)`).pipe(map((state) => state.matches));
  private _gtMd = this.breakpointObserver.observe(`(min-width: 960px)`).pipe(map((state) => state.matches));
  private _gtSm = this.breakpointObserver.observe(`(min-width: 600px)`).pipe(map((state) => state.matches));

  // take collapsed menu into account for XL and LG
  gtXl$ = combineLatest([this._gtXl, this._gtLg, this.sidenavCollapsed$]).pipe(
    map(([primary, secondary, collapsed]) => primary || (secondary && collapsed)),
    distinctUntilChanged((a, b) => a === b)
  );
  gtLg$ = combineLatest([this._gtLg, this._gtMd, this.sidenavCollapsed$]).pipe(
    map(([primary, secondary, collapsed]) => primary || (secondary && collapsed)),
    distinctUntilChanged((a, b) => a === b)
  );
  gtMd$ = this._gtMd;
  gtSm$ = this._gtSm;

  ltXl$ = this.gtXl$.pipe(map((state) => !state));
  ltLg$ = this.gtLg$.pipe(map((state) => !state));
  ltMd$ = this.gtMd$.pipe(map((state) => !state));
  ltSm$ = this.gtSm$.pipe(map((state) => !state));

  isDesktop$ = this.gtLg$; // side nav open
  isMobile$ = this.ltSm$;

  private storage = new StorageService('layout');

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
    /**
     * Expand Sidenav when we switch to mobile view
     * Revert to last state when we switch back to desktop view
     */
    this.gtMd$.subscribe((state) => {
      if (state && this.storage.getLocalData('sidenavCollapsed')) {
        this.collapseSidenav();
      } else {
        this.expandSidenav();
      }
    });
  }

  openQuickpanel() {
    this._quickpanelOpenSubject.next(true);
  }

  closeQuickpanel() {
    this._quickpanelOpenSubject.next(false);
  }

  openLeftDrawer() {
    this._sidenavOpenSubject.next(true);
  }

  closeSidenav() {
    this._sidenavOpenSubject.next(false);
  }

  collapseSidenav(save?: boolean) {
    // enable collapse
    this._sidenavCollapsedSubject.next(true);
    if (!save) return;
    // collapse sidenav and prevent open for 400ms
    this.collapseCloseSidenav(true);
    this.storage.setLocalData('sidenavCollapsed', true);
  }

  expandSidenav(save?: boolean) {
    this._sidenavCollapsedSubject.next(false);
    if (!save) return;
    this.storage.setLocalData('sidenavCollapsed', false);
  }

  private collapseOpenDisabled: boolean;
  collapseOpenSidenav() {
    if (this.collapseOpenDisabled) return;
    this._sidenavCollapsedOpenSubject.next(true);
  }

  collapseCloseSidenav(force?: boolean) {
    this._sidenavCollapsedOpenSubject.next(false);
    if (!force) return;
    this.collapseOpenDisabled = true;
    setTimeout(() => {
      this.collapseOpenDisabled = false;
    }, 400);
  }

  openConfigpanel() {
    this._configpanelOpenSubject.next(true);
  }

  closeConfigpanel() {
    this._configpanelOpenSubject.next(false);
  }

  openSearch() {
    this._searchOpen.next(true);
  }

  closeSearch() {
    this._searchOpen.next(false);
  }

  enableRTL() {
    this.router.navigate([], {
      queryParams: {
        rtl: 'true',
      },
    });
  }

  disableRTL() {
    this.router.navigate([], {
      queryParams: {
        rtl: 'false',
      },
    });
  }
}
