<ng-template #sidenavRef>
  <vex-sidenav *ngIf="!isFocusedMode" [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar *ngIf="!isFocusedMode" [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="(isDesktop$ | async) === false" class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer border-t"></vex-footer>
</ng-template>

<!-- <ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template> -->

<vex-layout
  [footerRef]="footerRef"
  quickpanelRef="quickpanelRef"
  [sidenavRef]="sidenavRef"
  [toolbarRef]="toolbarRef"
></vex-layout>

<!-- <vex-config-panel-toggle
  (openConfig)="configpanel.open()"
></vex-config-panel-toggle> -->

<!-- CONFIGPANEL -->
<!-- <vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-config-panel></vex-config-panel>
</vex-sidebar> -->
<!-- END CONFIGPANEL -->
