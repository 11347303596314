/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@visurel/iconify-angular';
import { LangMenuComponent } from './lang-menu.component';

@NgModule({
  declarations: [LangMenuComponent],
  imports: [CommonModule, IconModule, MatButtonModule, MatMenuModule, TranslocoModule],
  exports: [LangMenuComponent],
})
export class LangMenuModule {}
