<!-- @format -->

<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed" [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center select-none">
    <!-- ICONS -->
    <img *ngIf="brand === 'APRN'" src="/assets/img/logo/aprn-icon.svg" class="h-10 flex-none" alt="logo" />
    <img *ngIf="brand === 'CFA'" [src]="imageUrl$ | async" class="h-8 flex-none" alt="logo" />
    <img *ngIf="brand === 'CMS' && collapsed && (collapsedOpen$ | async) === false" src="/assets/img/logo/nl-icon@600.png" class="w-8 ml-1 flex-none" alt="logo" />
    <img *ngIf="brand === 'OL'" src="/assets/img/logo/ol-icon.svg" class="h-10 flex-none" alt="logo" />
    <!-- ORG NAME -->
    <h2 class="title flex-auto truncate lg:overflow-visible">
      <img *ngIf="brand === 'APRN'" src="/assets/img/logo/aprn-text.svg" class="h-10 ml-1" alt="logo" />
      <img *ngIf="brand === 'CFA'" src="/assets/img/logo/myhelplink-text.svg" class="h-12 ml-1" alt="logo" />
      <img *ngIf="brand === 'CMS'" src="/assets/img/logo/nl@661.png" class="h-12" [class.hidden]="collapsed && (collapsedOpen$ | async) === false" alt="logo" />
      <img *ngIf="brand === 'OL'" src="/assets/img/logo/ol-text.svg" class="h-10" alt="logo" />
    </h2>
    <!-- PIN -->
    <button (click)="toggleCollapse()" *ngIf="(showCollapsePin$ | async) && (gtMd$ | async)" class="text-secondary pin w-8 h-8 mr-2 leading-none flex-none" mat-icon-button type="button">
      <mat-icon [icIcon]="collapsed ? icRadioButtonUnchecked : icRadioButtonChecked" size="14px"></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
  <div class="text-secondary opacity-50 text-xxs pl-2 w-full text-end truncate">{{ version }}</div>
</div>
