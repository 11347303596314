<!-- @format -->

<div class="toolbar flex items-center content-start w-full px-gutter gap-2" vexContainer>
  <button (click)="openLeftDrawer()" [class.hidden]="!mobileQuery" mat-icon-button type="button" aria-label="open side navigation" [matBadge]="hasBadge ? '!' : ''" matBadgeColor="warn" class="-ml-1">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery" class="flex flex-none items-center content-start px-gutter">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-auto truncate">
    <div *ngIf="user?.isCMS || user?.isPhysician || user?.isClient" class="text-lg sm:text-2xl font-medium truncate sm:block" [class.hidden]="canClientSearch">{{ user?.Organization }}</div>
  </span>

  <div class="flex items-center -mr-1" *transloco="let t">
    <div *ngIf="canClientSearch" class="mr-1 sm:mr-3"><ng-template vexLazyComp="person-search" [inputs]="{ toolbar: true }"></ng-template></div>
    <vex-toolbar-announcements *ngIf="hasAnnouncements"></vex-toolbar-announcements>
    <vex-lang-menu></vex-lang-menu>
    <vex-toolbar-user></vex-toolbar-user>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [class.hidden]="mobileQuery"></vex-navigation>
