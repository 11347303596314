/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Subject } from 'rxjs';
import { Badge } from 'src/app/shared/badge';
import { sideNavItems } from 'src/app/side-nav-items';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading
} from '../interfaces/navigation-item.interface';

export enum BadgeColor {
  WARN,
  PRIMARY,
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  items: NavigationItem[] = [];

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  private _badgeCountSubject = new BehaviorSubject<number>(0);
  badgeCount$ = this._badgeCountSubject.asObservable().pipe(distinctUntilChanged((a, b) => a === b));
  badgeChange$ = this.badgeCount$.pipe(
    map((cnt) => cnt > 0),
    distinctUntilChanged((a, b) => a === b)
  );

  private _navItemsChangeSubject = new Subject<NavigationItem[]>();
  items$ = this._navItemsChangeSubject.asObservable();

  // find and update all effected items
  private applyBadge(items: NavigationItem[], label: string, value: number): number {
    const result = items.reduce((sum, item) => {
      // iterate though children but no badge here
      if (this.isSubheading(item)) {
        return sum + this.applyBadge(item.children, label, value);
      }
      // iterate though children add badge accordingly
      if (this.isDropdown(item)) {
        const childValue = this.applyBadge(item.children, label, value);
        item.badge.value = childValue || 0;
        return sum + childValue;
      }
      // item found
      if (this.isLink(item) && item.label === label) {
        item.badge = value
          ? {
              value,
              bgClass: 'bg-warn',
              textClass: 'text-warn-contrast',
            }
          : null;
        return sum + value;
      }
      // item not found
      return sum + (item?.badge?.value || 0);
    }, 0);
    return result;
  }

  constructor() {
    this.badgeCount$.subscribe((count) => {
      // set os app icon badge
      Badge.set(count);
    });
  }

  updateBadge(label: string, value: number) {
    const count = this.applyBadge(sideNavItems, label, value);
    this._badgeCountSubject.next(count);
  }

  updateItems(items: NavigationItem[]) {
    this.items = items;
    this._navItemsChangeSubject.next(items);
    // force recalculation of badges
    this.updateBadge(null, 0);
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link' && !item.label.startsWith('_');
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }
}
