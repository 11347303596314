/** @format */

import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { StringUtil } from '../shared/string-util';

export type PersonRoleType = 'CLIENT' | 'STAFF' | 'MEDICAL' | 'PHYSICIAN' | 'PROVIDER' | 'PROVIDER_EXTERNAL';

export interface PersonBase {
  FirstName: string; // "Ben"
  fullName?: string; // "Ben White"
  LastName: string; // "White"
  PersonId: string; // "7f36842e-c6e9-4106-b6bf-08d727111ef3"
  // mapped
  isOrg: boolean;
  isPlaceholder: boolean;
}

export interface PersonRecord extends PersonBase {
  AdditionalContactPhone: string;
  AdditionalEmail: string;
  AdditionalPhoneTypeId: number;
  AddressId: number;
  AttendedChurch: string;
  CFATermsAndConditionsChecked: boolean;
  Comments: string;
  ContactPhone: string;
  Email: string;
  FileId: number; // 101;
  GenderTypeId: number;
  IsClientFacingApplicationEnabled: boolean;
  LanguageTypeId: number;
  LegacyId: string;
  PhoneTypeId: number;
  PrefLanguageTypeId: number;
  SalutationTypeId: number;
  SpouseName: string;
  // unused
  AA: number;
  AAA: number;
  ZZ: number;
  ZZZ: number;
}

export interface PersonSearchBase {
  DisplayName: string; // "Appt Test"
  DisplayText?: string; // "Appt Test - 05/06/2001"
  // mapped
  q?: string;
}

export interface PersonSearchClient extends PersonSearchBase {
  ClientId: string; // "ecfd5203-3e3b-45e2-203f-08d727111ef5"
  DateOfBirth: string; // "05/06/2001"
  OrganizationId: string; // "f8472681-6908-4127-adf9-fb967b886ced"
  PersonId: string; //  "7f36842e-c6e9-4106-b6bf-08d727111ef3"
  PriorVisitDate: string; // ""
  // mapped
  dob?: Date;
}

export interface PersonSearchAPRNClient extends PersonSearchBase, PersonBase {
  APRClientId?: string; // "ecfd5203-3e3b-45e2-203f-08d727111ef5"
  CaseOpenDate?: string; // "10/29/2019"
  // mapped
  caseOpenDate?: Date;
}

export interface PersonSearchStaff extends PersonSearchBase, PersonBase {
  AssignedRole: string; // probably should not display this to others
  Email: string;
  Id: string; // userId
  Title: string;
  // unused
  AllowTextContact: unknown;
  ContextId: string; // ba5d262c-7036-4c33-8297-9751e85bffaf",
  ContextType: string; // Staff/Volunteer",
  EndDate: string; // null,
  MobileIntakeSMSStatus: unknown;
  Status: 'Active' | 'Inactive';
  // mapped
  emailLink?: SafeUrl;
  roleType?: string;
}

export interface PersonSearchAPRNStaff extends PersonSearchBase, PersonBase {
  AdditionalPhone: string; // "(555) 232-3232",
  City: string; // "Dublin",
  Email: string;
  Id: string; // "f8472681-6908-4127-adf9-fb967b886ced"
  PhoneNumber: string; // "(555) 232-3232",
  // mapped
  emailLink?: SafeUrl;
  phoneLink?: SafeUrl;
}

export interface PersonSearchResult extends PersonSearchBase {
  // common properties
  PersonId: string; //  "7f36842e-c6e9-4106-b6bf-08d727111ef3"
  FileId?: number;
  // client properties
  ClientId?: string; // "ecfd5203-3e3b-45e2-203f-08d727111ef5"
  DateOfBirth?: string; // "05/06/2001"
  OrganizationId?: string; // "f8472681-6908-4127-adf9-fb967b886ced"
  PriorVisitDate?: string; // ""
  // all but cms clients
  FirstName?: string;
  LastName?: string;
  // aprn client properties
  APRClientId?: string; // "ecfd5203-3e3b-45e2-203f-08d727111ef5"
  CaseOpenDate?: string; // "10/29/2019"
  // aprn provider/nurse properties
  City?: string; // "Dublin",
  PhoneNumber?: string; // "(555) 232-3232",
  AdditionalPhone?: string; // "(555) 232-3232",
  // staff Properties
  AssignedRole?: string; // probably should not display this to others
  Email?: string; // also used by aprn providers
  Id?: string; // "f8472681-6908-4127-adf9-fb967b886ced" // also used by aprn providers/nurses
  Title?: string;
  // mapped
  caseOpenDate?: Date;
  dob?: Date;
  phoneLink?: SafeUrl;
  emailLink?: SafeUrl;
  roleType?: string;
}

export const normalizePersonBase = <T extends PersonBase>(person: T): T => {
  const isOrg = person.FirstName === 'Org.';
  const fullName = isOrg
    ? StringUtil.toTitleCase(person.LastName)
    : `${StringUtil.toTitleCase(person.FirstName)} ${StringUtil.toTitleCase(person.LastName)}`;
  const isPlaceholder = fullName === 'Client Placeholder';
  return Object.assign(person, {
    fullName,
    isOrg,
    isPlaceholder,
  });
};

export const normalizePersonSearchBase = <T extends PersonSearchBase>(person: T): T => {
  const [FirstName, ...LastName] = person.DisplayName.split(' ');
  return Object.assign(person, {
    FirstName,
    LastName: LastName.join(' ').trim(),
  });
};

export const normalizePersonName = (name: string) => StringUtil.toTitleCase(name?.replace(/^Org\.\W+/, ''));

// generate role map to quickly lookup relationships (id <=> name <=> type)
const roleMap = {
  id2Name: Object.values(environment.role).reduce((result, map) => Object.assign(result, map), {}),
  id2Type: Object.entries(environment.role).reduce(
    (result, [type, map]) => Object.keys(map).reduce((result, role) => Object.assign(result, { [role]: type }), result),
    {}
  ),
  // broken locally if role has different ids per environment
  // name2Id: Object.values(environment.role).reduce(
  //   (result, map) => Object.entries(map).reduce((result, [id, name]) => Object.assign(result, { [name]: id }), result),
  //   {}
  // ),
  name2Type: Object.entries(environment.role).reduce(
    (result, [type, map]) =>
      Object.values(map).reduce((result, role) => Object.assign(result, { [role]: type }), result),
    {}
  ),
};

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor() {}

  // static getRoleIdByName(name: string): string {
  //   return roleMap.name2Id[name];
  // }
  static getRoleNameById(id: string): string {
    return roleMap.id2Name[id];
  }
  static getRoleTypeById(id: string): PersonRoleType {
    return roleMap.id2Type[id] || 'STAFF';
  }
  static getRoleTypeByName(name: string): PersonRoleType {
    return roleMap.name2Type[name] || 'STAFF';
  }
}
