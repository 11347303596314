/** @format */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { trackByProp } from 'src/@vex/utils/track-by';
import { OrgAnnouncement } from 'src/app/services/org/org-announcements.service';
import { StateService } from 'src/app/services/state.service';
import { StorageService } from 'src/app/services/storage.service';
// icons
import icNew from '@iconify/icons-mdi/new-releases';

@UntilDestroy()
@Component({
  selector: 'vex-toolbar-announcements-dropdown',
  templateUrl: './toolbar-announcements-dropdown.component.html',
  styleUrls: ['./toolbar-announcements-dropdown.component.scss'],
})
export class ToolbarAnnouncementsDropdownComponent implements OnInit {
  icNew = icNew;
  trackById = trackByProp('SystemMessageId');

  private storage = new StorageService('announcements');
  private personId: string;
  viewCache: number[];
  announcements: OrgAnnouncement[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.personId = user.PersonId;
      this.viewCache = (this.storage.getLocalData(this.personId) || []) as number[];
      this.cd.markForCheck();
    });
    StateService.announcements$.pipe(untilDestroyed(this)).subscribe((announcements) => {
      this.announcements = announcements;
      this.cd.markForCheck();
    });
  }

  isRead(announcement: OrgAnnouncement) {
    return this.viewCache?.includes(announcement.SystemMessageId);
  }

  markRead(msgId: number) {
    if (!this.viewCache || this.viewCache.includes(msgId)) return;
    this.viewCache.push(msgId);
    this.storage.setLocalData(this.personId, this.viewCache);
    // not ideal, but triggers recalculation of viewed state
    StateService.announcements$.next(this.announcements);
  }
}
