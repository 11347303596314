/** @format */

import { HttpClient } from '@angular/common/http';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServiceLocator } from '../shared/service-locator';
import { SnackService } from './snack.service';

let httpPromise = ServiceLocator.get(HttpClient);

let warnSnack: MatSnackBarRef<any> = null;

// display timeout warning
const versionWarn = () => {
  SnackService.info('version.warn', 'version.update', -1).then((snack) => {
    warnSnack = snack;
    snack.onAction().subscribe(() => {
      warnSnack = null;
      location.reload();
    });
  });
};

export class VersionService {
  constructor() {}

  static async check() {
    const http = await httpPromise;
    const result$ = http.get(`version.txt`, { params: { ms: Date.now() }, responseType: 'text' });
    try {
      const data = (await firstValueFrom(result$)) as string;
      if (environment.version !== data) {
        versionWarn();
      }
    } catch (ex) {
      console.warn(ex);
    }
  }
}
