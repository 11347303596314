/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { AuthAuthorizations, AuthProfile, AuthService, AuthUser } from 'src/app/services/auth.service';
import { LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { ProfileService } from 'src/app/services/profile.service';
import { MenuItem } from '../interfaces/menu-item.interface';
// icons
import icProfile from '@iconify/icons-ic/baseline-account-circle';
import icChevronRight from '@iconify/icons-ic/baseline-chevron-right';
import icAbout from '@iconify/icons-mdi/account-group';
import icStaff from '@iconify/icons-mdi/badge-account-horizontal';
import icClient from '@iconify/icons-mdi/person';
import { Style, StyleService } from 'src/@vex/services/style.service';

@UntilDestroy()
@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
  user: AuthUser;
  auth: AuthAuthorizations;
  profiles: AuthProfile[];
  items: MenuItem[];
  photoURL: string;

  icChevronRight = icChevronRight;
  icClient = icClient;
  icStaff = icStaff;
  icProfile = icProfile;

  private style: Style;

  get isHighContrast() {
    return this.style === Style.contrast;
  }
  set isHighContrast(value: boolean) {
    this.styleService.setStyle(value ? Style.contrast : Style.default);
  }

  constructor(
    private dialog: MatDialog,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private translocoService: TranslocoService,
    private styleService: StyleService,
    private cd: ChangeDetectorRef
  ) {
    Object.assign(this, popoverRef.data);
    this.items = [
      {
        icon: icProfile,
        label: this.translocoService.translate('menu.profile'),
        description: this.translocoService.translate('user.profile'),
        // colorClass: 'text-primary',
        route: '/profile',
        authorizations: ['ClientFacingApplication.Update'],
      },
      {
        icon: icProfile,
        label: this.translocoService.translate('menu.profile'),
        description: this.translocoService.translate('user.profile'),
        // colorClass: 'text-primary',
        route: `/staff/view/${this.user.PersonId}`,
        authorizations: ['User.isAPRN', 'User.isCMS', 'User.isPhysician', 'OptionLine.All'],
      },
      {
        icon: icAbout,
        label: this.user.Organization,
        description: this.translocoService.translate('menu.about'),
        // colorClass: 'text-primary',
        route: '/about',
        authorizations: ['ClientFacingApplication.Update'],
      },
    ];
  }

  ngOnInit() {
    // const role = this.user.isClient ? 'client' : 'staff';
    this.items = this.items.filter((item) => {
      return !item.authorizations?.length || item.authorizations.some((auth) => this.auth[auth]);
    });
    ProfileService.photo$.pipe(untilDestroyed(this)).subscribe((url) => {
      this.photoURL = url;
      this.cd.markForCheck();
    });
    StyleService.style$.pipe(untilDestroyed(this)).subscribe((style) => {
      this.style = style;
      this.cd.markForCheck();
    });
  }

  close() {
    this.popoverRef.close();
  }

  selectPerson(personId: string) {
    AuthService.returnPath = '/';
    AuthService.selectProfileAsync(personId).then((result) => {
      if (result.status !== 'SUCCESS') {
        AuthService.logout();
      }
    });
  }

  async change() {
    this.close();
    const dialogConfig = new MatDialogConfig();
    Object.assign(dialogConfig, {
      autoFocus: true,
      closeOnNavigation: true,
      disableClose: true,
      data: { profiles: this.profiles, currentId: this.user.PersonId },
    });

    const dialogRef = await LazyDialogService.open('auth-profile-list', dialogConfig);

    dialogRef.afterClosed().subscribe(this.selectPerson);
  }

  lock() {
    this.close();
    AuthService.lock();
  }

  logout() {
    this.close();
    AuthService.logout();
  }
}
