/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StateService } from 'src/app/services/state.service';

import icLanguage from '@iconify/icons-ic/language';

@UntilDestroy()
@Component({
  selector: 'vex-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangMenuComponent implements OnInit {
  icLanguage = icLanguage;

  @Input() optional: boolean;

  enabled: boolean;

  langs: AvailableLangs;
  currentLang: string;

  constructor(private cd: ChangeDetectorRef, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.enabled = !this.optional || StateService.isFocusedMode;
    this.langs = this.translocoService.getAvailableLangs();
    this.currentLang = this.translocoService.getActiveLang();
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang) => {
      this.currentLang = lang;
    });
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.enabled = !this.optional || (StateService.isFocusedMode && user?.isClient);
      this.cd.markForCheck();
    });
  }

  setLang(lang: string) {
    this.translocoService.setActiveLang(lang);
  }
}
