/** @format */

import { StringUtil } from './string-util';

export class TimeUtil {
  static to12hr(time24: string) {
    const [, hr = '0', , min = '00'] = (time24 || '').toUpperCase().match(/^(\d{1,2})(:(\d{2}))?$/) || [];
    const hrs = parseInt(hr);
    return time24 && `${hrs % 12 || 12}:${min}${hrs < 12 ? 'am' : 'pm'}`;
  }
  static to24hr(time12: string) {
    const offsetMap = { AM: 0, PM: 12 };
    // invalid patterns will be treated as midnight 0:00am
    const [, hr = '0', , min = '00', , sec = '00', offset = 'AM'] =
      (time12 || '').toUpperCase().match(/^(\d{1,2})(:(\d{2}))?(:(\d{2}))?\s?(AM|PM)?$/) || [];
    return time12 && `${StringUtil.padToLength((parseInt(hr) % 12) + offsetMap[offset])}:${min}`;
  }
}
