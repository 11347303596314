/**
 * Force any authentication information to be erased if this route is triggered
 *
 * @format
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

const keepAuthUrlMap = {
  '/auth/lock': 1,
};

@Injectable({
  providedIn: 'root',
})
export class AuthResetService implements CanActivate {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!keepAuthUrlMap[state.url]) AuthService.resetAuth();
    return true;
  }
}
