/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DebugState } from 'src/app/shared/debug-state';
import { AppModule } from './app/app.module';
import { ServiceLocator } from './app/shared/service-locator';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// fix ios height bug :/
let cnt = 0;
let lastSize = 0;
let resizeTimeout: number;
const appHeight = () => {
  const height = window.innerHeight;
  if (lastSize !== height) {
    cnt = 0;
    lastSize = height;
    document.body.scrollTop = 0;
    document.documentElement.style.setProperty('--app-height', `${height}px`);
    DebugState.set('window.height', lastSize.toString());
  }
  if (cnt++ < 10) {
    clearTimeout(resizeTimeout);
    resizeTimeout = window.setTimeout(appHeight, 500);
  }
};
window.addEventListener('resize', appHeight);

appHeight();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(
    (app) => {
      // mark app running
      window['hbi'] = true;
      // provides and instantiated app injector for static methods
      ServiceLocator.setInjector(app.injector);
    },
    (err) => console.error(err)
  );
