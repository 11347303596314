/** @format */

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { addDays, differenceInHours, format, parseISO } from 'date-fns';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { ObservableProvider, ObservableUtils } from 'src/app/shared/observable-utils';
import { ServiceLocator } from 'src/app/shared/service-locator';
import { ArrayUtil } from '../shared/array-util';
import { Rest } from '../shared/rest';
import { StringUtil } from '../shared/string-util';
import { AuthUser } from './auth.service';
import { EventService } from './event.service';
import { OrgService } from './org.service';
import { PersonRoleType } from './person.service';
import { SnackService } from './snack.service';
import { StateService } from './state.service';

const urlCollection = {
  clientUnread: {
    url: () =>
      `Organizations/${user.OrganizationId}/Communication/${user.ClientId}/GetNewMessageCount/${user.PersonId}`,
  },
  messages: { url: (roomId: string) => `Organizations/${user.OrganizationId}/Communication/History/${roomId}` },
};

export type ChatChannel = 'CFA' | 'SMS' | 'EMAIL';
export type RoomType = 'CLIENT' | 'PRIVATE' | 'GROUP' | 'APRNCLIENT' | 'APRNPROVIDER';
export type MemberState = 'ADDING' | 'REMOVING' | 'ACTIVE';
export type AvailReason =
  | 'ACTIVITY' // you created recent activity in this room
  | 'ASSIGNED' // you are currently assigned to the client case or room
  | 'OPENCASE' // client has an open case
  | 'RECENT' // recent activity in this room
  | 'UNREPLIED'; // staff has not replied to the last client message

// convenience properties
// const channelId = ChatToChannelId['SMS'];
export const ChatToChannelId: Record<ChatChannel, number> = { EMAIL: 1, SMS: 2, CFA: 3 };
export const TypeToChannelId: Record<RoomType, number> = {
  CLIENT: null,
  PRIVATE: 3,
  GROUP: 3,
  APRNCLIENT: 2,
  APRNPROVIDER: 2,
};
// const channelName = ChatToChannelName[2];
export const ChatToChannelName: ChatChannel[] = [undefined, 'EMAIL', 'SMS', 'CFA'];
export const ChatRoomNameUnknown = '● ● ●';

export interface RoomMember {
  profileId: string; // A10D4DAC-D64F-4041-D734-08DA31A888B1
  name: string; // Jane Doe
  title: string; // Center Advocate
  state?: MemberState;
}

export interface RoomAvail {
  Date: string; // "2022-09-21T13:43:26.0908481"
  Id: string; // "A10D4DAC-D64F-4041-D734-08DA31A888B1"
  Name: string; // "test 009"
  Type: RoomType;
  Role: PersonRoleType; // role type of person (private room only)
  Unread: number; // unread messages
  Organization: string; // room organization
  Reason: AvailReason[];
}

interface RoomInitConfig {
  channelId?: number; // EMAIL:1, SMS:2, CFA:3, etc...
  lastUpdated?: Date; // last message date or room create date
  name?: string; // name of the room
  organization?: string; // organization to display
  reason?: AvailReason[];
  type?: RoomType; // type of room
  role?: PersonRoleType; // role type of person (private room only)
  unreadCount?: number; // number of unread messages/errors
}

interface RoomInternalAPI extends RoomInitConfig {
  id: string; // the personId of the client being communicated with
  active: boolean; // the user is actively looking at the chat window
  messages: ChatEvent[]; // the messages loaded so far, sorted
  messageCache: { [key: string]: ChatEvent }; // the messages loaded so far, accessible via ID
  enableNotifications: boolean; // should send to global notification
  isLoading: boolean; // historic messages are being requested
  isTyping: boolean; // the user is actively typing a message
  members: Record<string, RoomMember>; // members in the room
  name: string; // members in the room
  fileId?: number;
  oldestMs: number; // the epoc time in ms of the oldest message in cache
  pin: boolean; // room is pinned to the room list
  reason: AvailReason[];
  typingUsers: string[]; // list of currently typing users
  update: () => void; // convenience method for alerting message updates to subscribers
  // source subjects for downstream event listeners
  isLoading$: BehaviorSubject<boolean>; // messages being loaded
  lastUpdated$: BehaviorSubject<Date>; // array of messages
  messages$: BehaviorSubject<ChatEvent[]>; // array of messages
  members$: BehaviorSubject<Record<string, RoomMember>>; // array of messages
  name$: BehaviorSubject<string>; // array of messages
  reason$: BehaviorSubject<string[]>; // array of typing users
  typingUsers$: BehaviorSubject<string[]>; // array of typing users
  unreadCount$: BehaviorSubject<number>; // number of unread messages/errors
}

interface RoomCreateConfig {
  profileId?: string; // the personId of the coworker to chat with
  // group chat room properties
  name?: string; // the name of a new group chat room
  roomId?: string; // the id of a group chat room to create/connect to
  members?: string[]; // array of member personIds to include in the group chat
  organization?: string; // room organization name
  organizationId?: string; // room organization id
}
// outgoing update
interface RoomUpdate {
  name?: string; // the name of a new group chat room
  add?: string[]; // array of member personIds to include in the group chat
  remove?: string[]; // array of member personIds to remove from the group chat
}
// incoming update
interface RoomUpdated {
  name?: string; // the name of a new group chat room
  add?: RoomMember[]; // the id of a group chat room to create/connect to
  reason?: AvailReason[];
  remove?: string[];
  unread?: number;
}
interface RoomInfo {
  name: string; // the name of a new group chat room
  type: RoomType; // type of room
  fileId?: number;
  role: PersonRoleType; // role type of person (private room only)
  organization: string; // room organization
  reason: AvailReason[];
  members: RoomMember[]; // the id of a group chat room to create/connect to
}
interface RoomCache {
  id: string;
  internal: RoomInternalAPI;
  external: ChatRoom;
}

export interface ChatEvent {
  ID?: string; // unique record id
  Date?: string; // date sent in iso8601 format
  Name: string; // name of sender
  Message: string; // text message
  SourceId?: string; // personId of sender
  ChannelId: number; // EMAIL:1, SMS:2, CFA:3, etc...
  IsRead: boolean; // did the recipient see the message
  ErrorCode?: string;
  // calculated
  channel?: ChatChannel;
  date?: Date; // date sent
  day?: string; // unique day in local timezone
  id?: string; // required for view caching (id case)
  isError?: boolean; // was error sending message
  isSelf?: boolean; // was the message sent by self
  isClient?: boolean; // was the message sent by self
  isSending?: boolean; // is the message send in progress
  isSaved?: boolean; // does the message exist in the database. In error flow, determines send vs resend path.
}

const pageSize = 20;
const tempIdPrefix = 'unknown-';
// instance that returns "unknown" when converted to string
// this allows us to detect if a property has been changed from its initial value
const unknownInstance = new (function () {
  this.toString = () => 'unknown';
})();
let msgCount = 0;
let user: AuthUser;

// get required instances
const httpPromise = ServiceLocator.get(HttpClient);
const routerPromise = ServiceLocator.get(Router);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);

const unreadCount = new BehaviorSubject<number>(0);
const rooms = new BehaviorSubject<ChatRoom[]>(null);

const roomCache: Record<string, RoomCache> = {};

const actions = { joining: null, leaving: null };

const cleanRoomName = (name: string) => StringUtil.toTitleCase(name?.replace(/^Org\. /, ''));

const findLast = <T>(list: T[], callback: (value: T) => boolean): T => {
  let i = list.length;
  while (i--) {
    if (callback(list[i])) return list[i];
  }
};

const updateGlobalUnreadCount = () => {
  const sum = Object.values(roomCache).reduce((sum, room) => {
    return sum + ((room.internal.enableNotifications && room.internal.unreadCount) || 0);
  }, 0);
  unreadCount.next(sum);
};

const updateRooms = () => {
  rooms.next(
    Object.values(roomCache)
      .map((room) => room.external)
      .sort((a, b) => b.updated.valueOf() - a.updated.valueOf())
  );
};

const hasRoom = (roomId: string) => !!roomCache[roomId.toLowerCase()];

const getRoom = (roomId: string, config: RoomInitConfig = {}, quiet: boolean = false): RoomCache => {
  const id = roomId.toLowerCase();
  let room = roomCache[id];
  if (!room) {
    // create room if not exist
    const internal = {
      api: {} as RoomInternalAPI,
      config: { name: ChatRoomNameUnknown, ...config },
    };
    const external = new ChatRoom(id, internal);
    const cache: RoomCache = { external, id, internal: internal.api };
    roomCache[id] = room = cache;
    // get room details if name is undefined
    quiet || config.name || getRoomInfo(internal.api, true);
    // announce new room
    quiet || updateRooms();
  } else if (config.name && room.external.name !== config.name) {
    // name changed
    room.external.name = cleanRoomName(config.name);
    // announce name change
    quiet || updateRooms();
  }
  return room;
};

const getRoomPublic = (roomId: string, config: RoomInitConfig = {}): ChatRoom => {
  return getRoom(roomId, config).external;
};

// requests the number of unread messages when the user is a client
const getClientUnreadCount = async (): Promise<any> => {
  try {
    const personId = user.PersonId;
    const data = await Rest.get<number>(urlCollection.clientUnread, [], {});
    if (user?.PersonId !== personId) return;
    const room = getRoom(personId, { type: 'CLIENT', name: user.Organization });
    room.internal.unreadCount = data;
    room.internal.unreadCount$.next(data);
  } catch (ex) {
    console.warn(ex);
  }
};

// filter, update
const normalizeMessages = (messages: ChatEvent[], roomId: string) => {
  // TODO: remove selfSource once SourceId available
  return (
    messages
      // .filter((msg) => !user.isClient || msg.Target === 'CFA')
      .map((msg) => {
        const date = new Date(msg.Date);
        return Object.assign(msg, {
          id: msg.ID,
          date,
          day: format(date, 'yyMMdd'),
          isSelf: msg.SourceId === user.PersonId,
          isClient: msg.SourceId === roomId,
          isError: !!msg.ErrorCode,
          Name: user.isClient && msg.SourceId !== user.PersonId ? msg.Name.split(' ')[0] : msg.Name,
          // assume CFA channel if channelId missing
          channel: ChatToChannelName[msg.ChannelId || 3],
          isSaved: !msg.ID.startsWith(tempIdPrefix),
        });
      })
  );
};

const markRead = (api: RoomInternalAPI): boolean => {
  if (!api.active) return;
  const ids = api.messages
    .filter((msg) => {
      if ((msg.isSelf && !msg.isError) || msg.IsRead) return;
      msg.IsRead = true;
      return true;
    })
    .map((message) => message.ID);
  const updated = ids.length > 0;
  if (updated) {
    EventService.send('ChatMessageRead', api.id, ids);
    api.update();
  }
  return updated;
};

const addMessages = (
  api: RoomInternalAPI,
  messages: ChatEvent[],
  prepend: boolean = false,
  tempId: string = null
): number => {
  if (!messages.length) return 0;
  // normalize messages
  const msgs = normalizeMessages(messages, api.id);
  // get before counts
  const messageCountBefore = api.messages.length;
  const errorCountBefore = api.messages.filter((m) => m.isError).length;
  // remove temp id
  if (tempId) {
    delete api.messageCache[tempId];
  }
  // add new messages to cache
  msgs.forEach((m) => {
    api.messageCache[m.id] = m;
  });
  // build message array from cache
  api.messages = Object.values(api.messageCache).sort((a, b) => ArrayUtil.compare(a.date, b.date));
  // get after counts
  const messageCountAfter = api.messages.length;
  const errorCountAfter = api.messages.filter((m) => m.isError).length;
  // calc diff
  const messageCountChange = messageCountAfter - messageCountBefore;
  const errorCountChange = errorCountAfter > errorCountBefore ? errorCountAfter - errorCountBefore : 0;
  // calc diff (new messages, new errors)
  const totalChangesCount = messageCountChange + errorCountChange;
  // track oldest time received
  api.oldestMs = Math.min(api.oldestMs, api.messages[0]?.date.valueOf());
  // update the communication channel based on
  // the channel used by the last message from the client
  // and message within last 12 hours
  if (!messageCountBefore || !prepend) {
    const lastClientMsg = findLast(api.messages, (msg) => msg.isClient);
    if (lastClientMsg?.ChannelId && differenceInHours(Date.now(), lastClientMsg.date) < 12) {
      api.channelId = lastClientMsg.ChannelId;
    }
  }
  // TODO: server should send update to remove room unreplied reason
  const isReplyTracked = ['CLIENT', 'APRNCLIENT', 'APRNPROVIDER'].includes(api.type);
  if (messageCountAfter && isReplyTracked) {
    const isUnreplied = api.messages.slice(-1)[0].SourceId === api.id;
    setUnreplied(api, isUnreplied);
  }
  // send update if messages received, even if they are all filtered
  if (totalChangesCount || messages.length === pageSize) {
    if (!markRead(api)) {
      // if mark read didn't update then sends our own update
      api.update();
    }
  }

  return totalChangesCount;
};

const getMessages = async (api: RoomInternalAPI, more = false) => {
  if (!user || !api.active || api.isLoading) return;
  api.isLoading = true;
  try {
    const messages = await Rest.get<ChatEvent[]>(urlCollection.messages, [api.id], {
      params: {
        count: pageSize,
        ...(more ? { dtm: new Date(api.oldestMs).toISOString() } : undefined),
      },
    });
    addMessages(api, messages, more);
  } catch (ex) {
    SnackService.warn('chat.error.load');
  }
  api.isLoading = false;
};

const sendMessage = async (api: RoomInternalAPI, message: string | number, channelId: number) => {
  // if string, new message, else index of message to resend
  const isNewMessage = typeof message === 'string';
  let msg: ChatEvent;
  let tempId: string;
  if (isNewMessage) {
    tempId = `${tempIdPrefix}${msgCount++}`;
    msg = {
      ID: tempId,
      Date: new Date().toISOString(),
      Name: cleanRoomName(user.FullName),
      Message: message,
      ChannelId: channelId,
      SourceId: user.PersonId,
      IsRead: false,
      isSending: true,
      isSaved: false,
    };
    addMessages(api, [msg]);
  } else {
    msg = api.messages[message];
    tempId = msg.id;
    Object.assign(msg, { isSending: true, isError: false, IsRead: false, ErrorCode: undefined });
    api.update();
  }
  if (!['GROUP', 'PRIVATE'].includes[api.type]) {
    api.pin = true;
  }
  if (EventService.state === signalR.HubConnectionState.Connected) {
    try {
      if (!msg.isSaved) {
        const result = await EventService.invoke<ChatEvent[] | { error: string }>(
          'ChatMessage2',
          api.id,
          msg.Message,
          channelId
        );
        if (Array.isArray(result) && result.length) {
          addMessages(api, result, false, tempId);
        } else {
          Object.assign(msg, {
            isSending: false,
            isError: true,
            ErrorCode: Array.isArray(result) ? result[0]?.ErrorCode ?? 'internal' : result.error,
          });
        }
      } else {
        const result = await EventService.invoke<ChatEvent[] | { error: string }>(
          'ChatResendMessage',
          api.id,
          msg.ID,
          channelId
        );
        if (Array.isArray(result) && result.length) {
          addMessages(api, result);
        } else {
          Object.assign(msg, {
            isSending: false,
            isError: true,
            ErrorCode: Array.isArray(result) ? result[0]?.ErrorCode ?? 'internal' : result.error,
          });
        }
      }
      // remove unreplied reason
      if (['CLIENT', 'APRNCLIENT', 'APRNPROVIDER'].includes(api.type)) {
        setUnreplied(api, false);
      }
      api.lastUpdated = new Date();
      api.update();
      updateRooms();
      return true;
    } catch (ex) {
      console.warn(ex);
    }
  }
  // update message send failure
  Object.assign(msg, { isSending: false, isError: true, ErrorCode: 'disconnected' });
  api.update();
};

const sendTyping = (api: RoomInternalAPI, state: boolean) => {
  if (state === api.isTyping) return;
  api.isTyping = state;
  EventService.send('ChatTyping', api.id, state);
};

const updateRoomName = async (api: RoomInternalAPI, name: string) => {
  // only allow group rooms to be renamed
  if (!name || name === api.name || api.type !== 'GROUP') return;
  try {
    await EventService.invoke('chatRoomUpdate', api.id, { name });
    return true;
  } catch (ex) {
    SnackService.warn('chat.error.name');
  }
  return false;
};

const createRoom = async (config: RoomCreateConfig, initConfig: RoomInitConfig): Promise<ChatRoom> => {
  actions.joining = true;
  try {
    const roomId = await EventService.invoke<string>('ChatRoomCreate', config);
    if (!roomId) throw 'error';
    return getRoomPublic(
      roomId,
      Object.assign(
        {
          name: cleanRoomName(config.name),
          organization: config.organization || user?.Organization,
        },
        initConfig
      )
    );
  } catch (ex) {
    SnackService.warn('chat.error.create');
  } finally {
    actions.joining = null;
  }
  // todo: delete once supported by eventHub
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     const room = getRoomPublic(v4(), Object.assign({ name: config.name }, initConfig));
  //     if (config.members?.length) {
  //       room.addMembers(config.members);
  //     }
  //     resolve(room);
  //   }, 1e3);
  // });
};

const getRoomInfo = async (api: RoomInternalAPI, announce: boolean = false): Promise<boolean> => {
  // details unneeded if user is a client
  if (user?.isClient) return;
  try {
    const info: RoomInfo = await EventService.invoke('chatRoomInfo', api.id);
    api.members = info.members?.reduce((result, member) => Object.assign(result, { [member.profileId]: member }), {});
    api.name = cleanRoomName(info.name);
    api.fileId = info.fileId;
    api.organization = info.organization;
    // server missing "RECENT"
    api.reason = info.reason;
    api.type = info.type;
    api.role = info.role;
    announce && updateRooms();
  } catch (ex) {
    console.warn(ex);
  }
  return false;
};

const addMembers = async (api: RoomInternalAPI, memberIds: string[]): Promise<boolean> => {
  const orgMembers = api.members;
  const newMembers = Object.assign({}, api.members);
  api.members = memberIds.reduce(
    (result, profileId) =>
      Object.assign(result, {
        [profileId]: Object.assign({ profileId, name: 'Joining...' }, newMembers[profileId], {
          state: 'ADDING',
        }),
      }),
    newMembers
  );
  try {
    await EventService.invoke('chatRoomUpdate', api.id, { add: memberIds });
    return true;
  } catch (ex) {
    SnackService.warn('chat.error.add');
    api.members = orgMembers;
  }
  return false;
};

const removeMembers = async (api: RoomInternalAPI, memberIds: string[]) => {
  const orgMembers = api.members;
  const newMembers = Object.assign({}, api.members);
  const isSelf = memberIds.includes(user.PersonId);
  if (isSelf) {
    actions.leaving = true;
  }
  api.members = memberIds.reduce(
    (result, profileId) =>
      Object.assign(result, {
        [profileId]: Object.assign({ profileId, name: 'Leaving...' }, newMembers[profileId], {
          state: 'REMOVING',
        }),
      }),
    newMembers
  );
  try {
    await EventService.invoke('chatRoomUpdate', api.id, { remove: memberIds });
    return true;
  } catch (ex) {
    SnackService.warn('chat.error.remove');
    api.members = orgMembers;
    return false;
  } finally {
    if (isSelf) {
      actions.leaving = null;
    }
  }
};

const setUnreplied = (api: RoomInternalAPI, state: boolean) => {
  const reasons = api.reason.filter((reason) => reason !== 'UNREPLIED');
  api.reason = state ? reasons.concat(['UNREPLIED']) : reasons;
};

const markReplied = async (api: RoomInternalAPI) => {
  try {
    await EventService.invoke('chatRoomMarkReplied', api.id);
    setUnreplied(api, false);
    return true;
  } catch (ex) {
    // console.warn(ex);
    SnackService.warn('chat.error.unreplied');
  }
  return false;
};

const notifyNewMessage = (room: RoomCache) => {
  const api = room.internal;
  // notify user of new message
  const infoKey = room.external.type === 'GROUP' ? 'chat.newGroupMessage' : 'chat.newMessage';
  SnackService.info({ key: infoKey, data: room.external, icon: 'chat' }, 'chat.read').then((snack) => {
    snack.onAction().subscribe(async () => {
      const router = await routerPromise;
      if (user.isClient) {
        router.navigate(['chat']);
      } else {
        router.navigate(['chat', room.external.type.toLowerCase(), api.id]);
      }
    });
  });
};

// -------------------
// EVENT HUB EVENTS
// -------------------
const eventHubEvents = {
  chatMessage: (roomId: string, chatEvents: ChatEvent[]) => {
    // console.log('chatMessage', roomId, chatEvents);
    const room = getRoom(roomId);
    const api = room.internal;
    const count = addMessages(api, chatEvents);
    const isImportant =
      // is client
      user?.isClient ||
      // assigned client/provider
      room.external.reason.includes('ASSIGNED') ||
      // private/group room
      ['PRIVATE', 'GROUP'].includes(api.type) ||
      // user recently contacted provider
      (api.type === 'APRNPROVIDER' && room.external.reason.includes('ACTIVITY'));
    // if we are not watching this room
    // then increment the unread count for the room
    if (count && !api.active && isImportant) {
      api.unreadCount += count;
      api.unreadCount$.next(api.unreadCount);
      notifyNewMessage(room);
    }
    api.lastUpdated = new Date();
    updateRooms();
  },
  chatMessageRead: (roomId: string, messageIds: string[]) => {
    // console.log('chatMessageRead', roomId, messageIds);
    const idMap = messageIds.reduce((result, id) => {
      result[id] = true;
      return result;
    }, {});
    const api = getRoom(roomId).internal;
    api.messages.forEach((message) => {
      if (idMap[message.ID]) {
        message.IsRead = true;
      }
    });
    api.update();
  },
  chatTyping: (roomId: string, isTyping: boolean, name: string) => {
    // ignore typing indicators if room doesn't already exist
    if (!hasRoom(roomId)) return;
    const formattedName = cleanRoomName(name);
    // console.log('chatTyping', roomId, isTyping, name);
    const api = getRoom(roomId).internal;
    // remove typing user if already in list
    api.typingUsers = api.typingUsers.filter((typingName) => formattedName !== typingName);
    // add typing user if typing
    if (isTyping) {
      api.typingUsers.push(formattedName);
    }
    api.typingUsers$.next(api.typingUsers);
  },
  // TODO: cleanup once API updated
  chatRoomCreated: (roomId: string, info: RoomInfo) => {
    const { type, name, organization, reason, members } = info;
    const alreadyExists = hasRoom(roomId);
    const room = getRoom(roomId, { type, name: cleanRoomName(name), organization, reason });
    const api = room.internal;
    api.members = members.reduce(
      (result, member) => Object.assign(result, { [member.profileId]: Object.assign({ state: 'ACTIVE' }, member) }),
      {}
    );
    // notify user if new group room created by someone else
    if (alreadyExists || actions.joining || room.external.type !== 'GROUP') return;
    SnackService.info({ key: 'chat.newRoom', data: room.external, icon: 'chat' }, 'chat.join').then((snack) => {
      snack.onAction().subscribe(async () => {
        const router = await routerPromise;
        if (user.isClient) return;
        router.navigate(['chat', room.external.type.toLowerCase(), api.id]);
      });
    });
  },
  chatRoomUpdated: (roomId: string, updates: RoomUpdated) => {
    // ignore updates for undefined rooms
    if (!hasRoom(roomId)) return;

    const room = getRoom(roomId);
    const api = room.internal;

    // no longer in room
    if (updates.remove?.includes(user.PersonId)) {
      room.external.destroy();
      if (actions.leaving) return;
      SnackService.info({ key: 'chat.removed', data: room.external, icon: 'chat' });
      return;
    }
    // apply updates if present
    if (updates.name) {
      api.name = cleanRoomName(updates.name);
    }

    if (updates.reason) {
      api.reason = updates.reason.map((reason) => reason.trim()) as AvailReason[];
    }

    if (typeof updates.unread === 'number') {
      api.unreadCount = updates.unread;
    }

    // add members
    if (updates.add) {
      updates.add.forEach((member) => {
        api.members[member.profileId] = Object.assign({ state: 'ACTIVE' }, member);
      });
    }
    // remove members
    if (updates.remove) {
      updates.remove.forEach((profileId) => {
        delete api.members[profileId];
      });
    }
    // announce member change
    if (updates.add || updates.remove) {
      api.members$.next(api.members);
    }
  },
};

// register event handlers
Object.entries(eventHubEvents).forEach(([name, handler]) => EventService.on(name, handler));

/**
 * PROVIDERS
 */

type Providers = Record<string, ObservableProvider>;

const normalizeAvailRoom = (rooms: RoomAvail[]) => {
  return rooms.map((room) =>
    Object.assign(room, {
      // Type: room.Type.toUpperCase(),
      // Organization: user?.Organization,
      Reason: (room.Reason || []).map((reason) => reason.trim()),
    })
  );
};

const providers: Providers = {
  availableRooms: {
    bs: new BehaviorSubject<RoomAvail[]>(null),
    match: () => !user?.isClient && user?.PersonId,
    url: () => `Organizations/${user.OrganizationId}/Communication/Rooms`,
    normalize: normalizeAvailRoom,
  },
};

const availableRooms$ = ObservableUtils.getObservable<RoomAvail[]>(providers.availableRooms);

/**
 * INITIALIZATION
 */

// don't initialize if event hub is disabled
if (!StateService.disableEventHub) {
  /**
   * AVAILABLE ROOMS
   **/
  availableRooms$.subscribe((rooms) => {
    if (!rooms) return;
    rooms.forEach((roomAvail) => {
      const unreadCount = roomAvail.Unread;
      const room = getRoom(
        roomAvail.Id.toLowerCase(),
        {
          type: roomAvail.Type,
          role: roomAvail.Role,
          name: cleanRoomName(roomAvail.Name),
          unreadCount: unreadCount,
          lastUpdated: parseISO(roomAvail.Date),
          organization: roomAvail.Organization,
          reason: roomAvail.Reason,
          channelId: TypeToChannelId[roomAvail.Type],
        },
        true
      );
      // manage room
      const api = room.internal;
      // force load of unread message if active
      if (api.active && unreadCount) {
        getMessages(api);
      }
      // done if active or unread matches
      if (api.active || unreadCount === api.unreadCount) return;
      // update unread message count
      api.unreadCount = unreadCount;
      api.unreadCount$.next(unreadCount);
    });
    updateRooms();
  });

  /**
   * USER EVENTS
   **/
  StateService.user$.subscribe((authUser) => {
    user = authUser;
    if (!authUser) {
      unreadCount.next(0);
      rooms.next(null);
      Object.values(providers).forEach(ObservableUtils.resetObservable);
      // delete known rooms
      Object.keys(roomCache).forEach((roomId) => {
        roomCache[roomId].external.destroy(true);
      });
      return;
    }
    Object.values(providers).forEach(ObservableUtils.refreshObservable);
    if (!user.isClient) return;
    getClientUnreadCount();
  });

  // force room info refresh every 5 minutes
  setInterval(() => {
    ObservableUtils.refreshObservable(providers.availableRooms, true);
  }, 300e3);
}

/**
 * CHAT ROOM CLASS
 */

export class ChatRoom {
  private api: RoomInternalAPI = {
    id: null,
    active: false,
    channelId: null,
    isLoading: false,
    isTyping: false,
    lastUpdated: new Date(),
    members: {},
    messages: [] as ChatEvent[],
    messageCache: {} as { [key: string]: ChatEvent },
    name: unknownInstance,
    fileId: null,
    pin: false,
    reason: [],
    type: 'CLIENT',
    typingUsers: [] as string[],
    unreadCount: 0,
    oldestMs: addDays(new Date(), 1).valueOf(),
    organization: unknownInstance,
    enableNotifications: false,
    isLoading$: new BehaviorSubject(false),
    lastUpdated$: new BehaviorSubject(new Date()),
    members$: new BehaviorSubject({}),
    messages$: new BehaviorSubject([]),
    name$: new BehaviorSubject(unknownInstance),
    reason$: new BehaviorSubject([]),
    typingUsers$: new BehaviorSubject([]),
    unreadCount$: new BehaviorSubject(0),
    update: () => {
      this.api.messages$.next(this.api.messages);
    },
  };

  private applyNotificationState = () => {
    this.api.enableNotifications =
      // is client
      user?.isClient ||
      // assigned client/provider
      this.api.reason.includes('ASSIGNED') ||
      // private/group room
      ['PRIVATE', 'GROUP'].includes(this.api.type) ||
      // user recently contacted provider
      (this.api.type === 'APRNPROVIDER' && this.api.reason.includes('ACTIVITY'));
  };

  private wrapStreamProp<T>(propName: string, stream: BehaviorSubject<T>, action?: () => void) {
    let _value: T = this.api[propName];
    Object.defineProperty(this.api, propName, {
      get: (): T => {
        return _value;
      },
      set: (value: T) => {
        _value = value;
        stream && stream.next(value);
        action && action();
      },
    });
  }

  get id() {
    return this.api.id;
  }

  get active() {
    return this.api.active;
  }

  get pin() {
    return this.api.pin;
  }

  get reason() {
    return this.api.reason;
  }

  // the channel to use for communication (default: cfa)
  get channelId() {
    return this.api.channelId;
  }
  set channelId(value: number) {
    this.api.channelId = value;
    this.api.update();
  }

  message: string; // the message to send when sendMessage is called

  get name() {
    return this.api.name;
  }
  set name(value: string) {
    if (!value) return;
    if (this.api.name === unknownInstance) {
      // we are initializing the room name
      this.api.name = cleanRoomName(value);
      return;
    }
    // we are changing the room name
    updateRoomName(this.api, value);
  }

  get fileId() {
    return this.api.fileId;
  }

  get isPersonal() {
    return this.api.enableNotifications;
  }

  get isSelf() {
    return this.api.id === user?.PersonId;
  }

  get memberCount() {
    return Object.keys(this.api.members).length;
  }

  get organization() {
    return this.api.organization;
  }

  get role() {
    return this.api.role;
  }

  get type() {
    return this.api.type;
  }

  get unread() {
    return this.api.unreadCount;
  }

  get updated() {
    return this.api.lastUpdated;
  }

  constructor(roomId: string, apiContainer: { api?: RoomInternalAPI; config?: RoomInitConfig } = {}) {
    // wrap automation to property changes
    this.wrapStreamProp('isLoading', this.api.isLoading$);
    this.wrapStreamProp('lastUpdated', this.api.lastUpdated$);
    this.wrapStreamProp('members', this.api.members$);
    this.wrapStreamProp('name', this.api.name$);
    this.wrapStreamProp('reason', this.api.reason$, this.applyNotificationState);
    this.wrapStreamProp('type', null, this.applyNotificationState);
    this.wrapStreamProp('unreadCount', this.api.unreadCount$);
    // allow private data to be accessed by constructor caller
    apiContainer.api = this.api;
    // initialize room properties
    Object.assign(this.api, { id: roomId, channelId: TypeToChannelId[apiContainer.config.type] }, apiContainer.config);
    // link changes to unread count to recalculation of global unread count
    this.api.unreadCount$.subscribe(updateGlobalUnreadCount);
  }

  // OBSERVABLE PROPS
  isLoading$ = this.api.isLoading$.asObservable();
  lastUpdated$ = this.api.lastUpdated$.asObservable();
  members$ = this.api.members$.asObservable();
  messages$ = this.api.messages$.pipe(
    ObservableUtils.detectActivity(
      () => {
        this.api.active = true;
        this.api.unreadCount = 0;
        this.api.unreadCount$.next(0);
        getRoomInfo(this.api);
        // get latest messages when start watching
        // even if we already have them
        getMessages(this.api);
      },
      () => {
        this.api.active = false;
        sendTyping(this.api, false);
      }
    )
  );
  name$ = this.api.name$.asObservable();
  reason$ = this.api.reason$.asObservable();
  typing$ = this.api.typingUsers$.asObservable();
  unread$ = this.api.unreadCount$.asObservable();

  // METHODS
  async addMembers(members: string[]) {
    return addMembers(this.api, members);
  }

  async removeMembers(members: string[]) {
    return removeMembers(this.api, members);
  }

  refreshInfo() {
    getRoomInfo(this.api);
  }

  getMoreMessages() {
    getMessages(this.api, true);
  }

  async markReplied() {
    return markReplied(this.api);
  }

  async sendMessage(message: string | number = this.message, channelId: number = this.api.channelId) {
    this.message = null;
    return sendMessage(this.api, message, channelId);
  }

  sendTyping(state: boolean) {
    sendTyping(this.api, state);
  }

  destroy(quiet: boolean = false) {
    // finalize streams
    this.api.isLoading$.complete();
    this.api.members$.complete();
    this.api.name$.complete();
    this.api.messages$.complete();
    this.api.typingUsers$.complete();
    this.api.unreadCount$.complete();
    // misc cleanups
    this.api.messages = [];
    // remove room from global cache
    delete roomCache[this.api.id];
    quiet || updateRooms();
  }
}

/**
 * CHAT SERVICE API
 */

export class ChatV2Service {
  constructor() {}

  static rooms$ = rooms.asObservable();
  static unreadCount$ = unreadCount.asObservable().pipe(debounceTime(50));

  static async createClientRoom(config: RoomCreateConfig) {
    const roomExists = roomCache[config.profileId.toLowerCase()];
    const isAPRN = OrgService.isAPRN(config.organizationId || user.OrganizationId);
    const room = getRoom(
      config.profileId,
      {
        name: cleanRoomName(config.name),
        type: isAPRN ? 'APRNCLIENT' : 'CLIENT',
        organization: config.organization,
      },
      true
    ).external;
    if (!roomExists) updateRooms();
    return room;
  }

  static async createProviderRoom(config: RoomCreateConfig) {
    const roomExists = roomCache[config.profileId.toLowerCase()];
    const room = getRoom(
      config.profileId,
      {
        name: cleanRoomName(config.name),
        type: 'APRNPROVIDER',
        organization: config.organization,
      },
      true
    ).external;
    if (!roomExists) updateRooms();
    return room;
  }

  static async createGroupRoom(config: RoomCreateConfig) {
    return createRoom(config, { type: 'GROUP' });
  }

  static async createPrivateRoom(config: RoomCreateConfig) {
    return createRoom(config, { type: 'PRIVATE' });
  }
  static getRoom = getRoomPublic;
  static hasRoom = hasRoom;
}
