<!-- @format -->

<button *ngIf="enabled && langs.length > 1" [matMenuTriggerFor]="languageMenu" mat-icon-button type="button" aria-label="open language menu">
  <ic-icon [icIcon]="icLanguage" size="24"></ic-icon>
</button>

<mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
  <div *transloco="let t" class="mat-menu-title title">{{ t('menu.lang') }}</div>
  <button mat-menu-item *ngFor="let lang of langs" (click)="setLang(lang.id)" class="text-center" [class.selected]="currentLang === lang.id">
    {{ lang.label }}
  </button>
</mat-menu>
