/** @format */

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateUtil } from '../shared/date-util';
import { ObservableProvider, ObservableUtils } from '../shared/observable-utils';
import { ServiceLocator } from '../shared/service-locator';
import { StateService } from './state.service';

const urlCollection = {
  getType: (organizationId: string, type: string, includeChildren: boolean, lang: string) =>
    `Organizations/${organizationId}/Types/${type}?all=${includeChildren}&lang=${lang}`,
};

// #region Type Definitions

export interface CommonServiceResponse {
  status: 'SUCCESS' | 'ERROR';
}

interface CommonContent {
  PrefLanguageTypeId: number;
  Type: string;
}

// entity types
export interface CommonType {
  Id?: number;
  IsActive?: boolean; // active/inactive
  IsMasterType?: boolean; // is this locked from org editing, Admin: IsBeingAdministered === 0
  IsParent?: boolean; // has children
  EntityType?: string; // entity type of this item
  ParentType?: string; // entity type of parent
  ParentTypeId?: number; // id of parent type entry
  Sequence?: number; // display order
  SequenceEnabled?: boolean; // display order enabled
  Type: string; // display value in requested language
  DisplayComment?: boolean; // comments for this type allowed

  Content?: CommonContent[];
  Filter?: boolean; // Filter can be reason for visit
  FilterColor?: string; // event color
  Value?: number; // points
  Types?: CommonType[]; // child types if defined in AdministrationOfType
}

// common base for many models
export interface CommonBase {
  IsActive: number; // bit
  LastUpdatedBy: string; // "7680b5f8-eee0-47a0-910a-a1b33a7849f0",
  LastUpdatedDate: string; // "2022-12-27T13:34:37.563",
  RecordCreatedBy: string; // "7680b5f8-eee0-47a0-910a-a1b33a7849f0",
  RecordCreatedDate: string; // "2022-12-27T13:34:37.563"
  // mapped
  isActive: boolean;
  lastUpdatedDate: Date;
  recordCreatedDate: Date;
}

export interface CommonView<T = any, B = any> {
  viewModel: T;
  viewData: B;
}

export type CommonEntityType =
  | 'AdditionalSupportType'
  | 'AttendanceType'
  | 'BirthControlType'
  | 'CaseOutcomeType'
  | 'CategoryType'
  | 'ChannelType'
  | 'ChosenGenderType'
  | 'ConsentType'
  // | 'Classe'
  | 'ClientCategoryType'
  | 'ClientNotifiedType'
  | 'ConceptSharedType'
  | 'ContactModeType'
  | 'CurrencyType'
  | 'DecisionType'
  | 'Demographic'
  | 'DemographicType'
  | 'DonorRelationshipType'
  | 'EmotionalSideEffectType'
  | 'EventName'
  | 'EventType'
  | 'FollowUpType'
  | 'GenderType'
  | 'GodRelationshipType'
  | 'InsuranceType'
  | 'InteractionOutcomeType'
  | 'InteractionType'
  | 'LanguageType'
  | 'LocationType'
  | 'MedicalHistoryType'
  | 'PaymentType'
  | 'PhoneType'
  | 'PhysicalSideEffectType'
  | 'PositivePregnancyOutcomeType'
  | 'PrefixType'
  | 'PrefLanguageType'
  | 'PregnancyIntentionType'
  | 'PregnancySymptomType'
  | 'PurposeOfGiftType'
  | 'QuickBooksClassType'
  | 'QuickBooksItemType'
  | 'ReferralType'
  | 'RelationshipType'
  | 'ReligiousPreferenceType'
  | 'RequestService'
  | 'RequestServiceType'
  | 'Resource'
  | 'ResourceType'
  | 'RewardType'
  | 'RiskType'
  | 'SalutationType'
  | 'ServiceUnit'
  | 'ServiceUnitItem'
  | 'ServiceUnitType'
  | 'SourceOfGiftType'
  | 'SpiritualDiscussionOutcomeType'
  // | 'SpiritualStatusType'
  | 'StaffEventType'
  | 'STDSTIType'
  | 'TargetType'
  | 'TestResultType'
  | 'TimeFrameType'
  | 'TimeZoneType'
  | 'TitleType'
  | 'TouchOutcomeType'
  | 'UltrasoundApprovalTimeframeType'
  | 'UltrasoundImagesReviewStatusType'
  | 'UltrasoundType';

// #endregion

// #region COMMON FUNCTIONS

// TODO: these should probably be added to the class as static methods

// get type value from common type array
function getCommonTypeValue(list: CommonType[], id: number): string;
// get multiple type values from common type array
function getCommonTypeValue(list: CommonType[], id: number[]): string[];
// implementation
function getCommonTypeValue(list: CommonType[], id: any): any {
  if (typeof id === 'number') {
    return list?.find((item) => item.Id === id)?.Type;
  }
  // convert list to {id1: type1, id2: type2, ...}
  const listMap = list?.reduce((result, item) => ({ ...result, [item.Id]: item.Type }), {}) || {};
  return id?.map((id) => listMap[id]);
}
export { getCommonTypeValue };

export const isCommonTypeDisplayComment = (list: CommonType[], id: number): boolean =>
  list?.find((item) => item.Id === id)?.DisplayComment ?? false;

export const isCommonTypeFiltered = (list: CommonType[], id: number): boolean =>
  list?.find((item) => item.Id === id)?.Filter ?? false;

// normalize functions
type INormalizeCommonTypeOverload = {
  (types: CommonType[]): CommonType[];
  (types: CommonType[], last: number | number[]): CommonType[];
  (types: CommonType[], last: number | number[], includeInactive: boolean): CommonType[];
};
export const normalizeCommonType: INormalizeCommonTypeOverload = (
  types: CommonType[],
  last: number | number[] = null,
  includeInactive: boolean = false
): CommonType[] => {
  const lastN = Array.isArray(last) ? last : [];
  return types
    .filter((type) => includeInactive || type.IsActive)
    .map((type, index) =>
      Object.assign(type, { Types: type.Types && normalizeCommonType(type.Types, lastN[index], includeInactive) })
    )
    .sort(
      (a, b) =>
        (a.Id === last && 1) ||
        (b.Id === last && -1) ||
        (a.SequenceEnabled && a.Sequence - b.Sequence) ||
        a.Type.localeCompare(b.Type)
    );
};

export const normalizeCommonBase = <T extends CommonBase>(item: T): T =>
  Object.assign(item, {
    isActive: item.IsActive === 1,
    lastUpdatedDate: DateUtil.parseDateTimeISOFromEST(item.LastUpdatedDate),
    recordCreatedDate: DateUtil.parseDateTimeISOFromEST(item.RecordCreatedDate),
  });

export const removeCommonBase = <T extends CommonBase>(item: T): T => ({
  ...item,
  isActive: undefined,
  IsActive: undefined,
  LastUpdatedBy: undefined,
  lastUpdatedDate: undefined,
  LastUpdatedDate: undefined,
  RecordCreatedBy: undefined,
  recordCreatedDate: undefined,
  RecordCreatedDate: undefined,
});

export const normalizeCommonBaseList = <T extends CommonBase>(items: T[]): T[] => items.map(normalizeCommonBase);

// #endregion

export class CommonService {
  // internal properties
  private static clientLang: string = 'en';
  private static observables: Record<string, Observable<CommonType[]>> = {};
  private static providers: Record<string, ObservableProvider> = {};

  // default overrides for common types
  private static typeMap = {
    AdditionalSupportType: { last: 5 },
    BirthControlType: { last: 11 },
    CaseOutcomeType: { last: 24 },
    ConceptSharedType: { last: 10 },
    DecisionType: { last: 3 },
    DemographicType: { last: [7, -1, 27, 166, 45, 173, 57] },
    EmotionalSideEffectType: { last: 17 },
    LanguageType: { last: 6 },
    MedicalHistoryType: { last: 12 },
    PhoneType: { last: 7 },
    PhysicalSideEffectType: { last: 9 },
    PositivePregnancyOutcomeType: { last: 9 },
    PregnancySymptomType: { last: 9 },
    ReferralType: { last: 69 },
    RelationshipType: { last: 22 },
    ReligiousPreferenceType: { last: 13 },
    RequestServiceType: { last: [75, 76, 17] },
    ResourceType: { last: [395, 25, -1] },
    RiskType: { last: [] },
    ServiceUnitType: { last: 12 },
    STDSTIType: { last: 12 },
  };

  // static initialization block
  static {
    const translocoPromise = ServiceLocator.get(TranslocoService);
    // track active language
    translocoPromise.then((translocoService) => {
      translocoService.langChanges$.subscribe((lang) => {
        this.clientLang = lang;
        Object.values(this.providers).forEach(ObservableUtils.refreshObservable);
      });
    });

    // track authenticated user
    StateService.user$.subscribe((user) => {
      if (!user) {
        Object.values(this.providers).forEach(ObservableUtils.resetObservable);
        return;
      }
      Object.values(this.providers).forEach(ObservableUtils.refreshObservable);
    });
  }

  static filterInactive(types: CommonType[], deep?: boolean): CommonType[] {
    const result = types?.filter((item) => item.IsActive);
    return deep ? result?.map((item) => ({ ...item, Types: CommonService.filterInactive(item.Types, deep) })) : result;
  }

  // dynamically create provider for requested entity type
  // resulting observable may includes inactive items
  // caller is responsible for handling/filtering inactive items
  // if organizationId is not provided, the current user orgId is used
  static getTypes$(entityType: CommonEntityType, includeChildren: boolean = false, orgId?: string) {
    const config = this.typeMap[entityType] || {};
    const id = `${orgId || 'active'}.types.${entityType}.${includeChildren}`;
    const defaultMatch = orgId
      ? () => `${orgId}-${this.clientLang}`
      : () => StateService.user?.OrganizationId && `${StateService.user?.OrganizationId}-${this.clientLang}`;
    // get existing provider or get a newly created one
    const provider =
      this.providers[id] ||
      (this.providers[id] = {
        bs: new BehaviorSubject<CommonType[]>(null),
        match: config.match || defaultMatch,
        url: () =>
          urlCollection.getType(
            orgId || StateService.user?.OrganizationId,
            entityType,
            includeChildren,
            this.clientLang
          ),
        normalize: (data) => normalizeCommonType(data, config.last, true),
      });
    // get existing observable or get a newly created one
    const observable =
      this.observables[id] || (this.observables[id] = ObservableUtils.getObservable<CommonType[]>(provider));
    return observable;
  }

  // refresh dynamic provider if entityType updated
  static refreshType(entityType: CommonEntityType) {
    const ids = [
      `active.types.${entityType}.false`,
      `active.types.${entityType}.true`,
      `${StateService.user?.OrganizationId}.types.${entityType}.false`,
      `${StateService.user?.OrganizationId}.types.${entityType}.true`,
    ];
    ids.forEach((id) => this.providers[id] && ObservableUtils.refreshObservable(this.providers[id], true));
  }
}
