/** @format */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { DialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { environment } from 'src/environments/environment';
import { ApiInterceptor } from '../interceptors/api-interceptor';
import { InterceptorContext } from '../interceptors/interceptor-context';
import { MockInterceptor } from '../interceptors/mock.interceptor';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { LazyDialogService } from '../services/lazy-dialog.service';
import { StateService } from '../services/state.service';
import { DateUtil } from '../shared/date-util';
import { ObservableUtils as OU, ObservableProvider } from '../shared/observable-utils';
import { ServiceLocator } from '../shared/service-locator';
import { OrgService } from './org.service';
import { SnackService } from './snack.service';

const urlCollection = {
  deleteDevice: (deviceId) => `Organizations/${userOrgId}/Access/Devices/${deviceId}`,
  deleteLocation: (ipAddress) => `Organizations/${userOrgId}/Access/Locations/${ipAddress}`,
  deleteUserDevices: (profileId, deviceId) =>
    `Organizations/${userOrgId}/Access/Users/${profileId}/Devices/${deviceId}`,
  getConnect: () => `Organizations/${userOrgId}/Access/Connect`,
  getDevices: () => `Organizations/${userOrgId}/Access/Devices`,
  getLocations: () => `Organizations/${userOrgId}/Access/Locations`,
  getPendingRequests: () => urlCollection.getRequests(true),
  getPostMFA: () => `Organizations/${userOrgId}/Access/MFA`,
  getRequests: (pending: boolean = false) => `Organizations/${userOrgId}/Access/Requests?pending=${pending}`,
  getUserDevices: (profileId) => `Organizations/${userOrgId}/Access/Users/${profileId}/Devices`,
  postApprove: () => `Organizations/${userOrgId}/Access/Approve`,
};

// #region Type Definitions

export type AccessState = 'ALLOW' | 'DENY' | 'REQUESTING' | 'MFA';
export interface AccessEvent {
  name: string; // full name of the requester
  requestId: string;
  location?: string; // future 'city, state, country' via ip to location lookup service
  locationIp: string; // ip address
  locationNew: boolean;
  device?: string; // future chrome | android | ipad | etc...
  deviceNew: boolean;
}

export interface AccessOrgDevice {
  ApprovalDate: string; // "2023-03-15T09:32:06.2640537",
  ApproverId: string; // null,
  ApproverName: string; // null
  DeviceId: string; // "bf653167-d527-4869-91ce-761f9f8ff59e",
  DeviceName: string; // "Chrome (Windows 10)",
  FirstUsedDate: string; // "2023-03-28T13:49:56.813"
  IsActive: boolean;
  LastUpdatedDate: string; // "2023-03-28T14:56:07.11",
  LastUsedById: string; // "496d2e31-c415-4322-cf3f-08d9a2317662",
  LastUsedByName: string; // "Ben White",
  LastUsedDate: string; // "2023-03-28T14:17:48.023",
  // mapped
  approvalDate?: Date;
  firstUsedDate?: Date;
  lastUpdatedDate?: Date;
  lastUsedDate?: Date;
  lastUsedDateOnly?: string; // "2023-03-28",
}

export interface AccessOrgLocation {
  ApprovalDate: string; // "2023-03-15T09:32:06.2640537",
  ApproverId: string; // null,
  ApproverName: string; // null
  FirstUsedDate: string; // "2023-03-28T13:49:56.813"
  IsActive: boolean;
  LastUpdatedDate: string; // "2023-03-28T14:56:07.11",
  LastUsedById: string; // "496d2e31-c415-4322-cf3f-08d9a2317662",
  LastUsedByName: string; // "Ben White",
  LastUsedDate: string; // "2023-03-28T14:45:10.913",
  LocationIP: string; // "0.0.0.1",
  LocationName: string; // null,
  // mapped
  approvalDate?: Date;
  firstUsedDate?: Date;
  lastUpdatedDate?: Date;
  lastUsedDate?: Date;
  lastUsedDateOnly?: string; // "2023-03-28",
}

export interface AccessRequest {
  ApprovalDate: string; // "2023-03-15T09:32:06.2640537",
  Approved: boolean; // true,
  ApproverId: string; // "496d2e31-c415-4322-cf3f-08d9a2317662",
  ApproverName: string; // "Ben White",
  DeviceId: string; // "bf653167-d527-4869-91ce-761f9f8ff59e",
  DeviceName: string; // "Windows",
  DeviceNew: boolean; // true,
  LocationIP: string; // "0.0.0.1",
  LocationName: string; // null,
  LocationNew: boolean; // false,
  RequestDate: string; // "2023-03-15T09:20:20.8954802",
  RequesterId: string; // "4dae208c-57ff-4003-d927-08dab3773010",
  RequesterName: string; // "Ben Staff Test"
  RequestId: string; // "2972aa89-e9db-48d8-13ea-08db5ad532d7",
  // mapped
  approvalDate?: Date;
  requestDate?: Date;
  requestDateOnly?: string;
}

export interface AccessUserDevice {
  DeviceId: string; // "bf653167-d527-4869-91ce-761f9f8ff59e",
  DeviceName: string; // "Chrome (Windows 10)",
  ExpiresDate: string; // "2023-06-26T13:49:56.8123115",
  FirstUsedDate: string; // "2023-03-28T13:49:56.813"
  IsActive: boolean;
  LastUsedDate: string; // "2023-03-28T14:56:07.11",
  LocationIP: string; // "127.0.0.1",
  LocationName: string; // null,
  PersonId: string; // "496d2e31-c415-4322-cf3f-08d9a2317662",
  // mapped
  expiresDate?: Date;
  firstUsedDate?: Date;
  lastUsedDate?: Date;
  isExpired?: boolean;
}

// #endregion

// switch to enable/disabled access control features
const enabled = environment.features.accessControl;

// tracks the current access state
let _currentState: AccessState = enabled ? null : 'ALLOW';
const currentState = new BehaviorSubject<AccessState>(_currentState);

let isAdmin = false;
let isAllowed: boolean; // has device been granted access by org
let userProfileId: string;
let userOrgId: string;

const dialogPromise = ServiceLocator.get(MatDialog);
const httpPromise = ServiceLocator.get(HttpClient);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);
const contextAccess = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { access: false } }]);
const contextMock = InterceptorContext.get([{ interceptor: MockInterceptor, config: { enable: true } }]);

// #region DIALOGS

const openDialogMFA = async () => {
  const dialog = await dialogPromise;
  if (dialog.openDialogs.length) return;
  const dialogConfig = new MatDialogConfig();
  const data = {};
  Object.assign(dialogConfig, {
    autoFocus: true,
    closeOnNavigation: false,
    disableClose: true,
    data,
  });
  const dialogRef = await LazyDialogService.open('mfa', dialogConfig);
  dialogRef.afterClosed().subscribe(async (result) => {
    // if user canceled verification
    if (!result && _currentState === 'MFA') return currentState.next('DENY');
  });
};

const openDialogReq = async () => {
  const dialog = await dialogPromise;
  if (dialog.openDialogs.length) return;

  const dialogConfig = new MatDialogConfig<DialogData>();
  const data: DialogData = {
    title: 'auth.access.title',
    content: 'auth.access.content',
    // contentData: { name: this.orgInfo?.Name || '' },
    maxHeight: 'xs',
    defaultValue: false,
    enableMarkdown: true,
    actions: [
      {
        label: 'form.button.cancel',
        value: true,
      },
    ],
  };
  Object.assign(dialogConfig, {
    autoFocus: true,
    closeOnNavigation: false,
    disableClose: true,
    // backdropClass: 'bg-pattern',
    data,
  });
  const dialogRef = await LazyDialogService.open('generic-dialog', dialogConfig);
  dialogRef.afterClosed().subscribe((result) => {
    if (!result) return;
    currentState.next('DENY');
  });
};

const openApprovalDialog = async () => {
  if (!isAdmin) return;
  const dialogConfig = new MatDialogConfig();
  Object.assign(dialogConfig, {
    autoFocus: true,
    closeOnNavigation: true,
    disableClose: true,
  });
  const dialogRef = await LazyDialogService.open('access-approval', dialogConfig);
  return firstValueFrom(dialogRef.afterClosed());
};

const closeDialogs = async () => {
  const dialog = await dialogPromise;
  dialog.closeAll();
  return firstValueFrom(dialog.afterAllClosed);
};

// #endregion

// #region PROVIDERS

let enabledMFA: boolean;
let enabledTracking: boolean;
let enabledApproval: boolean;
const getOrgTracking = () => enabled && enabledTracking && isAllowed && isAdmin && userOrgId;
const getOrgApproval = () => enabled && enabledApproval && isAllowed && isAdmin && userOrgId;

const normalizeAccessRequest = (requests: AccessRequest[]): AccessRequest[] =>
  requests
    .map((request) =>
      Object.assign(request, {
        approvalDate: request.ApprovalDate && DateUtil.parseDateTimeISOFromEST(request.ApprovalDate),
        requestDate: DateUtil.parseDateTimeISOFromEST(request.RequestDate),
        requestDateOnly: request.RequestDate.slice(0, 10),
      })
    )
    .sort((a, b) => b.RequestDate.localeCompare(a.RequestDate));

type Providers = Record<string, ObservableProvider>;
const providers: Providers = {
  devices: {
    bs: new BehaviorSubject<AccessOrgDevice[]>(null),
    match: getOrgTracking,
    url: urlCollection.getDevices,
    normalize: (devices: AccessOrgDevice[]) =>
      devices
        .map((device) =>
          Object.assign(device, {
            approvalDate: DateUtil.parseDateTimeISOFromEST(device.ApprovalDate),
            firstUsedDate: DateUtil.parseDateTimeISOFromEST(device.FirstUsedDate),
            lastUpdatedDate: DateUtil.parseDateTimeISOFromEST(device.LastUpdatedDate),
            lastUsedDate: DateUtil.parseDateTimeISOFromEST(device.LastUsedDate),
            lastUsedDateOnly: device.LastUsedDate?.slice(0, 10),
          })
        )
        .sort((a, b) => (b.LastUsedDate || '').localeCompare(a.LastUsedDate || '')),
  },
  locations: {
    bs: new BehaviorSubject<AccessOrgLocation[]>(null),
    match: getOrgTracking,
    url: urlCollection.getLocations,
    normalize: (locations: AccessOrgLocation[]) =>
      locations
        .map((location) =>
          Object.assign(location, {
            approvalDate: DateUtil.parseDateTimeISOFromEST(location.ApprovalDate),
            firstUsedDate: DateUtil.parseDateTimeISOFromEST(location.FirstUsedDate),
            lastUpdatedDate: DateUtil.parseDateTimeISOFromEST(location.LastUpdatedDate),
            lastUsedDate: DateUtil.parseDateTimeISOFromEST(location.LastUsedDate),
            lastUsedDateOnly: location.LastUsedDate?.slice(0, 10),
          })
        )
        .sort((a, b) => (b.LastUsedDate || '').localeCompare(a.LastUsedDate || '')),
  },
  pending: {
    bs: new BehaviorSubject<AccessRequest[]>(null),
    match: getOrgApproval,
    url: urlCollection.getPendingRequests,
    normalize: normalizeAccessRequest,
  },
  requests: {
    bs: new BehaviorSubject<AccessRequest[]>(null),
    match: getOrgApproval,
    url: urlCollection.getRequests,
    normalize: normalizeAccessRequest,
  },
};

// #endregion

// #region EVENT HUB API

currentState.subscribe(async (state) => {
  // short circuit if valid state change
  if (!state || _currentState === state) return;
  _currentState = state;
  isAllowed = state === 'ALLOW';
  // update access granted state
  StateService.accessGranted$.next(isAllowed);
  if (parent !== self) {
    // alert cms of current access state
    parent.postMessage({ accessState: state }, environment.host);
  }
  // close dialogs
  await closeDialogs();
  // process state change
  switch (state) {
    case 'MFA':
      return openDialogMFA();
    case 'REQUESTING':
      return openDialogReq();
    case 'DENY':
      SnackService.warn({ key: 'auth.access.denied', data: {}, icon: 'security' });
      AuthService.logout();
      break;
    case 'ALLOW': // check again on next session
      Object.values(providers).forEach(OU.refreshObservable);
      break;
  }
});

const eventHubEvents = {
  accessUpdate: async (deviceId: string, state: AccessState) => {
    if (deviceId !== StateService.deviceId || !state) return;
    // process updates last
    currentState.next(state);
  },
  accessRequested: async (event: AccessEvent, pending: AccessRequest[]) => {
    if (!enabled || !isAdmin) return;
    // update streams
    providers.pending.bs.next(normalizeAccessRequest(pending));
    OU.refreshObservable(providers.requests, true);
    // display message
    const key =
      event.deviceNew && event.locationNew
        ? 'auth.access.request'
        : event.deviceNew
        ? 'auth.access.requestDevice'
        : event.locationNew
        ? 'auth.access.requestLocation'
        : null;
    if (!key) return;
    SnackService.info({ key, data: event, icon: 'security' }, 'form.button.view', 0).then((snack) => {
      snack.onAction().subscribe(() => {
        openApprovalDialog();
      });
    });
  },
  accessAllowed: async (event: AccessEvent, pending: AccessRequest[]) => {
    if (!enabled || !isAdmin) return;
    // update streams
    providers.pending.bs.next(normalizeAccessRequest(pending));
    Object.values(providers).forEach((p) => OU.refreshObservable(p, p !== providers.pending));
    // display message
    const key =
      event.deviceNew && event.locationNew
        ? 'auth.access.allowed'
        : event.deviceNew
        ? 'auth.access.allowedDevice'
        : event.locationNew
        ? 'auth.access.allowedLocation'
        : null;
    if (!key) return;
    SnackService.info({ key, data: event, icon: 'security' }, '', 0);
  },
  accessDenied: async (event: AccessEvent, pending: AccessRequest[]) => {
    if (!enabled || !isAdmin) return;
    // update streams
    providers.pending.bs.next(normalizeAccessRequest(pending));
    Object.values(providers).forEach((p) => OU.refreshObservable(p, p !== providers.pending));
    // display message
    const key =
      event.deviceNew && event.locationNew
        ? 'auth.access.denied'
        : event.deviceNew
        ? 'auth.access.deniedDevice'
        : event.locationNew
        ? 'auth.access.deniedLocation'
        : null;
    if (!key) return;
    SnackService.info({ key, data: event, icon: 'security' }, '', 0);
  },
};

// register event hub handlers
Object.entries(eventHubEvents).forEach(([name, handler]) => EventService.on(name, handler));

// request access state on connect
EventService.connectionId$.subscribe(async (id) => {
  if (!enabled) return;
  currentState.next(null);
  if (!id) return;
  const http = await httpPromise;
  const response = http.get<{ accessState: AccessState }>(urlCollection.getConnect(), { context: contextAccess });
  const { accessState } = await firstValueFrom(response);
  currentState.next(accessState);
});

// #endregion

// #region USER CHANGE DETECTION

StateService.user$.subscribe((user) => {
  userProfileId = user?.PersonId;
  userOrgId = user?.OrganizationId;
});

StateService.authorizations$.subscribe((auth) => {
  isAdmin = auth['Application.Admin'];
  Object.values(providers).forEach(OU.refreshObservable);
});

OrgService.organization$.subscribe(async (org) => {
  if (!org) {
    enabledMFA = enabledTracking = enabledApproval = null;
    Object.values(providers).forEach(OU.resetObservable);
    return;
  }
  enabledMFA = org.MFA;
  enabledTracking = org.TrackDevice || org.TrackLocation;
  enabledApproval = enabledTracking && (org.TrackDeviceApproval || org.TrackLocationApproval);
  Object.values(providers).forEach(OU.refreshObservable);
});

// #endregion

// #region SERVICE API

const approve = async (requestId: string, allow: boolean): Promise<boolean> => {
  try {
    const http = await httpPromise;
    const response = http.post<unknown>(urlCollection.postApprove(), { approved: allow, requestId }, { context });
    await firstValueFrom(response);
    return true;
  } catch (ex) {
    SnackService.warn({ key: 'auth.status.failure.access', data: {}, icon: 'security' });
    return false;
  }
};

// request code be sent to device
// returns seconds until code expires
const mfaRequest = async (mode: string): Promise<number> => {
  try {
    const http = await httpPromise;
    const response = http.get<{ expires: number }>(urlCollection.getPostMFA(), {
      context: contextAccess,
      params: { mode },
    });
    const { expires } = await firstValueFrom(response);
    return expires;
  } catch (ex) {
    return 0;
  }
};

// verifies code, optionally remembers for 90 days (default 12 hours)
// returns valid/invalid boolean
const mfaVerify = async (code: string, remember: boolean = false): Promise<boolean> => {
  try {
    const http = await httpPromise;
    const response = http.post<{ verified: boolean; accessState: AccessState }>(
      urlCollection.getPostMFA(),
      { code, remember },
      { context: contextAccess }
    );
    const { verified, accessState } = await firstValueFrom(response);
    if (accessState) currentState.next(accessState);
    return verified;
  } catch (ex) {
    return false;
  }
};

// route resolver will call this before loading route
const resolve = async (url: string): Promise<AccessState> => {
  if (!enabled || StateService.disableEventHub) return 'ALLOW';
  // wait for access state from server (will continue waiting if MFA or REQUESTING)
  return await firstValueFrom(currentState.pipe(filter((state) => state === 'ALLOW')));
};

// TODO: this should be converted into a dynamic provider since it depends on org setting "enabledMFA" which may arrive late
const getUserDevices = async (profileId: string): Promise<AccessUserDevice[]> => {
  if (!enabled || !enabledMFA) return;
  if (!isAdmin && profileId !== userProfileId) return;
  const http = await httpPromise;
  const response = http.get<AccessUserDevice[]>(urlCollection.getUserDevices(profileId), { context });
  const data = await firstValueFrom(response);
  const dtm = new Date();
  const normalized = data
    .map((device) =>
      Object.assign(device, {
        expiresDate: DateUtil.parseDateTimeISOFromEST(device.ExpiresDate),
        firstUsedDate: DateUtil.parseDateTimeISOFromEST(device.FirstUsedDate),
        lastUsedDate: DateUtil.parseDateTimeISOFromEST(device.LastUsedDate),
      })
    )
    .map((device) =>
      Object.assign(device, {
        isExpired: !device.IsActive || device.expiresDate < dtm,
      })
    );
  return normalized;
};

const disableDevice = async (deviceId: string): Promise<boolean> => {
  const http = await httpPromise;
  const response = http.delete<AccessUserDevice[]>(urlCollection.deleteDevice(deviceId), { context });
  try {
    await firstValueFrom(response);
    OU.refreshObservable(providers.devices, true);
    return true;
  } catch (ex) {
    return false;
  }
};

const disableLocation = async (ipAddress: string): Promise<boolean> => {
  const http = await httpPromise;
  const response = http.delete<AccessUserDevice[]>(urlCollection.deleteLocation(ipAddress), { context });
  try {
    await firstValueFrom(response);
    OU.refreshObservable(providers.locations, true);
    return true;
  } catch (ex) {
    return false;
  }
};

const disableUserDevice = async (profileId: string, deviceId: string): Promise<boolean> => {
  const http = await httpPromise;
  const response = http.delete<AccessUserDevice[]>(urlCollection.deleteUserDevices(profileId, deviceId), { context });
  try {
    await firstValueFrom(response);
    return true;
  } catch (ex) {
    return false;
  }
};

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor() {}

  static orgDevices$ = OU.getObservable<AccessOrgDevice[]>(providers.devices);
  static orgLocations$ = OU.getObservable<AccessOrgLocation[]>(providers.locations);
  static pending$ = OU.getObservable<AccessRequest[]>(providers.pending);
  static requests$ = OU.getObservable<AccessRequest[]>(providers.requests);

  static approve = approve;
  static disableDevice = disableDevice;
  static disableLocation = disableLocation;
  static disableUserDevice = disableUserDevice;
  static getUserDevices = getUserDevices;
  static mfaRequest = mfaRequest;
  static mfaVerify = mfaVerify;
  static openApprovalDialog = openApprovalDialog;
  static resolve = resolve;
}

// #endregion
