/** @format */

const [setBadge, clearBadge] =
  'setAppBadge' in navigator
    ? ['setAppBadge', 'clearAppBadge']
    : 'setClientBadge' in navigator
    ? ['setClientBadge', 'clearClientBadge']
    : [];
export class Badge {
  static async set(value?: number): Promise<void> {
    if (!setBadge) return Promise.reject(new DOMException('The operation is not supported.', 'NotSupportedError'));
    return navigator[setBadge](value);
  }

  static async clear(): Promise<void> {
    if (!clearBadge) return Promise.reject(new DOMException('The operation is not supported.', 'NotSupportedError'));
    return navigator[clearBadge]();
  }
}
