/** @format */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrgAnnouncement } from 'src/app/services/org/org-announcements.service';
import { StateService } from 'src/app/services/state.service';
import { StorageService } from 'src/app/services/storage.service';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarAnnouncementsDropdownComponent } from './toolbar-announcements-dropdown/toolbar-announcements-dropdown.component';
// icons
import icAnnouncement from '@iconify/icons-mdi/announcement';

@UntilDestroy()
@Component({
  selector: 'vex-toolbar-announcements',
  templateUrl: './toolbar-announcements.component.html',
  styleUrls: ['./toolbar-announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarAnnouncementsComponent implements OnInit {
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  icAnnouncement = icAnnouncement;

  dropdownOpen: boolean;

  private storage = new StorageService('announcements');
  private profileId: string;
  private announcements: OrgAnnouncement[];
  viewed: boolean;

  constructor(private popover: PopoverService, private cd: ChangeDetectorRef) {}

  private checkViewed() {
    const viewCache = (this.storage.getLocalData(this.profileId) || []) as number[];
    this.viewed =
      viewCache &&
      this.announcements &&
      !this.announcements.some((announcement) => !viewCache.includes(announcement.SystemMessageId));
    this.cd.markForCheck();
  }

  ngOnInit() {
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.profileId = user?.PersonId;
      this.checkViewed();
    });
    StateService.announcements$.pipe(untilDestroyed(this)).subscribe((announcements) => {
      this.announcements = announcements;
      this.checkViewed();
    });
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarAnnouncementsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
