/** @format */

const lCaseWords = {
  // articles
  a: 1,
  an: 1,
  the: 1,
  // prepositions
  for: 1,
  of: 1,
  on: 1,
  up: 1,
  // conjunctions
  and: 1,
  but: 1,
  nor: 1,
  or: 1,
  so: 1,
  yet: 1,
  // trailing possessive like Women's
  s: 1,
};

// these words should be upper case
const uCase = {
  // acronyms
  abc: 1,
  prc: 1,
  // roman numerals
  i: 1,
  ii: 1,
  iii: 1,
  iv: 1,
  v: 1,
  vi: 1,
  vii: 1,
  viii: 1,
  ix: 1,
  x: 1,
};

// process each word
const processWord = (lCase: string, index: number) => {
  const uCase = lCase.toUpperCase();
  // check predefined upper case list
  if (uCase[lCase]) return uCase;
  // check not at beginning and predefined lower case list
  if (index > 0 && lCaseWords[lCase]) return lCase;
  // return default casing
  return `${uCase.charAt(0)}${lCase.substring(1)}`;
};

export class StringUtil {
  static padToLength(value: number | string, char: string = '0', length: number = 2) {
    return `${value}`.padStart(length, char).slice(-length);
  }

  // attempts to title case string
  static toTitleCase(txt: string) {
    const lCase = txt?.toLowerCase() || '';
    const uCase = txt?.toUpperCase() || '';
    // if multi-case is present, assume correct
    if (txt !== lCase && txt !== uCase) return txt;
    return lCase.replace(/\w+/g, processWord);
  }

  // extract text content from html string
  static textFromHtml(html) {
    try {
      const dom = new DOMParser().parseFromString(html, 'text/html');
      const root = dom.documentElement;
      // strip non-content tags
      const codeTags = root.querySelectorAll('script, style');
      codeTags.forEach((tag) => tag.parentNode.removeChild(tag));
      // get the text
      const text = root.textContent || root.innerText;
      return text;
    } catch (ex) {
      return html;
    }
  }
}
