/** @format */

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { ArrayUtil } from '../shared/array-util';
import { DateUtil } from '../shared/date-util';
import { ObservableProvider, ObservableUtils } from '../shared/observable-utils';
import { Rest } from '../shared/rest';
import { ServiceLocator } from '../shared/service-locator';
import { OrgService } from './org.service';
import { StateService } from './state.service';

const urlCollection = {
  redeem: {
    url: (profileId: string) => `Organizations/${StateService.user?.OrganizationId}/Clients/${profileId}/RedeemRewards`,
    urlList: (profileId: string) => `Organizations/${organizationId}/Clients/${profileId}/RewardsSummary`,
    urlGet: (profileId: string, date: string) =>
      `Organizations/${StateService.user?.OrganizationId}/Clients/${profileId}/RedeemedRewardsForDate/${date}`,
  },
  addPoints: {
    url: (caseId: string) =>
      `Organizations/${StateService.user?.OrganizationId}/Clients/Case/${caseId}/CaseServiceUnitItem`,
  },
  getServiceUnitItems: {
    url: () => `Organizations/${StateService.user?.OrganizationId}/ServiceUnitItems`,
  },
};

export interface RewardRedeemRequest {
  PointsBalance: number; // 10
  dateRedeemed: Date;
  RedeemRewardTypes: RewardRedeemItem[];
  // Mapped
  DateRedeemed?: string; // '09/05/2023';
}

export interface RewardRedeemItem {
  Quantity: number; // '1';
  RewardTypeId: number; //'400';
  RewardTypeDescription: string; // 'Baby Crib';
  Value: number; // '10';
}

type RewardsEarnedDetail = {
  Activity: string;
  DateEarned: string;
  Points: number;
  name?: string;
  date?: Date;
};

type RewardsRedeemedDetail = {
  Reward: string;
  DateRedeemed: string;
  Points: number;
  name?: string;
  date?: Date;
};

export type RewardsEarned = {
  PointsBalance: number;
  RewardsEarned: RewardsEarnedDetail[];
};

export type RewardsRedeemed = {
  PointsBalance: number;
  RewardsRedeemed: RewardsRedeemedDetail[];
};

export type RewardsSummary = {
  PointsBalance: number;
  PointsEarned: number;
  PointsRedeemed: number;
};

export interface RewardsActivity {
  Quantity?: number; // null if earned, quantity if redeemed
  Points: number; // negative for redeemed, positive for earned
  // RewardsSummary
  Date: string; // 04/05/2022
  DateTime: string; // ISO8601 2022-04-05T14:45:26Z
  Type?: string; // Diapers, Daddy Class, etc... in requested lang
  // from RedeemedRewardsForDate
  RewardTypeId?: number;
  // mapped
  date?: Date;
}

export interface RewardsInfo {
  Balance: number;
  Earned: number;
  Redeemed: number;
  Activity: RewardsActivity[];
}

let clientLang = 'en';

const translocoPromise = ServiceLocator.get(TranslocoService);

const normalizeActivity = (item: RewardsActivity, date?: Date) =>
  Object.assign(item, {
    date: date || DateUtil.parseDate(item.Date),
  });

const normalizeRewardsInfo = (data: RewardsInfo) =>
  Object.assign(data, {
    Activity: data.Activity.map((item) => normalizeActivity(item)).sort(
      (a, b) => -ArrayUtil.compare(a.date, b.date) || ArrayUtil.compare(a.Type, b.Type)
    ),
  });

const normalizeDaySummary = (items: RewardsActivity[], date: Date) =>
  items.map((item) => normalizeActivity(item, date));

const normalizeRewardRequest = (data: RewardRedeemRequest) => ({
  ...data,
  DateRedeemed: DateUtil.formatDateEN(data.dateRedeemed),
  dateRedeemed: undefined,
});

const getOrgLangMatch = () => isSupported && organizationId && `${organizationId}-${clientLang}`;

const getPersonLangMatch = () => isSupported && organizationId && personId && `${personId}-${clientLang}`;

type Providers = Record<string, ObservableProvider>;

const providers: Providers = {
  summary: {
    bs: new BehaviorSubject<RewardsSummary>(null),
    match: getPersonLangMatch,
    url: () => urlCollection.redeem.urlList(personId),
  },
  info: {
    bs: new BehaviorSubject<RewardsInfo>(null),
    match: getPersonLangMatch,
    url: () => `Organizations/${organizationId}/Clients/${personId}/Rewards?lang=${clientLang}`,
    normalize: normalizeRewardsInfo,
  },
};

let personId: string;
let isClient: boolean;
let organizationId: string;
let isSupported: boolean;
let isSupported$ = new BehaviorSubject<boolean>(false);

// track authenticated user
StateService.user$.subscribe((user) => {
  if (!user) {
    personId = isClient = null;
    Object.values(providers).forEach(ObservableUtils.resetObservable);
    return;
  }
  personId = user.PersonId;
  isClient = user.isClient;
  Object.values(providers).forEach(ObservableUtils.refreshObservable);
});

// track authenticated user organization
OrgService.organization$.subscribe((org) => {
  if (!org) {
    organizationId = null;
    isSupported$.next(false);
    return;
  }
  organizationId = org.OrganizationId;
  isSupported$.next(org.TurnRewardsOn);
  Object.values(providers).forEach(ObservableUtils.refreshObservable);
});

// track active language
translocoPromise.then((translocoService) => {
  translocoService.langChanges$.subscribe((lang) => {
    clientLang = lang;
    Object.values(providers).forEach(ObservableUtils.refreshObservable);
  });
});

export class RewardsService {
  static info$ = ObservableUtils.getObservable<RewardsInfo>(providers.info);
  static isSupported$ = isSupported$.asObservable();
  static summary$ = ObservableUtils.getObservable<RewardsSummary>(providers.summary);

  static refreshInfo(profileId: string) {
    const id = `info.${profileId}`;
    if (!providers[id]) return;
    return ObservableUtils.refreshObservable(providers[id], true);
  }

  static getInfo$(profileId: string) {
    const id = `info.${profileId}`;
    const provider =
      providers[id] ||
      (providers[id] = {
        bs: new BehaviorSubject<RewardsInfo>(null),
        match: getOrgLangMatch,
        url: () => `Organizations/${organizationId}/Clients/${profileId}/Rewards?lang=${clientLang}`,
        normalize: normalizeRewardsInfo,
      });
    return ObservableUtils.getObservable<RewardsInfo>(provider);
  }

  static readonly get = async (profileId: string, date: Date): Promise<RewardsActivity[]> =>
    Rest.get<RewardsActivity[]>(
      urlCollection.redeem,
      [profileId],
      DateUtil.formatDateEN(date).replaceAll('/', '-'),
      (items) => normalizeDaySummary(items, date)
    );

  static readonly redeem = async (profileId: string, data: RewardRedeemRequest): Promise<any> =>
    Rest.putPost<RewardRedeemRequest>(urlCollection.redeem, [profileId], normalizeRewardRequest(data));

  static readonly update = async (profileId: string, data: RewardRedeemRequest): Promise<any> =>
    Rest.putPost<RewardRedeemRequest>(
      urlCollection.redeem,
      [profileId],
      normalizeRewardRequest(data),
      DateUtil.formatDateEN(data.dateRedeemed).replaceAll('/', '-')
    );

  static {
    this.isSupported$.subscribe((value) => {
      isSupported = value;
    });
  }
}
