/** @format */

import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, map, Observable } from 'rxjs';
import ptk from 'src/app/shared/ptk';

const MOCK_DATA = new HttpContextToken<boolean>(() => false);

type MockResponseOption = {
  match?: string;
  response: {
    status?: number;
    statusText?: string;
    body?: unknown;
  };
};

let requestCnt = 0;
const idRx = /[^\/]+\/(\d+\/|\d+$|[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/gi;
const replaceRx = /[a-z0-9]/gi;
const defaultResponse: MockResponseOption = {
  response: {
    status: 404,
    statusText: 'Not Found',
  },
};

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  static config({ enable = false }, context: HttpContext = new HttpContext()) {
    context.set(MOCK_DATA, enable);
    return context;
  }

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // short circuit if not needed
    if (!request.context.get(MOCK_DATA)) {
      return next.handle(request);
    }

    const requestId = requestCnt++;
    const urlNamedData = {};
    const urlData = [];
    const keywords = {
      urlNamedData: urlNamedData, // urlNamedData.Organizations === 'f8472681-6908-4127-adf9-fb967b886ced'
      urlData: urlData, // urlData[0] === 'f8472681-6908-4127-adf9-fb967b886ced'
    };

    // normalize ids
    const normalizedUrl = request.url.replace(idRx, (path) => {
      const [name, id] = path.split('/');
      urlData.push(id);
      urlNamedData[name] = id;
      const normalizedId = id.includes('-') ? id.replace(replaceRx, '0') : '0';
      return [name, normalizedId].join('/');
    });

    const mockRequest: HttpRequest<unknown> = request.clone({
      // update url per environment configuration
      method: 'GET',
      url: `mocks/${normalizedUrl}.json`,
    });

    console.log('mock request', {
      id: requestId,
      file: mockRequest.url,
      keywords,
      request,
    });

    return next.handle(mockRequest).pipe(
      map((event) => {
        if (event.type !== HttpEventType.Response) return event;
        const body = event.body || {};
        const mockOptions = body[request.method] || {};
        const { default: defaultOption = defaultResponse, ...otherOptions } = mockOptions;

        // find best response for given request else default
        const mockOption =
          Object.values(otherOptions).find((option: MockResponseOption) => {
            // use path toolkit to evaluate response validity
            return ptk.get(request, option.match, { defaultValue: false, keywords });
          }) || defaultOption;

        console.log('mock selected', {
          id: requestId,
          match: mockOption.match || 'default',
          response: mockOption.response,
        });

        const mockResponse = Object.assign({ status: 200 }, mockOption.response);

        // success status
        if (mockResponse.status < 400) {
          return new HttpResponse(mockResponse);
        }
        // failed status
        throw new HttpErrorResponse(Object.assign(mockResponse, { error: mockResponse.body, body: undefined }));
      }),
      delay(500)
    );
  }
}
