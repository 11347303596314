/**
 * TypeScript
 *
 * @format
 */

type Constructor<T = unknown> = new (...args: unknown[]) => T;
type OperationMethod = (a: unknown, b: unknown) => unknown;
type OperationValue = { value?: unknown; delete?: boolean };
type Operation = string | OperationValue;
type ObjectMap = Record<string, unknown>;
type RequestAPI = {
  next: () => string | undefined;
  peak: (this: Request, cnt: number) => string[];
  done: () => boolean;
  skip: (cnt: number) => void;
  prev: (cnt: number) => void;
  throw: (errorMsg: string, slice?: number) => Error;
};
type RequestProps = {
  keywords: ObjectMap;
  parts: string[];
  index: number;
  root: unknown;
  used: string[];
  value: unknown;
  params: unknown[];
};
type Request = RequestAPI & RequestProps;

function isConstructor(type: unknown): type is Constructor {
  return typeof type === 'function' && type.prototype && type.prototype.constructor;
}

/**
 * Keywords
 */
import envRoot from './global-this';
// props that need added to global keywords
const specials = { false: false, null: null, true: true };
// global props to exclude
const envRootBlacklist: ObjectMap = { eval: 1, global: 1, globalThis: 1, GLOBAL: 1, root: 1, console: 1 };
// place holder for keywords
const globalKeywords: ObjectMap = {};

/**
 * Helpers
 */
const { hasOwnProperty, toString, propertyIsEnumerable } = Object.prototype;
// regex for extracting actionable parts from a keypath
// / identifiers | numbers | operations | other /
// e.g. 'parseInt(b[4],10)' --> ["parseInt", "(", "b", "[", "4", "]", ",", "10", ")"]
const rxParse = /[a-z$_][a-z0-9$_]*|[0-9]+|[!<=>|&+-]+|./gi;
const rxParam = /^\$([0-9]+)$/;
// special characters not handled by resolve method
const rxUnsupported = /[^a-z0-9$_]/i;
// parts that can appear as a prefix
const prefix: ObjectMap = { '+': 1, '-': 1, '!!': 1, '!': 1, '~': 1, typeof: 1, void: 1 };
// flag used to apply continue logic
const andOr: ObjectMap = { '||': 0, '&&': 1 };
// internal object ref that allows the code to know that a value was not provided by the caller
const unset = {};

const partsAPI: RequestAPI = {
  next: function (this: Request) {
    return this.parts[this.index++];
  },
  prev: function (this: Request, cnt: number) {
    this.index -= cnt;
  },
  peak: function (this: Request, cnt: number) {
    return this.parts.slice(this.index, this.index + cnt);
  },
  skip: function (this: Request, cnt: number) {
    this.index += cnt;
  },
  done: function (this: Request) {
    return this.parts.length <= this.index;
  },
  throw: function (this: Request, errorMsg: string, slice = 0): Error {
    return new Error(
      `${errorMsg} at char ${this.parts.slice(0, this.index - slice).join('').length} ${this.parts.join('')}`
    );
  },
};

// initialize properties for each new request
function initRequest(context: unknown, path: string, keywords: ObjectMap, params: unknown[], value: unknown): Request {
  const request: Request = Object.create(partsAPI);
  request.keywords = keywords === unset ? globalKeywords : Object.assign(Object.create(null), globalKeywords, keywords);
  request.index = 0;
  request.params = params;
  request.parts = path.match(rxParse) || [];
  request.root = context;
  request.used = [];
  request.value = value;
  return request;
}

function xor(a: unknown, b: unknown) {
  return a ? !b : b;
}

function resolveTemplateLiteral(request: Request): unknown {
  let part;
  let escape;
  let expression = false;
  let literal = '';
  while ((part = request.next())) {
    if (escape) {
      literal += part;
      escape = false;
      continue;
    }
    if (expression && part === '{') {
      // drop opening dollar sign $ and resolve expression
      literal = literal.slice(0, -1) + resolve(request, request.root);
      // skip closing bracket }
      request.skip(1);
      // reset expression flag
      expression = false;
      continue;
    }
    expression = part.endsWith('$');
    switch (part) {
      case '\\':
        escape = true;
        continue;
      case '`':
        return literal;
      default:
        literal += part;
    }
  }
  throw request.throw('Missing closing backtick (`)');
}

function resolveString(request: Request, quote: string): string {
  let part;
  let escape;
  let literal = '';
  while ((part = request.next())) {
    if (escape) {
      literal += part;
      escape = false;
      continue;
    }
    switch (part) {
      case '\\':
        escape = true;
        continue;
      case quote:
        return literal;
      default:
        literal += part;
    }
  }
  throw request.throw(`Missing closing quote (${quote})`);
}

function resolveArgs(request: Request, context: unknown): unknown[] {
  let part;
  const params: unknown[] = [];
  while ((part = request.next())) {
    switch (part) {
      case ' ':
      case ',':
        continue;
      case ')':
        return params;
      default:
        request.prev(1);
        params.push(resolve(request, context));
    }
  }
  throw request.throw('Missing closing parenthesis ")"');
}

function resolveProp(request: Request, context: unknown): string {
  let part, prop;
  while ((part = request.next())) {
    switch (part) {
      case ' ':
        continue;
      case ']':
        return prop as string;
      default:
        request.prev(1);
        prop = resolve(request, context);
    }
  }
  throw request.throw('Missing closing bracket "]"');
}

function resolveParam(request: Request, param: string): unknown {
  const index: number = parseInt(param.split('$')[1]);
  return index in request.params ? request.params[index] : unset;
}

function resolveObject(request: Request, context: unknown): ObjectMap {
  const obj: ObjectMap = {};
  let part;
  let name: unknown = unset;
  let value: unknown = unset;
  function shorthand() {
    if (name !== unset && value === unset) {
      // back up to the property name
      request.prev(2);
      // resolve property name as value
      obj[name as string] = resolve(request, context);
      // skip the comma we just processed
      request.skip(1);
    }
  }
  while ((part = request.next())) {
    switch (part) {
      case ']': // we can ignore closing bracket in {[foo]}
      case ' ': // ignore extra spaces
        continue;
      // string literals
      case "'":
      case '"':
        request.prev(1);
        name = resolve(request, context);
        continue;
      // dynamic property names (ES2015)
      case '[':
        name = resolve(request, context);
        continue;
      // value
      case ':':
        if (name === unset) {
          throw request.throw('Unexpected token ":"');
        }
        obj[name as string] = value = resolve(request, context);
        continue;
      // next property
      case ',':
        if (name === unset) {
          throw request.throw('Unexpected token ","');
        }
        // check for shorthand property name {propName,...}
        shorthand();
        // reset
        name = value = unset;
        continue;
      // done
      case '}':
        // check for shorthand property name {propName}
        shorthand();
        // done
        return obj;
      default:
        // check for unexpected tokens in property name (?, |, &, =, etc...)
        if (rxUnsupported.test(part)) {
          throw request.throw(`Unexpected token "${part}"`);
        }
        name = part;
    }
  }
  throw request.throw('Missing closing brace "}"');
}

function resolveArray(request: Request, context: unknown): unknown[] {
  let part;
  const arr = [];
  while ((part = request.next())) {
    switch (part) {
      case ' ':
        continue;
      case ']':
        return arr;
      case ',':
        arr.push(resolve(request, context));
        continue;
      default:
        request.prev(1);
        arr.push(resolve(request, context));
    }
  }
  throw request.throw('Missing closing bracket "]"');
}

function resolveOperations(operations: Operation[]) {
  const order = [
    { in: 1, instanceof: 1, typeof: 1, void: 1 },
    { '*': 1, '/': 1, '%': 1 },
    { '+': 1, '-': 1 },
    { '<<': 1, '>>': 1, '>>>': 1 },
    { '<': 1, '>': 1, '<=': 1, '>=': 1 },
    { '==': 1, '!=': 1, '===': 1, '!==': 1 },
    { '&': 1 },
    { '^': 1 },
    { '|': 1 },
    { '&&': 1 },
    { '||': 1 },
  ];
  const operatorMethods: Record<string, OperationMethod> = {
    in: (a, b) => (a as string) in (b as ObjectMap),
    instanceof: (a, b) => a instanceof (b as Constructor),
    typeof: (a, b) => typeof b,
    void: (a, b) => void b,
    '-': (a, b) => (a as number) - (b as number),
    '!': (a, b) => !b,
    '!!': (a, b) => !!b,
    '!=': (a, b) => a != b,
    '!==': (a, b) => a !== b,
    '*': (a, b) => (a as number) * (b as number),
    '/': (a, b) => (a as number) / (b as number),
    '&': (a, b) => (a as number) & (b as number),
    '&&': (a, b) => a && b,
    '%': (a, b) => (a as number) % (b as number),
    '^': (a, b) => (a as number) ^ (b as number),
    '+': (a, b) => (a as number) + (b as number),
    '<': (a, b) => (a as number) < (b as number),
    '<<': (a, b) => (a as number) << (b as number),
    '<=': (a, b) => (a as number) <= (b as number),
    '==': (a, b) => a == b,
    '===': (a, b) => a === b,
    '>': (a, b) => (a as number) > (b as number),
    '>=': (a, b) => (a as number) >= (b as number),
    '>>': (a, b) => (a as number) >> (b as number),
    '>>>': (a, b) => (a as number) >>> (b as number),
    '|': (a, b) => (a as number) | (b as number),
    '||': (a, b) => a || b,
    '~': (a, b) => ~(b as number),
  };
  // console.log(JSON.stringify(operations));
  // merge number sign with values
  let x = operations.length;
  while (x--) {
    if (prefix[operations[x] as string] && (!x || operatorMethods[operations[x - 1] as string])) {
      operations.splice(x, 2, {
        value: operatorMethods[operations[x] as string](0, (operations[x + 1] as OperationValue).value),
      });
    }
  }
  // process operators by order of operation (supports short circuit logic)
  order.some((operators) => {
    while (
      operations.length > 1 &&
      operations.some((value, index) => {
        if (!((value as string) in operators)) {
          return false;
        }
        // merge the 3 parts of this expression into 1 (e.g. [2, +, 3] -> [5])
        const result = operatorMethods[value as string](
          (operations[index - 1] as OperationValue).value,
          (operations[index + 1] as OperationValue).value
        );
        return operations.splice(index - 1, 3, { value: result });
      })
    ) {
      /* noop */
    }
    // short circuit loop if we have tested all ordered operators
    return operations.length <= 1;
  });
  return (operations[0] as OperationValue).value;
}

function isNumber(string: string): boolean {
  return !isNaN(Number(string));
}

function resolve(request: Request, context: unknown, operation = 'g') {
  const { root } = request;
  let part;
  let cnt = 0;
  let prevContext: ObjectMap = {};
  let propName: string | undefined;
  let isFirst;
  let expectConstructor;
  let trailingSpace;
  let currentContext = context;
  const operations: Operation[] = [];

  function getProp(name: string): unknown {
    propName = name;
    prevContext = currentContext as ObjectMap;
    if (operation === 'g') {
      // return value on property whether exists or not
      return prevContext != null ? prevContext[propName] : undefined;
    } else if (propName in prevContext) {
      // return existing property
      return prevContext[propName];
    } else {
      // make property an empty object and return it
      return (prevContext[propName] = {});
    }
  }

  function done(result: unknown) {
    // console.log('< resolve', root, context, parts, result);
    if (operation === 'g') {
      // return resolved value
      return operations.length ? operations.push({ value: result }) && resolveOperations(operations) : result;
    }
    if (!prevContext || !propName) {
      return false;
    }
    if (operation === 'd') {
      // delete property
      delete prevContext[propName];
    } else {
      // set property value
      prevContext[propName] = request.value;
    }
    return true;
  }

  const otherFirst: Record<string, () => void> = {
    new: function () {
      expectConstructor = true;
      cnt--;
    },
    delete: function () {
      currentContext = resolve(request, root, 'd');
    },
  };

  // console.log('> resolve', root, context, parts);
  while (++cnt && (part = request.next())) {
    propName = undefined;
    isFirst = cnt === 1;

    // console.log('- resolve', part, currentContext);
    switch (part) {
      // treat spaces as if they didn't exist
      case ' ':
        cnt--;
        trailingSpace = !isFirst;
        continue;
      // just a break token
      case '.':
        continue;
      // defer to other resolvers to handle these
      case "'":
      case '"':
        currentContext = resolveString(request, part);
        continue;
      case '`':
        currentContext = resolveTemplateLiteral(request);
        continue;
      case '{':
        currentContext = resolveObject(request, root);
        continue;
      case '(':
        if (isFirst) {
          currentContext = resolve(request, root);
          if (request.next() !== ')') {
            throw request.throw('Missing closing parenthesis ")"');
          }
          continue;
        }
        if (expectConstructor) {
          if (!isConstructor(currentContext)) {
            throw request.throw('Not a constructor', 1);
          }
          // execute as constructor
          currentContext = new currentContext(...resolveArgs(request, root));
          expectConstructor = false;
          continue;
        }
        if (typeof currentContext !== 'function') {
          throw request.throw('Not a function');
        }
        // execute as function
        currentContext = currentContext.apply(prevContext, resolveArgs(request, root));
        continue;
      case '[':
        currentContext = isFirst
          ? resolveArray(request, root) // array initializer
          : getProp(resolveProp(request, root)); // property accessor
        continue;
      // operators
      case '||':
      case '&&':
      case 'in':
      case 'instanceof':
      case 'typeof':
      case 'void':
      case '-':
      case '!':
      case '!!':
      case '!=':
      case '!==':
      case '*':
      case '/':
      case '&':
      case '%':
      case '^':
      case '+':
      case '<':
      case '<<':
      case '<=':
      case '==':
      case '===':
      case '>':
      case '>=':
      case '>>':
      case '>>>':
      case '|':
      case '~':
        if (isFirst && !prefix[part]) {
          throw request.throw(`Unexpected token "${part}"`);
        }
        operations.push(...(isFirst ? [part] : [{ value: currentContext }, part]));
        // reset context
        currentContext = root;
        cnt = 0;
        continue;
      // tokens used by other resolvers (add back into parts and return)
      case ',':
      case ':':
      case ')':
      case ']':
      case '}':
        // we should not get these tokens initially
        if (isFirst) {
          throw request.throw(`Unexpected token "${part}"`);
        }
        request.prev(1);
        return done(currentContext);
      // ooh, something for us to resolve
      default:
        // check for unsupported tokens (?, |, &, =, ...)
        if (part.length === 1 && rxUnsupported.test(part)) {
          throw request.throw(`Unsupported token "${part}"`);
        }
        // check for params ($1, $2, ...)
        if (part.length > 1 && rxParam.test(part)) {
          const result = resolveParam(request, part);
          if (result !== unset) {
            currentContext = result;
            continue;
          }
        }
        // support keywords
        if (isFirst && hasOwnProperty.call(request.keywords, part)) {
          currentContext = request.keywords[part];
          continue;
        }
        // support new, delete, void, typeof
        if (isFirst && otherFirst[part] && request.peak(1)[0] === ' ') {
          request.skip(1);
          otherFirst[part]();
          continue;
        }
        // support numbers (int, float)
        if (isFirst && isNumber(part)) {
          // check if the next 2 items are the decimal part of the number
          const [period, decimal] = request.peak(2);
          if (period === '.' && isNumber(decimal)) {
            // it's a float
            currentContext = parseFloat(`${part}.${decimal}`);
            // skip used parts (.<number>)
            request.skip(2);
          } else {
            // must be an integer
            currentContext = parseInt(part, 10);
          }
          continue;
        }
        if (trailingSpace) {
          throw request.throw(`Unexpected token "${part}"`);
        }
        currentContext = getProp(part);
    }
  }
  return done(currentContext);
}

/**
 * Keypath
 */

export type PTKOptions = {
  defaultValue?: unknown;
  keywords?: ObjectMap;
  params?: unknown[];
};

// gets the value at the specific path of the provided object
function get(context: unknown, path: string, options: PTKOptions = {}): unknown {
  const { defaultValue, keywords = unset, params = [] } = options;
  const request = initRequest(context, path, keywords, params, unset);
  const result = resolve(request, context);
  if (!request.done()) {
    throw request.throw(`Unexpected token "${request.parts[request.index]}"`);
  }
  return result === undefined ? defaultValue : result;
}

// sets the value at the specific path of the provided object
function set(context: unknown, path: string, value: unknown, options: PTKOptions = {}): boolean {
  const { keywords = unset, params = [] } = options;
  const request = initRequest(context, path, keywords, params, value);
  const result = resolve(request, context, 's');
  if (!request.done()) {
    throw request.throw(`Unexpected token "${request.parts[request.index]}"`);
  }
  return !!result;
}

// deletes the property at the specific path of the provided object
function deleteProp(context: unknown, path: string, options: PTKOptions = {}): boolean {
  const { keywords = unset, params = [] } = options;
  const request = initRequest(context, path, keywords, params, unset);
  resolve(request, context, 'd');
  if (!request.done()) {
    throw request.throw(`Unexpected token "${request.parts[request.index]}"`);
  }
  return true;
}

// returns a string that will be parsed as a single token by the path resolver
function escape(string: string): string {
  return `"${string.replace(/"/g, '\\"')}"`;
}

/**
 * Keywords
 */

// removes all keyword definitions
function deleteKeyword(): boolean;
// removes the specified keyword definition
function deleteKeyword(key: string): boolean;
// removes multiple specified keyword definitions
function deleteKeyword(key: string[]): boolean;
function deleteKeyword(key?: unknown): boolean {
  const type = toString.call(key);
  switch (type) {
    case '[object String]':
      delete globalKeywords[key as string];
      break;
    case '[object Array]':
      (key as string[]).forEach((key) => {
        delete globalKeywords[key];
      });
      break;
    case '[object Undefined]':
      Object.keys(globalKeywords).forEach((key) => {
        delete globalKeywords[key];
      });
      break;
    default:
      throw new Error(`Unsupported keyword identifier type ${type}`);
  }
  return true;
}

// Returns a specified keyword value.
function getKeyword(key: string): unknown {
  return globalKeywords[key];
}

// gets the names of all keyword definitions
function getKeywords(): string[] {
  return Object.keys(globalKeywords);
}

// Returns a boolean indicating whether an keyword is defined.
function hasKeyword(key: string): boolean {
  return hasOwnProperty.call(globalKeywords, key);
}

// adds all default keyword definitions
function setKeyword(): boolean;
// adds single keyword definition
function setKeyword(key: string, value: unknown): boolean;
// adds multiple keyword definitions
function setKeyword(map: ObjectMap): boolean;
function setKeyword(key?: unknown, value?: unknown): boolean {
  const type = toString.call(key);
  switch (type) {
    case '[object String]':
      globalKeywords[key as string] = value;
      break;
    case '[object Object]':
      Object.entries(key as ObjectMap).forEach(([objectKey, objectValue]) => {
        globalKeywords[objectKey] = objectValue;
      });
      break;
    case '[object Undefined]':
      Object.assign(
        globalKeywords,
        specials, // missing stuff
        Object.getOwnPropertyNames(envRoot) // non-enumerable root props
          .filter((objectKey) => {
            return !envRootBlacklist[objectKey] && !propertyIsEnumerable.call(envRoot, objectKey);
          })
          .reduce((result: ObjectMap, objectKey) => {
            result[objectKey] = envRoot[objectKey];
            return result;
          }, {})
      );
      break;
    default:
      throw new Error(`Unsupported keyword definition type ${type}`);
  }
  return true;
}

// initialize default keywords
setKeyword();

const api = {
  get,
  set,
  delete: deleteProp,
  // find,
  // findAll
  // mask
  escape,
  getKeyword,
  setKeyword,
  hasKeyword,
  deleteKeyword,
  getKeywords,
};

export {
    api as default,
    get,
    set,
    deleteProp as delete,
    escape,
    getKeyword,
    setKeyword,
    hasKeyword,
    deleteKeyword,
    getKeywords,
};
