/** @format */

import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/@vex/services/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { AuthUser } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
// icons
import icLanguage from '@iconify/icons-ic/language';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import icDescription from '@iconify/icons-ic/twotone-description';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAnnouncement from '@iconify/icons-mdi/announcement';

@UntilDestroy()
@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input()
  mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  hasBadge = false;
  canClientSearch: boolean;

  user: AuthUser;
  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map((config) => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map((config) => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map((config) => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map((config) => config.navbar.position === 'below-toolbar'));

  icAnnouncement = icAnnouncement;
  icArrowDropDown = icArrowDropDown;
  icAssignment = icAssignment;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icBookmarks = icBookmarks;
  icDescription = icDescription;
  icDoneAll = icDoneAll;
  icLanguage = icLanguage;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icReceipt = icReceipt;
  icSearch = icSearch;

  langs: AvailableLangs;
  hasAnnouncements: boolean;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private translocoService: TranslocoService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.langs = this.translocoService.getAvailableLangs();
    this.navigationService.badgeChange$.pipe(untilDestroyed(this)).subscribe((hasBadge) => {
      this.hasBadge = hasBadge;
      this.cd.markForCheck();
    });
    StateService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.cd.markForCheck();
    });
    StateService.authorizations$.pipe(untilDestroyed(this)).subscribe((auth) => {
      this.canClientSearch = ['APRSMS.All', 'APRIntake.All'].some((name) => auth[name]) || auth['Client.Read'];
      this.cd.markForCheck();
    });
    StateService.announcements$.pipe(untilDestroyed(this)).subscribe((announcements) => {
      this.hasAnnouncements = announcements?.length > 0;
      this.cd.markForCheck();
    });
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openLeftDrawer() {
    this.layoutService.openLeftDrawer();
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  setLang(lang: string) {
    this.translocoService.setActiveLang(lang);
  }
}
