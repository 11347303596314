/** @format */

import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ServiceLocator } from '../shared/service-locator';

const dialogPromise = ServiceLocator.get(MatDialog);

@Injectable({
  providedIn: 'root',
})
export class LazyDialogService {
  constructor() {}

  static async open<C = unknown, D = any, R = any>(
    dialogName: string,
    dialogConfig: MatDialogConfig<D>
  ): Promise<MatDialogRef<C, R>> {
    const dialog = await dialogPromise;
    const chunk = await import(`../dialogs/${dialogName}/${dialogName}.component`);
    // assume first export is the requested dialog component (ts types and interfaces are ignored)
    const dialogComponent = Object.values(chunk)[0] as ComponentType<C>;
    return dialog.open<C, D, R>(dialogComponent, dialogConfig);
  }
}
