/** @format */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { environment } from 'src/environments/environment';
import { SnackService } from './snack.service';
import { StateService } from './state.service';
import { StorageService } from './storage.service';

export type PushState = 'denied' | 'disabled' | 'updating' | 'enabled';
export type PushServiceResponse = { status: 'ERROR' | 'SUCCESS' | 'UPDATED' };

const storage = new StorageService('push');

const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);

const state = new BehaviorSubject<PushState>(null);

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  get denied() {
    return 'Notification' in window && Notification.permission === 'denied';
  }

  get enabled(): boolean {
    return storage.getLocalData('enabled') as boolean;
  }
  private set enabled(value: boolean) {
    storage.setLocalData('enabled', value);
    this.updateState();
  }

  get supported() {
    return 'Notification' in window && this.swPush.isEnabled && !StateService.isAuthTransfer;
  }

  readonly state$ = state.asObservable();

  private subscription: PushSubscriptionJSON;

  private updateState() {
    const currentState: PushState = this.denied ? 'denied' : this.enabled ? 'enabled' : 'disabled';
    state.next(currentState);
  }

  private processClick({ action, notification }) {
    // console.log('processClick', action, notification);
    const onActionClick = notification?.data?.onActionClick;
    const route = {
      authReset: `/auth/reset-password/${onActionClick?.authReset?.id}`,
      calendar: '/calendar',
      chat: '/chat',
      intakeForms: '/intakeforms',
      photos: '/files/media',
      rewards: '/rewards',
      video: `/video/room/${onActionClick?.video?.id}`,
    }[action];
    if (route) {
      this.router.navigateByUrl(route);
    }
  }

  private async processAuth(user) {
    if (!user) return;
    // register deviceId with server
    const response = this.http.post<PushServiceResponse>(
      `Organizations/${user.OrganizationId}/notifications/register`,
      { personId: user.PersonId, deviceId: StateService.deviceId },
      { context }
    );
    try {
      await firstValueFrom(response);
    } catch (ex) {
      console.warn(ex);
    }
    // try to subscribe/update subscription if allowed
    if (this.supported && !this.denied && this.enabled !== false) {
      this.subscribe(true);
    }
  }

  constructor(private http: HttpClient, private router: Router, private swPush: SwPush) {
    this.updateState();

    // listen for user logins, and register deviceId with server
    StateService.user$.subscribe(this.processAuth.bind(this));

    this.swPush.subscription.subscribe(async (subscription) => {
      this.subscription = subscription?.toJSON();
      // console.log('subscription', JSON.stringify(this.subscription, null, 2));
    });

    this.swPush.notificationClicks.subscribe(this.processClick.bind(this));
  }

  async subscribe(quiet?: boolean) {
    state.next('updating');
    try {
      // get subscription
      await this.swPush.requestSubscription({
        serverPublicKey: environment.apiKeys.vapid,
      });
      const response = this.http.put<PushServiceResponse>(
        `notifications/deviceId/${StateService.deviceId}`,
        this.subscription,
        {
          context,
        }
      );
      const data = await firstValueFrom(response);
      if (data.status !== 'SUCCESS') {
        throw new Error('unable to register push subscription');
      }
      this.enabled = true;
    } catch (ex) {
      console.warn(ex);
      this.enabled = false;
      if (quiet) {
        return;
      }
      SnackService.warn('push.error');
    }
  }

  async unsubscribe() {
    state.next('updating');
    const response = this.http.delete<PushServiceResponse>(`notifications/deviceId/${StateService.deviceId}`, {
      context,
    });
    try {
      await firstValueFrom(response);
      await this.swPush.unsubscribe();
      this.enabled = false;
    } catch (ex) {
      console.warn(ex);
      this.enabled = true;
      SnackService.warn('push.error');
    }
  }
}
