/**
 * This module can be used to configure multiple interceptors for an http request
 * and return a context with all the required tokens applied.
 * This can be used with any interceptor that has a static config method. (see Configurable type below)
 * Example:
 *   const context = InterceptorContext.get([
 *     { interceptor: ApiInterceptor, config: { enable: true } },
 *     { interceptor: CacheInterceptor, config: { ignore: true } },
 *     { interceptor: MockInterceptor, config: { dataSet: '2022/01/01' } }
 *   ]);
 */

import { HttpContext } from "@angular/common/http";

export type Configurable = {
    config: (config: Record<string, unknown>, context?: HttpContext) => HttpContext
}

export type InterceptorConfig = {
    interceptor: Configurable,
    config: Record<string, unknown>
};

export class InterceptorContext {
    static get(
        interceptors: InterceptorConfig[],
        context: HttpContext = new HttpContext()
    ) {
        return interceptors.reduce((result, config) => {
            return config.interceptor.config(config.config, result);
        }, context);
    }
}
