/** @format */

import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormControlWarn extends FormControl {
  warnings: any;
}

export class CustomValidators {
  // proper email validation regex
  static email = this.pattern(/^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$/i, { format: true });
  static zipCode = this.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/i, { format: true });
  static pin = this.pattern(/^[0-9]{4}$/i, { format: true });
  static phone = this.pattern(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i, { format: true });

  static pattern(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // if control is empty return no error
      if (!control.value) return null;
      // test the value of the control against the regexp supplied
      const invalid = !regex.test(control.value);
      // if invalid, return error object, else null
      return invalid ? error : null;
    };
  }

  static unique(sourcePath: string, targetPath: string) {
    return (control: AbstractControl) => {
      const sourceValue: string = control.get(sourcePath).value;
      const targetValue: string = control.get(targetPath).value;
      // compare if the values math
      if (sourceValue === targetValue) {
        // if they match, set an error in our target form control
        control.get(targetPath).setErrors({ duplicate: true });
      }
    };
  }

  static requiredWarn(control: FormControlWarn) {
    const { required = true, ...warnings } = control.warnings || {};
    if (!control.value) {
      Object.assign(warnings, { required });
    }
    Object.assign(control, { warnings });
    return null;
  }

  static match(passwordPath: string, confirmPath: string) {
    return ((control: AbstractControl) => {
      const password: string = control.get(passwordPath).value; // get password from our password form control
      const confirmPassword: string = control.get(confirmPath).value; // get password from our confirmPassword form control
      // compare is the password match
      if (password !== confirmPassword) {
        // if they don't match, set an error in our confirm form control
        control.get(confirmPath).setErrors({ Mismatch: true });
      }
    }) as ValidatorFn;
  }

  // TODO: look at https://stackoverflow.com/questions/62961841/date-validator-in-angular-8-to-compare-two-dates
  static onOrAfter(firstDatePath: string, secondDatePath: string) {
    return ((control: AbstractControl) => {
      const firstDate: Date = control.get(firstDatePath).value;
      const secondDate: Date = control.get(secondDatePath).value;
      if (firstDate > secondDate) {
        control.get(secondDatePath).setErrors({ OutOfSequence: true });
      }
    }) as ValidatorFn;
  }
}
