/** @format */

import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AuthUser } from 'src/app/services/auth.service';

// @UntilDestroy()
@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() customTemplate: TemplateRef<any>;

  public user: AuthUser;
  public year = new Date().getFullYear();

  constructor() // private http: HttpClient
  {}

  ngOnInit() {
    // StateService.user$.pipe(
    //   untilDestroyed(this)
    // ).subscribe((user) => {
    //   if (!user) { return; }
    //   this.user = user;
    // });
  }

  ngOnDestroy(): void {}
}
