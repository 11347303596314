/** @format */

import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ArrayUtil } from 'src/app/shared/array-util';
import { Link } from '../../shared/link';
import { ObservableManager } from '../../shared/observable-utils';
import { Rest } from '../../shared/rest';
import { AuthUser } from '../auth.service';
import { CommonBase, CommonType, normalizeCommonBase, removeCommonBase } from '../common.service';
import { AddressBase } from '../location.service';
import { AuthRequire, StateService } from '../state.service';

const urlCollection = {
  url: (orgId: string) => `Organizations/${orgId || StateService.user.OrganizationId}/Locations`,
  urlToggleActive: (orgId: string) =>
    `Organizations/${orgId || StateService.user.OrganizationId}/Locations/ToggleActiveStatus`
};

// #region TYPE DEFINITIONS

export interface OrgLocation extends AddressBase, CommonBase {
  AddressId: number;
  IsDefault: boolean;
  Latitude: number;
  LocationId: number;
  LocationType: CommonType;
  LocationTypeId: number;
  Longitude: number;
  Name: string;
  OrganizationId: string;
  Phone: string;
  // mapped
  addressLink: SafeUrl;
  distance?: number;
  phoneLink: SafeUrl;
  SortOrder: number;
}

// #endregion

// #region PRIVATE

// locations
export const normalizeOrgLocation = (item: OrgLocation): OrgLocation =>
  Object.assign(normalizeCommonBase(item), {
    addressLink: Link.fromAddress(item.Address),
    Name: item.Name.replace(' (Inactive)', ''),
    phoneLink: Link.fromPhone(item.Phone),
  });

const normalizeList = (items: OrgLocation[]): OrgLocation[] =>
  items
    .map((item) => normalizeOrgLocation(item))
    .sort((a, b) => -ArrayUtil.compare(a.isActive, b.isActive) || ArrayUtil.compare(a.Name, b.Name));

const normalizeOut = (item: OrgLocation): OrgLocation =>
  Object.assign(removeCommonBase(item), {
    OrganizationId: StateService.user.OrganizationId,
    addressLink: undefined,
    distance: undefined,
    phoneLink: undefined,
  });

// #endregion

export const getOrgLocationName = (list: OrgLocation[], id: number): string =>
  list?.find((item) => item.LocationId === id)?.Name;

export class OrgLocationService {
  private static getName = (orgId: string) => `orgLocations/${orgId || StateService.user.OrganizationId}`;

  //@AuthRequire open to all
  static readonly list = async (orgId?: string): Promise<OrgLocation[]> =>
    Rest.get<OrgLocation[]>(urlCollection, [orgId], {
      normalize: normalizeList,
      manager: this.getName(orgId),
      params: { activeOnly: false },
    });

  //@AuthRequire open to all
  static readonly list$ = (orgId?: string): Observable<OrgLocation[]> =>
    ObservableManager.init<OrgLocation[]>(this.getName(orgId), () => this.list(orgId));

  // output the locations for the org of the current authenticated user
  static readonly current$ = ObservableManager.initTrack<OrgLocation[], AuthUser>(
    StateService.user$,
    (user) => this.getName(user?.OrganizationId),
    (user) => this.list(user?.OrganizationId)
  );

  @AuthRequire('Client.Read', 'OptionLine.All', 'Donor.All', 'ClientFacingApplication.Read')
  static readonly get = async (id: number, orgId?: string): Promise<OrgLocation> =>
    Rest.get<OrgLocation>(urlCollection, [orgId], id, normalizeOrgLocation);

  static readonly set = async (data: OrgLocation, orgId?: string) =>  {
    Rest.putPost<OrgLocation>(urlCollection, [orgId], normalizeOut(data), data.LocationId, {
      manager: this.getName(orgId),
    });
  }
  
  @AuthRequire('Application.Admin')
  static readonly toggleActive = async (id: number, orgId?: string): Promise<any> =>
    Rest.putPost<any>({ url: urlCollection.urlToggleActive }, [orgId], {}, id, { manager: this.getName(orgId) });
}
