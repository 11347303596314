/** @format */

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, startWith } from 'rxjs/operators';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';
import { ConfigService } from 'src/@vex/services/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { checkRouterChildsData } from 'src/@vex/utils/check-router-childs-data';
import { StateService } from 'src/app/services/state.service';

@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
})
export class CustomLayoutComponent implements OnInit, OnDestroy {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;
  isFocusedMode = StateService.isFocusedMode;

  isFooterVisible$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(this.router.routerState.root.snapshot, (data) =>
        'footerVisible' in data ? data.footerVisible : this.footerVisibleDefault
      )
    )
  );

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.toolbarShadowEnabled
      )
    )
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  private footerVisibleDefault: boolean;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.configService.config$
      .pipe(
        untilDestroyed(this),
        map((config) => config.footer.visible)
      )
      .subscribe((footerVisible) => {
        this.footerVisibleDefault = footerVisible;
      });

    // this.layoutService.configpanelOpen$.pipe(
    //   untilDestroyed(this)
    // ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }

  ngOnDestroy(): void {}
}
