/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';

let tacAccepted: boolean = false;

StateService.user$.subscribe((user) => {
  // skip terms and conditions for now if staff
  tacAccepted = user?.TermsAndConditionsAccepted || !user?.isClient;
});

@Injectable({
  providedIn: 'root',
})
export class AgreementsGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (tacAccepted) return true;
    // save where we were heading
    AuthService.returnPath = state.url;
    // redirect to tac
    return this.router.parseUrl('agreements/tac');
  }
}
