<!-- @format -->

<div class="dropdown flex-col" *transloco="let t">
  <!-- HEADER -->
  <div class="flex bg-primary text-primary-contrast p-3" [class.items-center]="user.isClient" [class.items-start]="!user.isClient">
    <div *ngIf="!photoURL" class="dropdown-heading-icon rounded-full mr-3 p-2">
      <ic-icon size="32" [icIcon]="icClient"></ic-icon>
    </div>
    <img *ngIf="photoURL" [src]="photoURL" alt="profile picture" class="h-12 w-12 mr-3 rounded-full bg-white" />
    <div class="flex-col dropdown-heading ellipsis-container">
      <div class="title">{{ user.fullName }}</div>
      <div *ngIf="!user.isClient" class="caption">{{ user.Role }}</div>
      <div *ngIf="!user.isClient" class="caption">{{ user.Location }}</div>
    </div>
  </div>
  <!-- LIST -->
  <div class="dropdown-content">
    <a *ngFor="let item of items" (click)="close()" [routerLink]="item.route" class="flex flex-auto items-center notification ellipsis-container" matRipple>
      <mat-icon [icIcon]="item.icon" [ngClass]="item.colorClass || 'text-primary'" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon *ngIf="item.route" [icIcon]="icChevronRight" class="notification-chevron flex-none"></mat-icon>
    </a>
    <div class="mx-6 px-1 py-3">
      <mat-checkbox [(ngModel)]="isHighContrast" vexPristine>{{ t('user.highContrast') }}</mat-checkbox>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="flex justify-between dropdown-footer">
    <a *ngIf="profiles.length > 1" (click)="change()" color="primary" mat-button aria-label="change profile">{{ t('auth.change') }}</a>
    <a (click)="logout()" color="primary" mat-button aria-label="logout">{{ t('auth.logout') }}</a>
  </div>
</div>
