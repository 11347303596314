/** @format */

import { Inject, Injectable } from '@angular/core';

const defaultData = '{}';

// fallback if storage is not available
const memoryCache = {};
const memoryStorage = {
  getItem: (namespace) => {
    return memoryCache[namespace];
  },
  setItem: (namespace, value) => {
    memoryCache[namespace] = value;
  },
  removeItem: (namespace) => {
    delete memoryCache[namespace];
  },
};

let ss;
let ls;
try {
  ls = localStorage;
  ss = sessionStorage;
} catch {
  ls = memoryStorage;
  ss = memoryStorage;
}

const getData = (store: Storage, namespace: string, property?: string) => {
  const data = store.getItem(namespace) || defaultData;
  let value = {};
  try {
    value = JSON.parse(data);
  } catch (ignore) {}
  return property ? value[property] : value;
};

const setData = (store: Storage, namespace: string, property: string | Record<string, any>, value?: unknown) => {
  const data = getData(store, namespace);
  if (typeof property === 'string') {
    data[property] = value;
  } else {
    Object.assign(data, property);
  }
  try {
    store.setItem(namespace, JSON.stringify(data));
  } catch (ignore) {}
};

const removeData = (store: Storage, namespace: string, property?: string) => {
  let data = {};
  if (typeof property === 'string') {
    data = getData(store, namespace);
    delete data[property];
  }
  try {
    store.setItem(namespace, JSON.stringify(data));
  } catch (ignore) {}
};

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(@Inject('STORAGE_NAMESPACE') private namespace: string = 'global') {}

  static disableLocal = false;

  getNamespace() {
    return this.namespace;
  }

  getSessionData<T = unknown>(property?: string): T {
    return getData(ss, this.namespace, property);
  }

  setSessionData(property: string | Record<string, any>, value?: unknown) {
    return setData(ss, this.namespace, property, value);
  }

  removeSessionData(property?: string) {
    return removeData(ss, this.namespace, property);
  }

  getLocalData<T = unknown>(property?: string): T {
    return getData(StorageService.disableLocal ? ss : ls, this.namespace, property);
  }

  setLocalData(property: string | Record<string, any>, value?: unknown) {
    return setData(StorageService.disableLocal ? ss : ls, this.namespace, property, value);
  }

  removeLocalData(property?: string) {
    return removeData(StorageService.disableLocal ? ss : ls, this.namespace, property);
  }
}
