/** @format */

import { Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom-validators';

export class PhoneUtil {
  static defaultIsd = '1';

  static formatters = {
    // united states
    1: (number) => {
      let formatted = number.replace(/\D/g, '');
      while (formatted.startsWith('1')) formatted = formatted.slice(1);
      if (formatted.length === 0) {
        formatted = '';
      } else if (formatted.length <= 3) {
        formatted = formatted.replace(/^(\d{0,3})/, '$1');
      } else if (formatted.length <= 6) {
        formatted = formatted.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
      } else {
        formatted = formatted.substring(0, 10);
        formatted = formatted.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      }
      return formatted;
    },
    // allow numbers, spaces and ()-. characters
    default: (number) => number.replace(/[^\d\.\(\)\-+ ]/g, ''),
  };

  static validators = {
    1: CustomValidators.phone,
    default: Validators.nullValidator,
  };

  static format(phone: string, isd: string = PhoneUtil.defaultIsd) {
    const i = isd.replace(/\D/g, '');
    const formatter = PhoneUtil.formatters[i] || PhoneUtil.formatters.default;
    return phone && formatter(phone);
  }
}
