// get a reference to global this
// see https://mathiasbynens.be/notes/globalthis

// fix typescript complaints
type ObjectMap = Record<string, unknown>;
declare const _m_: Record<string, unknown>;
declare global { interface Object { _m_: unknown } }

let envRoot: ObjectMap = {};

if (typeof globalThis === 'object') {
    envRoot = globalThis;
} else {
    Object.defineProperty(Object.prototype, '_m_', {
        get: function () {
            return this;
        },
        configurable: true
    });
    envRoot = _m_;
    delete Object.prototype._m_;
}

export default envRoot;