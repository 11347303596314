/** @format */

function padZero(str: string, len = 2) {
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

function invertColor(hex: string, bw: boolean) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) throw new Error('Invalid HEX color.');

  const rDecimal = parseInt(hex.slice(0, 2), 16),
    gDecimal = parseInt(hex.slice(2, 4), 16),
    bDecimal = parseInt(hex.slice(4, 6), 16);

  // black/white only
  // https://stackoverflow.com/a/3943023/112731
  if (bw) return rDecimal * 0.299 + gDecimal * 0.587 + bDecimal * 0.114 > 186 ? '#000000' : '#FFFFFF';

  // invert color components
  const rHex = (255 - rDecimal).toString(16),
    gHex = (255 - gDecimal).toString(16),
    bHex = (255 - bDecimal).toString(16);

  // pad each with zeros and return
  return '#' + padZero(rHex) + padZero(gHex) + padZero(bHex);
}

export class ColorUtil {
  static invertColor = invertColor;
}
