/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@visurel/iconify-angular';
import { PopoverModule } from 'src/@vex/components/popover/popover.module';
import { ToolbarAnnouncementsDropdownComponent } from './toolbar-announcements-dropdown/toolbar-announcements-dropdown.component';
import { ToolbarAnnouncementsComponent } from './toolbar-announcements.component';

@NgModule({
  declarations: [ToolbarAnnouncementsComponent, ToolbarAnnouncementsDropdownComponent],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatRippleModule,
    MatTabsModule,
    PopoverModule,
    RouterModule,
    TranslocoModule,
  ],
  exports: [ToolbarAnnouncementsComponent],
  entryComponents: [ToolbarAnnouncementsDropdownComponent],
})
export class ToolbarAnnouncementsModule {}
