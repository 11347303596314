/**
 * This interceptor is designed to make communications with the api simple.
 * It performs the following tasks for the dev
 *   1. Updates the URL based on the environment
 *   2. Adds required authentication to request
 *   3. Detects auth errors and redirects client to login page
 *
 * Example use:
 *   this.http.get(`ClientInfo`, { context: ApiInterceptor.config() });
 *
 * Example appending to existing context
 *   const httpContext = ...create context here...;
 *   this.http.get(`ClientInfo`, { context: ApiInterceptor.config({enable: true}, httpContext) });
 *
 * @format
 */

import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { StateService } from '../services/state.service';

const API_TOKEN = new HttpContextToken<boolean>(() => false);
const AUTH_REQUIRED = new HttpContextToken<boolean>(() => false);
const ACCESS_REQUIRED = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  static config({ enable = true, auth = true, access = true }, context: HttpContext = new HttpContext()) {
    context.set(API_TOKEN, enable);
    context.set(AUTH_REQUIRED, auth);
    context.set(ACCESS_REQUIRED, access);
    return context;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // forward event to keepalive stream
    StateService.keepalive$.next({ httpRequest: request.url });
    // short circuit if not needed
    if (!request.context.get(API_TOKEN)) return next.handle(request);
    // short circuit if access not needed
    if (!request.context.get(ACCESS_REQUIRED) || StateService.disableEventHub) return this.placeRequest(request, next);

    // pause until access is granted
    return StateService.accessGranted$.pipe(
      filter((granted) => granted),
      first(),
      mergeMap(() => this.placeRequest(request, next))
    );
  }

  private placeRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authRequired = request.context.get(AUTH_REQUIRED);
    let apiRequest: HttpRequest<unknown> = request.clone({
      // update url per environment configuration
      url: `${environment.host}/api/${request.url.replace(/^\/+/g, '')}`,
      // allow affinity cookies to be sent
      withCredentials: authRequired,
    });
    // Get the auth token from the service.
    const authToken = authRequired && (AuthService.getToken() as string);
    // append authorization header
    if (authToken) {
      apiRequest = apiRequest.clone({
        headers: apiRequest.headers
          .append('Authorization', `Bearer ${authToken}`)
          .append('x-device-id', StateService.deviceId),
      });
    }

    // console.info('API request:', apiReq.url);

    // send cloned request with header to the next handler.
    return next.handle(apiRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        // handle 504 (gateway timeout/deployment underway)
        // todo: handle jwt refresh tokens (services not yet implemented)
        if (error.url.includes('/Account/Login')) {
          // logout if auth error
          AuthService.logout();
        }
        if (error.url.includes('/Account/RequestAccess')) {
          // alert cms of current access state
          parent.postMessage({ accessState: 'REQUESTING' }, environment.host);
          // if possible reload to trigger access request flow
          if (!StateService.disableEventHub) {
            location.reload();
          } else {
            AuthService.logout();
          }
        }
        return throwError(() => error);
      })
    );
  }
}
