<!-- @format -->
<ng-container *transloco="let t">
  <a
    *ngIf="isLink(item) && !isFunction(item.route)"
    [fragment]="item.fragment"
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
    [routerLink]="item.route"
    class="item flex items-center"
    routerLinkActive="active"
    matRipple
  >
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
    <ic-icon *ngIf="item.icon && level !== 0" [icIcon]="item.icon" class="subitem-icon flex-none" size="16"></ic-icon>
    <span class="item-label flex-auto">{{ t(item.label) }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{ item.badge.value }}</span>
  </a>

  <div *ngIf="isLink(item) && isFunction(item.route)" (click)="item.route()" class="item flex" routerLinkActive="active" matRipple>
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
    <mat-icon *ngIf="item.icon && level !== 0" [icIcon]="item.icon" class="subitem-icon flex-none"></mat-icon>
    <span class="item-label flex-auto">{{ t(item.label) }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{ item.badge.value }}</span>
  </div>

  <ng-container *ngIf="isDropdown(item)">
    <div (click)="toggleOpen()" [class.active]="isOpen || isActive" [class.open]="isOpen" class="item flex" matRipple>
      <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
      <span class="item-label flex-auto">{{ t(item.label) }}</span>
      <span *ngIf="!isOpen && item.badge?.value" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="item-badge flex-none">{{ item.badge.value }}</span>
      <mat-icon *ngIf="isOpen || !item.badge?.value" [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon flex-none"></mat-icon>
    </div>
    <div [@dropdown]="isOpen" class="item-dropdown">
      <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
    </div>
  </ng-container>

  <ng-container *ngIf="isSubheading(item)">
    <div (click)="toggleOpen()" [class.active]="isOpen || isActive" [class.open]="isOpen" class="subheading flex items-center" matRipple>
      <mat-icon *ngIf="item.icon && level === 0" [icIcon]="item.icon" class="item-icon flex-none"></mat-icon>
      <ic-icon *ngIf="item.icon && level !== 0" [icIcon]="item.icon" size="16" class="subitem-icon flex-none"></ic-icon>
      <div class="item-label flex-auto">{{ t(item.label) }}</div>
    </div>
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </ng-container>
</ng-container>
