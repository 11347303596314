/** @format */

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from 'src/@vex/interfaces/navigation-item.interface';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../@vex/services/config.service';
import { LayoutService } from '../@vex/services/layout.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { StyleService } from '../@vex/services/style.service';
import { AccessService } from './services/access.service';
import { AuthAuthorizations } from './services/auth.service';
import { CalendarRequestService } from './services/calendar/calendar-request.service';
import { ChatV2Service } from './services/chat-v2.service';
import { OrgRecord, OrgService } from './services/org.service';
import { OrgAnnouncementService } from './services/org/org-announcements.service';
import { OrgReportsService } from './services/org/org-reports.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { StateService } from './services/state.service';
import { VersionService } from './services/version.service';
import { DebugState } from './shared/debug-state';
import ptk from './shared/ptk';
import { sideNavItems } from './side-nav-items';

@UntilDestroy()
@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private configService: ConfigService,
    private layoutService: LayoutService,
    private navigationService: NavigationService,
    private platform: Platform,
    private pushService: PushNotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private splashScreenService: SplashScreenService,
    private styleService: StyleService,
    private swUpdate: SwUpdate,
    private titleService: Title,
    private translocoService: TranslocoService
  ) {
    if (this.platform.BLINK) {
      this.document.body.classList.add('is-blink');
    }
    this.layoutService.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add('is-mobile');
      } else {
        this.document.body.classList.remove('is-mobile');
      }
      DebugState.set('window.isMobile', isMobile);
    });
  }

  private authorizations: AuthAuthorizations;
  private orgInfo: OrgRecord;

  // filter side nav items based on authorizations
  private getMenuItems(items: NavigationItem[]): NavigationItem[] {
    const context = {
      auth: this.authorizations,
      features: environment.features,
      org: this.orgInfo,
    };
    return items
      .map((item) => {
        if (item.type === 'dropdown') {
          item.badge = item.badge || {
            value: 0,
            bgClass: 'bg-warn',
            textClass: 'text-warn-contrast',
          };
        }
        const auth = item.authorizations;
        if (auth && !auth.some((path) => ptk.get(context, path))) return;
        if (item.type !== 'link') {
          const children = this.getMenuItems(item.children);
          return children.length ? Object.assign({}, item, { children }) : undefined;
        }
        if (item.hidden) return;
        return item;
      })
      .filter((item) => item);
  }

  // save original application title / install state
  private appTitle = this.titleService.getTitle();
  private titleAppend: string;

  private calcPageTitle(): string[] {
    const result = [];
    const items = this.getMenuItems(sideNavItems);
    const tree: NavigationDropdown = {
      type: 'dropdown',
      label: this.titleService.getTitle(),
      children: items,
    };
    const isLink = this.navigationService.isLink;
    const isDropdown = this.navigationService.isDropdown;
    const isSubheading = this.navigationService.isSubheading;
    const isFunction = (prop) => prop instanceof Function;

    const buildTitle = (parent: NavigationDropdown | NavigationSubheading) => {
      return parent.children.some((child) => {
        if (isDropdown(child) || isSubheading(child)) {
          if (buildTitle(child)) {
            return result.unshift(this.translocoService.translate(child.label));
          }
        } else if (
          isLink(child) &&
          !isFunction(child.route) &&
          this.router.isActive(child.route as string, {
            fragment: 'ignored',
            matrixParams: 'ignored',
            paths: child.routerLinkActiveOptions ? 'exact' : 'subset',
            queryParams: 'subset',
          })
        ) {
          return result.unshift(this.translocoService.translate(child.label));
        }
      });
    };
    buildTitle(tree);
    return result;
  }

  private updatePageTitle() {
    // dynamically set page title based on route data
    // e.g. {path: 'login', data: { title: 'Login' }}
    let child = this.route;
    while (child.firstChild) child = child.firstChild;
    if (!child.snapshot) return;
    let titleArray = child.snapshot.data?.title?.split('/');
    if (titleArray) {
      // title override found, translate as needed
      titleArray = titleArray.map((item) => (item.includes('.') ? this.translocoService.translate(item) : item));
    } else {
      // title override not found, build title from sidenav
      titleArray = this.calcPageTitle();
    }
    // append content if requested
    if (this.titleAppend)
      titleArray.push(
        this.titleAppend.includes('.') ? this.translocoService.translate(this.titleAppend) : this.titleAppend
      );
    if (!StateService.isInstalled) titleArray.reverse().push(this.appTitle);
    const newTitle = titleArray.join(' · ');
    this.titleService.setTitle(newTitle);
    DebugState.set('page.title', newTitle);
  }

  ngOnInit() {
    // listen for app updates
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
          untilDestroyed(this)
        )
        .subscribe(async () => {
          StateService.updateReady$.next(true);
        });
    }

    // refresh side nav when user/profile changes
    StateService.authorizations$.pipe(untilDestroyed(this)).subscribe((authorizations) => {
      this.authorizations = authorizations;
      const items = this.getMenuItems(sideNavItems);
      this.navigationService.updateItems(items);
      DebugState.set('user.auth', authorizations);
    });

    // track current organization
    // this currently is only used for enabling/disabling rewards
    // for performance we assume org supports rewards if opened in targeted mode
    if (StateService.isFocusedMode) {
      this.orgInfo = { TurnRewardsOn: true } as OrgRecord;
    } else {
      OrgService.organization$.pipe(untilDestroyed(this)).subscribe((org) => {
        this.orgInfo = org;
        const items = this.getMenuItems(sideNavItems);
        this.navigationService.updateItems(items);
      });
    }

    // track unread message count
    ChatV2Service.unreadCount$.pipe(untilDestroyed(this)).subscribe((count) => {
      this.navigationService.updateBadge('menu.chat', count);
    });

    // track unapproved access requests
    AccessService.pending$.pipe(untilDestroyed(this)).subscribe((requests) => {
      this.navigationService.updateBadge('menu.access.title', requests?.length || 0);
    });

    // track unapproved appointment requests
    CalendarRequestService.pending$.pipe(untilDestroyed(this)).subscribe((requests) => {
      this.navigationService.updateBadge('menu.calendar.requests', requests?.length || 0);
    });

    // track org announcements
    OrgAnnouncementService.announcements$.pipe(untilDestroyed(this)).subscribe((announcements) => {
      StateService.announcements$.next(announcements);
    });

    // track org announcements
    OrgReportsService.reports$.pipe(untilDestroyed(this)).subscribe((reports) => {
      // find reports dropdown
      const dropdown = sideNavItems.find(
        (item) => item.type === 'dropdown' && item.label === 'menu.report.title'
      ) as NavigationDropdown;
      // populate with available reports
      dropdown.children = dropdown.children
        .filter((item) => item.type !== 'link' || !(item.route as string).startsWith('/report/legacy/'))
        .concat(
          ...((reports?.map((report) => ({
            type: 'link',
            label: `menu.report.${report.ReportName}`,
            // icon: icReport,
            route: `/report/legacy/${report.ReportId}`,
          })) || []) as NavigationLink[])
        );
      // update side nav
      const items = this.getMenuItems(sideNavItems);
      this.navigationService.updateItems(items);
    });

    let url: string;
    let langCount: number;
    const tryUpdate = () => {
      if (!url || langCount) return;
      this.updatePageTitle();
    };

    // update page title when new content ready
    StateService.loadingCount$.pipe(untilDestroyed(this)).subscribe((cnt) => {
      langCount = cnt;
      tryUpdate();
    });
    // update page title when new content ready
    StateService.titleAppend$.pipe(untilDestroyed(this)).subscribe((append) => {
      this.titleAppend = append;
      tryUpdate();
    });
    // update page title on lang change
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(tryUpdate, 0);
    });
    // track route changes
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        url = this.router.url;
        // service worker managing updates?
        if (!this.swUpdate.isEnabled) {
          // check app version for updates
          VersionService.check();
        }
        // update page title
        StateService.titleAppend$.next(null);
      });
  }
}
