/** @format */

import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DateUtil } from 'src/app/shared/date-util';
import { Link } from 'src/app/shared/link';
import { ObservableManager } from 'src/app/shared/observable-utils';
import { Rest } from '../../shared/rest';
import { CommonBase, CommonView, normalizeCommonBase } from '../common.service';
import { HBIAddress } from '../location.service';
import { PersonRecord } from '../person.service';
import { AuthRequire, StateService, authAllow } from '../state.service';
import { CalendarAppointment, fixEventDates, normalizeAppointment } from './calendar-appointment.service';

const urlCollection = {
  url: () => `Organizations/${StateService.user?.OrganizationId}/Calendar/AppointmentRequestById`,
  urlList: () => `Organizations/${StateService.user?.OrganizationId}/Calendar/PendingAppointmentRequests`,
  reject: {
    url: () => '',
    urlPost: () => `Organizations/${StateService.user?.OrganizationId}/Calendar/ApointmentRejection`,
  },
};

// #region TYPE DEFINITIONS

export interface CalendarRequest extends CommonBase {
  AllowEmailContact: boolean; // true
  AllowPhoneContact: boolean; // null
  AllowTextContact: boolean; // null
  AppointmentDate: string; // "2023-12-18T10:00:00"
  AppointmentDateDisplay: string; // "12/18/23 10:00 AM"
  AppointmentRequestId: number; // 530
  CaptchaToken: string; // null
  City: string; // null
  ClientId: string; // "50ae1cab-b700-46e7-aead-d622dc43591c"
  Comments: string; // null
  CountryId: number; // null
  DateOfBirth: string; // "09/01/1980"
  Email: string; // "ben.white@centricconsulting.com"
  EndDate: string; // "12/18/2023 12:00:00 AM"
  EndTime: string; // "11:00:00 AM"
  FirstName: string; // "Barb"
  GenderTypeId: number; // 2
  IsAccepted: boolean; // null
  LastMenstrualPeriodDate: string; // null
  LastName: string; // "White"
  LocationDisplay: string; // "Heartbeat International"
  LocationId: number; // 1
  LocationResourceDisplay: string; // null
  LocationResourceId: number; // null
  PersonId: string; // "f6730976-4cc7-4b6e-fa91-08d9e6623f90"
  Phone: string; // "(614) 839-6738"
  PhoneType: string; // ""
  PhoneTypeId: number; // null
  PregnancyIntentionType: string; // ""
  PregnancyIntentionTypeId: null;
  Reason: string; // "test"
  RecordCreatedDateDisplay: string; // "12/15/2023"
  RejectionReason: string; // null
  SourceTypeCode: string; // "AAAB"
  SourceTypeDisplay: string; // "MyHelpLink"
  SourceTypeId: number; // 2
  StaffVolunteer: PersonRecord;
  StaffVolunteerId: string; // null
  StartDate: string; // "12/18/2023 12:00:00 AM"
  StartTime: string; // "10:00:00 AM"
  State: string; // null
  ZipCode: string; // null
  // mapped
  Address: HBIAddress;
  addressLink?: SafeUrl;
  birthDate: Date; // DateOfBirth
  duration: number; // in minutes
  emailLink?: SafeUrl;
  phoneLink?: SafeUrl;
  smsLink?: SafeUrl;
  startDate: Date; // StartDate
  startEpoc: number; // StartDate.valueOf()
  stopDate: Date; // EndDate
}

export interface CalendarRequester {
  Address?: HBIAddress;
  AllowEmailContact: boolean;
  AllowPhoneContact: boolean;
  AllowTextContact: boolean;
  birthDate: Date;
  Email: string;
  emailLink: SafeUrl;
  FirstName?: string;
  LastName?: string;
  ContactPhone: string;
  phoneLink: SafeUrl;
  smsLink: SafeUrl;
  PhoneTypeId: number;
}

type CalendarRequestsView = CommonView<CalendarRequest[]>;
type CalendarRequestView = CommonView<CalendarAppointment>;

// #endregion

// #region PRIVATE

// how is a client being returned as both a client and a staff attendee?
// I don't know, but we managed to do it, so this code removes the staff record
const fixAttendees = (item: CalendarAppointment) =>
  Object.assign(item, { Attendees: item.Attendees.filter((a) => a.ContextType === 'Client') });

const normalizeRequestView = (item: CalendarRequestView): CalendarAppointment =>
  fixAttendees(normalizeAppointment(item.viewModel));

const normalizeRequest = (item: CalendarRequest): CalendarRequest =>
  Object.assign(
    normalizeCommonBase(item),
    // pretend this is a CalendarAppointment so that we can reuse the fixEventDates logic
    fixEventDates(item as any as CalendarAppointment),
    {
      Address: { City: item.City, State: item.State, ZipCode: item.ZipCode },
      addressLink: Link.fromAddress({ City: item.City, State: item.State, ZipCode: item.ZipCode } as HBIAddress),
      birthDate: DateUtil.parseDate(item.DateOfBirth),
      emailLink: Link.fromEmail(item.Email),
      phoneLink: Link.fromPhone(item.Phone),
      smsLink: Link.fromPhone(item.Phone, '', 'sms'),
    }
  );

const normalizeRequestsView = (item: CalendarRequestsView): CalendarRequest[] =>
  item.viewModel.map((item) => normalizeRequest(item));

// #endregion

export class CalendarRequestService {
  private static getName = () => `calendarRequests/${StateService.user?.OrganizationId}`;
  private static pending = new BehaviorSubject<CalendarRequest[]>([]);

  @AuthRequire('Client.Read') // TODO: server does not enforce
  static readonly list = async (): Promise<CalendarRequest[]> =>
    Rest.get<CalendarRequestsView, CalendarRequest[]>(urlCollection, [], {
      normalize: normalizeRequestsView,
      manager: this.getName(),
    });

  @AuthRequire('Client.Read') // TODO: server does not enforce
  static readonly list$ = (): Observable<CalendarRequest[]> =>
    ObservableManager.init<CalendarRequest[]>(this.getName(), () => this.list());

  @AuthRequire('Client.Read', 'ClientFacingApplication.Read') // TODO: server does not enforce
  static readonly get = async (id: number): Promise<CalendarAppointment> =>
    Rest.get<CalendarRequestView, CalendarAppointment>(urlCollection, [], id, normalizeRequestView);

  @AuthRequire('Client.Update') // TODO: server does not enforce
  static readonly reject = async (id: number, reason: string, notify: boolean): Promise<any> =>
    Rest.putPost<any>(
      urlCollection.reject,
      [],
      {
        AppointmentRequestId: id,
        Reason: reason,
      },
      0,
      {
        manager: this.getName(),
        params: { isNotificationAllowed: notify },
      }
    );

  static readonly pending$ = this.pending.asObservable();

  // track pending requests
  static {
    let subscription: Subscription;
    let lastOrgId;
    let timeout;

    // announce pending requests and schedule 5 minute refresh
    const updatePending = (items: CalendarRequest[]) => {
      clearTimeout(timeout);
      this.pending.next(items);
      timeout = setTimeout(() => {
        if (!authAllow(this.list)) return;
        this.list();
      }, 5 * 60 * 1e3);
    };

    // update pending requests on user change
    StateService.user$.subscribe(async (auth) => {
      if (StateService.isFocusedMode) return;
      const orgId = authAllow(this.list) && StateService.user?.OrganizationId;
      if (orgId !== lastOrgId) {
        lastOrgId = orgId;
        // cleanup
        clearTimeout(timeout);
        subscription?.unsubscribe();
        this.pending.next([]);
      }
      if (!orgId) return;
      // listen for changes on the new org
      subscription = this.list$().subscribe(updatePending);
    });
  }
}
