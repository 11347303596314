/**
 * Fully static service for getting org details
 *
 * @format
 */

import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiInterceptor } from 'src/app/interceptors/api-interceptor';
import { InterceptorContext } from 'src/app/interceptors/interceptor-context';
import { ObservableProvider, ObservableUtils } from 'src/app/shared/observable-utils';
import { environment } from 'src/environments/environment';
import { NIL as EMPTY_GUID } from 'uuid';
import { ArrayUtil } from '../shared/array-util';
import { ServiceLocator } from '../shared/service-locator';
import { CommonBase, CommonType, normalizeCommonBase } from './common.service';
import { AddressBase } from './location.service';
import { OrgLocation, normalizeOrgLocation } from './org/org-location.service';
import { PersonBase, normalizePersonBase } from './person.service';
import { StateService } from './state.service';

const urlCollection = {
  getRoles: (orgId) =>
    OrgService.isAPRN(orgId) // TODO: This logic should be on server side based on orgId
      ? `Organizations/${organizationId}/Admin/AdministrationRoles/GetAPRRoles`
      : `Organizations/${organizationId}/Admin/AdministrationRoles`,
  getRolesUsers: (roleId: string) =>
    `Organizations/${organizationId}/Admin/AdministrationUsers/UsersInRoleOptimized/${roleId}`,
  getRolesUsersNone: () => `Organizations/${organizationId}/Admin/AdministrationUsers/GetUsersNotInAnyRole`,
  postAddUsersRole: () => `Organizations/${organizationId}/Admin/AdministrationUsers`,
  putDelUsersRole: () => `Organizations/${organizationId}/Admin/AdministrationUsers/AssignUsersToRole`,
};

// definitions
export interface OrgUser extends PersonBase {
  City: string;
  Email: string;
  FileId?: number;
  Id: string;
  PhoneNumber: string;
  Role: string;
  Title: string;
}

interface OrgAddUserRolePayload {
  RoleId: string;
  UsersNotInRolesIds: string[];
}
interface OrgDelUserRolePayload {
  RoleId: string;
  UsersInRolesIds: string[];
}

interface OrgOnboardScreen {
  OrganizationOnboardScreenId: number;
  Title: string;
  Body: string;
  IsActive?: number;
  LastUpdatedBy?: string;
  LastUpdatedDate?: string;
  RecordCreatedBy?: string;
  RecordCreatedDate?: string;
}

export interface OrgContent {
  AuthorizationToReleaseRecords?: string;
  CFAMediaDisclaimer?: string;
  CFATermsAndConditions?: string;
  LimitationOfServices?: string;
  MedicalCareDisclaimer?: string;
  NLTermsAndConditions?: string;
  PrefLanguageTypeId: number;
  PrivacyPolicy?: string;
  STDSTITestingDisclaimer?: string;
  UltrasoundServicesAndRelease?: string;
}
export interface OrganizationCustomConsent {
  ConsentTypeId?: number;
  ConsentName: string;
  Consent: string;
  isCancel: boolean;
}
export interface OrganizationCustomConsentViewModel {
  ConsentTypeId: number;
  ConsentName: string;
  Consent: string;
  DummyName: string;
}

export interface OrgRecord extends AddressBase, CommonBase {
  AddressId: number;
  Administrators: OrgUser[];
  AuthorizationToReleaseRecords: string;
  CFAMediaDisclaimer: string;
  CFATermsAndConditions: string;
  ContactEmail: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContactUserId: string;
  Content?: OrgContent[];
  eBusinessUnit: number; // e-prescribe associated business unit id
  IsChosenGenderFieldEnabled: boolean;
  IsClientFacingApplicationEnabled: boolean;
  IsDemoSite: boolean;
  IsMasterOrganization: boolean;
  IsQuickBooksIntegratedEnabled: boolean;
  IsUltrasoundGAFieldEnabled: boolean;
  LegalName: string;
  LimitationOfServices: string;
  LocationId: number;
  MedicalCareDisclaimer: string;
  Name: string;
  NLTermsAndConditions: string;
  OrganizationId: string;
  OrganizationOnboardScreens: OrgOnboardScreen[];
  Phone: string;
  PrefLanguageTypeId: number;
  PrivacyPolicy: string;
  RecordCount: number;
  RegistrationApproved: boolean;
  STDSTITestingDisclaimer: string;
  TimeZoneTypeId: number;
  TrackFatherAge: boolean;
  TurnRewardsOn: boolean;
  UltrasoundApprovalTimeframeTypeId: number;
  // new security flags
  MFA: boolean;
  TrackDevice: boolean;
  TrackDeviceApproval: boolean;
  TrackLocation: boolean;
  TrackLocationApproval: boolean;
  UltrasoundServicesAndRelease: string;
  customConsents: OrganizationCustomConsent[];
}

export interface OrgAllOrganizations {
  IsMasterOrganization: boolean;
  Organizations: OrgRecord[];
}

export interface OrgSummaryRecord {
  Id: string;
  Name: string;
  IsMasterOrganization?: boolean;
  LocationId?: number;
  RecordCount?: string;
}

export interface OrgNewRecord {
  OrganizationId: EMPTY_GUID;
  Name?: string;
  LocationId: 0;
  OrganizationOnboardScreens: OrgOnboardScreen[];
}

export interface OrgStats {
  ClientRecordCount: string;
  DonorRecordCount: string;
  StaffVolunteerRecordCount: string;
  TotalRecordCount: string;
}

export interface OrgRole {
  Id: string;
  Name: string;
  DescText: string;
}

const newOrgId = EMPTY_GUID;

const locations = new BehaviorSubject<OrgLocation[]>(null);
const locationCache: Record<string, Promise<OrgLocation[]>> = {};
const roleCache: Record<string, Promise<OrgRole[]>> = {};

let clientLang = 'en';
let organizationId: string;
let isHBIAdmin: boolean;

let locationActive = false;
let loadedLocation = null;

// get required instances
let httpPromise = ServiceLocator.get(HttpClient);
const translocoPromise = ServiceLocator.get(TranslocoService);

// use api interceptor
const context = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { enable: true } }]);
const contextAccess = InterceptorContext.get([{ interceptor: ApiInterceptor, config: { access: false } }]);

const getUserLocations = async () => {
  if (
    !locationActive || // no one is listening
    !organizationId || // missing required data
    loadedLocation === organizationId // nothing has changes since our last request
  ) {
    return;
  }
  loadedLocation = organizationId;
  try {
    const data = await getCachedLocations(organizationId);
    locations.next(data);
  } catch (ex) {
    console.warn(ex);
    loadedLocation = null;
  }
};

const getLocations = async (orgId): Promise<OrgLocation[]> => {
  const http = await httpPromise;
  const response = http.get<OrgLocation[]>(`Organizations/${orgId}/Locations?activeOnly=false`, {
    context: contextAccess,
  });
  const data = await firstValueFrom(response);
  return data
    .map((item) => {
      return Object.assign(normalizeCommonBase(item), normalizeOrgLocation(item), {
        Name: item.Name.replace(' (Inactive)', ''),
      });
    })
    .sort((a, b) => ArrayUtil.compare(a.Name, b.Name));
};

const getCachedLocations = async (orgId, includeInactive: boolean = false) => {
  if (!locationCache[orgId]) {
    locationCache[orgId] = getLocations(orgId);
  }
  const locations = await locationCache[orgId];
  return locations?.filter((item) => includeInactive || item.isActive);
};

// TODO: these should be filtered by server
const filteredRoles = {
  'APR Option Line': true,
  'Center Option Line Role': true,
  'Client Facing Application User': true,
  Client: true,
  'Option Line Scheduler': true,
};

const normalizeOrgRoles = (roles: OrgRole[]) => roles.filter((role) => !filteredRoles[role.Name]);

const normalizeOrgUsers = (users: OrgUser[]) =>
  users?.map(normalizePersonBase).sort((a, b) => a.fullName.localeCompare(b.fullName));

const normalizeOrgInfo = (org: OrgRecord) =>
  Object.assign(org, {
    LegalName: org.LegalName.trim(),
    Name: org.Name.trim(),
    Administrators: normalizeOrgUsers(org.Administrators),
  });

const getRoles = async (orgId: string): Promise<OrgRole[]> => {
  if (!roleCache[orgId]) {
    const http = await httpPromise;
    const response = http.get<OrgRole[]>(urlCollection.getRoles(orgId), { context });
    // save promise in case requested again before this one resolves
    roleCache[orgId] = firstValueFrom(response).then(normalizeOrgRoles);
  }
  try {
    const data = await roleCache[orgId];
    return data;
  } catch (ex) {
    delete roleCache[orgId];
    throw ex;
  }
};

const getRoleUsers = async (roleId: string): Promise<OrgUser[]> => {
  const http = await httpPromise;
  const response = http.get<OrgUser[]>(
    roleId ? urlCollection.getRolesUsers(roleId) : urlCollection.getRolesUsersNone(),
    { context }
  );
  const data = await firstValueFrom(response);
  return normalizeOrgUsers(data);
};

const addUsersToRole = async (roleId: string, userIds: string[]): Promise<void> => {
  const http = await httpPromise;
  const body: OrgAddUserRolePayload = {
    RoleId: roleId,
    UsersNotInRolesIds: userIds,
  };
  const response = http.post<void>(urlCollection.postAddUsersRole(), body, { context });
  const data = await firstValueFrom(response);
  return data;
};

const removeUsersFromRole = async (roleId: string, userIds: string[]): Promise<void> => {
  const http = await httpPromise;
  const body: OrgDelUserRolePayload = {
    RoleId: roleId,
    UsersInRolesIds: userIds,
  };
  const response = http.put<void>(urlCollection.putDelUsersRole(), body, { context });
  const data = await firstValueFrom(response);
  return data;
};

const getOrganizationWithContent = async (id): Promise<OrgRecord> => {
  const http = await httpPromise;
  const response = http.get<OrgRecord>(`Organizations/${organizationId}/Organizations/${id}?lang=*`, {
    context,
  });
  const data = await firstValueFrom(response);
  return normalizeOrgInfo(data);
};

const saveCustomConsent = async (orgCustomConsent: OrganizationCustomConsent): Promise<void> => {
  const http = await httpPromise;
  const response = http.post<OrganizationCustomConsent>(
    `Organizations/${organizationId}/Organizations/AddCustomConsent`,
    orgCustomConsent,
    { context }
  );
  await firstValueFrom(response);
  ObservableUtils.refreshObservable(providers.allOrgs, true);
};
const updateCustomConsent = async (orgCustomConsent: OrganizationCustomConsent): Promise<void> => {
  const http = await httpPromise;
  const response = http.put<OrganizationCustomConsent>(
    `Organizations/${organizationId}/Organizations/updateCustomConsent`,
    orgCustomConsent,
    { context }
  );
  await firstValueFrom(response);
  ObservableUtils.refreshObservable(providers.allOrgs, true);
};

const getCustomConsentInfo = async (customConsentFormName): Promise<OrganizationCustomConsentViewModel> => {
  const http = await httpPromise;
  const response = http.get<OrganizationCustomConsentViewModel>(
    `Organizations/${organizationId}/Organizations/customConsentFormName/${customConsentFormName}`,
    {
      context,
    }
  );
  return await firstValueFrom(response);
};

const saveOrganization = async (org: OrgRecord): Promise<OrgRecord> => {
  const http = await httpPromise;
  const orgId = org.OrganizationId;
  const response = OrgService.isOrgNew(org)
    ? http.post<OrgRecord>(`Organizations/${organizationId}/Organizations`, org, { context })
    : http.put<OrgRecord>(`Organizations/${organizationId}/Organizations/${orgId}`, org, {
        context,
      });
  const data = await firstValueFrom(response);
  if (organizationId === orgId) {
    ObservableUtils.refreshObservable(providers.organization, true);
  }
  ObservableUtils.refreshObservable(providers.allOrgs, true);
  if (data) {
    return normalizeOrgInfo(data);
  }
};

const deleteOrganization = async (orgId: string): Promise<void> => {
  const http = await httpPromise;
  const response = http.put<void>(`Organizations/${organizationId}/Organizations/Removed/${orgId}`, null, {
    context,
  });
  await firstValueFrom(response);
  ObservableUtils.refreshObservable(providers.allOrgs, true);
};

const getOrganizationStats = async (id): Promise<OrgStats> => {
  const http = await httpPromise;
  const response = http.get<OrgStats[]>(`Organizations/${organizationId}/Organizations/RecordCounts/${id}`, {
    context,
  });
  const data = await firstValueFrom(response);
  return data[0];
};

const getOrgLangMatch = () => organizationId && `${organizationId}-${clientLang}`;
const getOrgLangNoAprnMatch = () => organizationId !== environment.aprnOrgId && getOrgLangMatch();

type Providers = Record<string, ObservableProvider>;
const providers: Providers = {
  organization: {
    bs: new BehaviorSubject<OrgRecord>(null),
    match: getOrgLangMatch,
    url: () => `Organizations/${organizationId}/Organizations/${organizationId}?lang=${clientLang}`,
    normalize: normalizeOrgInfo,
  },
  allOrgs: {
    bs: new BehaviorSubject<OrgAllOrganizations>(null),
    match: () => isHBIAdmin && organizationId,
    url: () => `Organizations/${organizationId}/Organizations/Administration`,
    normalize: (data: OrgSummaryRecord[]) =>
      data
        .map((org) =>
          Object.assign(org, {
            Name: org.Name.trim(),
          })
        )
        .sort((a, b) => (a.Name === b.Name ? 0 : a.Name > b.Name ? 1 : -1)),
  },
  roles: {
    bs: new BehaviorSubject<OrgRecord>(null),
    match: () => organizationId,
    url: () => urlCollection.getRoles(organizationId),
    normalize: normalizeOrgRoles,
  },
  timeZones: {
    bs: new BehaviorSubject<CommonType[]>(null),
    match: getOrgLangNoAprnMatch,
    url: () => `Organizations/${organizationId}/Types/TimeZoneType?lang=${clientLang}`,
  },
  ultrasoundApprovalTimeframes: {
    bs: new BehaviorSubject<CommonType[]>(null),
    match: getOrgLangNoAprnMatch,
    url: () => `Organizations/${organizationId}/Types/UltrasoundApprovalTimeframeType?lang=${clientLang}`,
  },
};

// track active language
translocoPromise.then((translocoService) => {
  translocoService.langChanges$.subscribe((lang) => {
    clientLang = lang;
    Object.values(providers).forEach(ObservableUtils.refreshObservable);
  });
});

// track authenticated user
StateService.user$.subscribe((user) => {
  if (!user) {
    organizationId = null;
    isHBIAdmin = null;
    Object.values(providers).forEach(ObservableUtils.resetObservable);
    loadedLocation = null;
    locations.next(null);
    return;
  }
  organizationId = user.OrganizationId;
  isHBIAdmin = user.isHBIAdmin;
  Object.values(providers).forEach(ObservableUtils.refreshObservable);
  getUserLocations();
});

export class OrgService {
  static organization$ = ObservableUtils.getObservable<OrgRecord>(providers.organization);
  static allOrganizations$ = ObservableUtils.getObservable<OrgSummaryRecord[]>(providers.allOrgs);
  static roles$ = ObservableUtils.getObservable<OrgRole[]>(providers.roles);
  static timeZones$ = ObservableUtils.getObservable<CommonType[]>(providers.timeZones);
  static ultrasoundApprovalTimeframes$ = ObservableUtils.getObservable<CommonType[]>(
    providers.ultrasoundApprovalTimeframes
  );

  static addUsersToRole = addUsersToRole;
  static deleteOrganization = deleteOrganization;
  static getLocations = getCachedLocations;
  static getOrganizationStats = getOrganizationStats;
  static getOrganizationWithContent = getOrganizationWithContent;
  static getRoles = getRoles;
  static getRoleUsers = getRoleUsers;
  static removeUsersFromRole = removeUsersFromRole;
  static saveOrganization = saveOrganization;
  static saveCustomConsent = saveCustomConsent;
  static getCustomConsentInfo = getCustomConsentInfo;
  static updateCustomConsent = updateCustomConsent;

  static getNewOrganization(): OrgNewRecord {
    return {
      OrganizationId: newOrgId,
      LocationId: 0,
      OrganizationOnboardScreens: [],
    };
  }

  static isAPRN(organizationId: string): boolean {
    return organizationId?.toLowerCase() === environment.aprnOrgId.toLowerCase();
  }

  static isOrgAdmin(org: OrgSummaryRecord | OrgRecord | OrgNewRecord): org is OrgSummaryRecord {
    // really simple type check
    return org && 'Id' in org;
  }

  static isOrgInfo(org: OrgSummaryRecord | OrgRecord | OrgNewRecord): org is OrgRecord {
    // really simple type check
    return org && 'OrganizationId' in org && org.OrganizationId && org.OrganizationId !== newOrgId;
  }

  static isOrgNew(org: OrgSummaryRecord | OrgRecord | OrgNewRecord): org is OrgNewRecord {
    // really simple type check
    return org && 'OrganizationId' in org && org.OrganizationId === newOrgId;
  }
}
